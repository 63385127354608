import axios from 'axios';
import { CONFIG } from '../config.ts';

export default class Payment {
  static async createCustomer({ userId, email }: { userId: string; email: string }) {
    const url = `${CONFIG.playPaymentAPIBaseUrl}/get-or-create-customer/`;
    const body = {
      user_id: userId,
      email,
    };

    return axios.post(url, body).then((res) => res.data);
  }

  static async createSubscription({
    userId,
    customerId,
    paymentMethodId,
    priceId,
    quantity,
    coupon,
  }: {
    userId: string;
    customerId: string;
    paymentMethodId: string;
    priceId: string;
    quantity: number;
    coupon?: string;
  }) {
    const url = `${CONFIG.playPaymentAPIBaseUrl}/create-subscription/`;
    const body: {
      trial_period_days?: number;
      coupon?: string;
      auto_recharge?: boolean;
      userId: string;
      paymentMethodId: string;
      customerId: string;
      priceId: string;
      quantity: number;
    } = {
      userId,
      customerId,
      paymentMethodId,
      priceId,
      quantity,
      coupon,
    };

    return axios.post(url, body).then((res) => res.data);
  }
}
