import { useEffect, useState } from 'react';
import { KeyIcon, UsersIcon } from '@heroicons/react/24/outline';
import { useUser } from '../../contexts/user.context.tsx';
import { useApiSecretKey, useGenerateApiSecretKey, useRevokeApiSecretKey } from '../../hooks/api.hooks.ts';
import { Spinner } from '../../components/Spinner.tsx';
import { Box } from '../../components/Box.tsx';
import { TextLink } from '../../components/TextLink.tsx';
import { TextField } from '../../components/TextField.tsx';
import { Button } from '../../components/Button.tsx';
import { Modal } from '../../components/Modal.tsx';

export function ApiAccessPage() {
  const { user } = useUser();
  const [userId, setUserId] = useState('');
  const { data: secretKey, isFetchedAfterMount } = useApiSecretKey();
  const [showRevokeModal, setShowRevokeModal] = useState(false);

  const { mutate: generateSecretKey, isLoading: isGeneratingKey } = useGenerateApiSecretKey();
  const { mutate: revokeSecretKey, isLoading: isRevokingKey } = useRevokeApiSecretKey(() => setShowRevokeModal(false));

  const onRevoke = async () => {
    await revokeSecretKey();
    setShowRevokeModal(false);
  };

  useEffect(() => {
    if (user) setUserId(user.id);
  }, [user]);

  return (
    <div className="max-w-lg">
      <h1 className="mb-8 text-xl font-semibold">API Access</h1>
      <div className="space-y-3">
        <p>Get access to PlayHT through the API.</p>
        <p>
          If you’re a developer, you can integrate text-to-speech conversions into your application using our API. The
          API provides a single interface to convert text to speech using our vast library of voices and languages.
        </p>
        <p>
          To get started, take a look at our API documentation. When you’re ready, generate a secret key from the
          credentials panel below, and use your credentials to communicate with the API.
        </p>
        <p className="text-base leading-10">
          <TextLink to="https://docs.play.ht/" target="_blank">
            API Documentation
          </TextLink>
        </p>
      </div>
      {isFetchedAfterMount ? (
        <Box className="mt-10">
          <h3 className="mb-6 text-base font-semibold">Credentials</h3>
          <div className="space-y-5">
            <TextField
              name="userId"
              label="User ID"
              type="text"
              value={userId}
              width="auto"
              leftIcon={<UsersIcon className="h-5 w-5 " aria-hidden="true" />}
              copyOption
              disabled
            />
            {secretKey ? (
              <>
                <TextField
                  name="secretKey"
                  label="Secret Key"
                  type="password"
                  value={secretKey}
                  width="auto"
                  leftIcon={<KeyIcon className="h-5 w-5 " />}
                  showOption
                  copyOption
                  disabled
                />
                <Button onClick={() => setShowRevokeModal(true)} intent="error" format="outlined">
                  Revoke Secret Key
                </Button>
                {renderRevokeModal(showRevokeModal, setShowRevokeModal, isRevokingKey, onRevoke)}
              </>
            ) : (
              <Button loading={isGeneratingKey} onClick={() => generateSecretKey()} intent="primary">
                Generate Secret Key
              </Button>
            )}
          </div>
        </Box>
      ) : (
        <Spinner className="mt-10" label="Loading API Access data..." />
      )}
    </div>
  );
}

function renderRevokeModal(
  showRevokeModal: boolean,
  setShowRevokeModal: (value: ((prevState: boolean) => boolean) | boolean) => void,
  isRevokingKey: boolean,
  onRevoke: () => Promise<void>
) {
  return (
    <Modal maxWidth="450px" open={showRevokeModal} setOpen={setShowRevokeModal}>
      <div className="space-y-5">
        <div className="text-lg font-semibold">Revoke access for key?</div>
        <div>
          Apps or scripts using this key will no longer be able to access the API.{' '}
          <span className="font-semibold">This action cannot be undone.</span>
        </div>
        <div className="flex justify-end gap-3">
          <Button format="outlined" size="small" onClick={() => setShowRevokeModal(false)}>
            Don't revoke
          </Button>
          <Button size="small" loading={isRevokingKey} onClick={onRevoke} intent="error">
            Revoke
          </Button>
        </div>
      </div>
    </Modal>
  );
}
