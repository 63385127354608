import React, { FC } from 'react';
import clsx from 'clsx';
import { BoltIcon, SparklesIcon } from '@heroicons/react/24/solid';
import { useActiveMainPlan } from '../../hooks/plans.hooks';

type VoiceCloneTypeProps = {
  instantCloningExceeded: boolean;
  hfCloningExceeded: boolean;
  onInstant: () => void;
  onHighFidelity: () => void;
};

const VoiceCloneType: FC<VoiceCloneTypeProps> = ({
  instantCloningExceeded,
  hfCloningExceeded,
  onInstant,
  onHighFidelity,
}) => {
  const activePlan = useActiveMainPlan();
  return (
    <div className="grid sm:grid-cols-2 gap-8">
      <button
        type="button"
        className={clsx(
          'py-12 px-4 rounded-2xl flex flex-col items-center justify-center hover:opacity-80 duration-200 ease-out',
          instantCloningExceeded ? 'bg-neutral/10 text-neutral' : 'bg-gray-800 text-white'
        )}
        onClick={onInstant}
      >
        <BoltIcon
          className={clsx('h-12 w-12 mb-4', instantCloningExceeded ? 'text-neutral' : 'text-primary-green-600')}
        />
        <span className="text-[20px] leading-[1.4] font-semibold text-center mb-2 text-inherit">Instant</span>
        <p className="text-[14px] leading-[20px] font-normal text-center text-inherit">
          Only 30 seconds of audio needed.
        </p>
        {instantCloningExceeded && (
          <p className="text-success underline">{activePlan ? 'Buy More Instant Clones' : 'Upgrade Plan to Access'}</p>
        )}
      </button>
      <button
        type="button"
        onClick={onHighFidelity}
        className={clsx(
          'py-12 px-4 bg-gray-800 rounded-2xl flex flex-col items-center justify-center hover:opacity-80 duration-200 ease-out',
          hfCloningExceeded ? 'bg-neutral/10 text-neutral' : 'bg-gray-800 text-white'
        )}
      >
        <SparklesIcon
          className={clsx('h-12 w-12 mb-4', hfCloningExceeded ? 'text-neutral' : 'text-primary-green-600')}
        />
        <span className="text-[20px] leading-[1.4] font-semibold text-center mb-2">High Fidelity</span>
        <p className="text-[14px] leading-[20px] font-normal text-center">
          Flawless results with 100% voice and accent resemblance.
        </p>
        {hfCloningExceeded && (
          <p className="text-success underline">
            {activePlan ? 'Buy More High Fidelity Clones' : 'Upgrade Plan to Access'}
          </p>
        )}
      </button>
    </div>
  );
};

export default VoiceCloneType;
