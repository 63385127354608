/**
 * Error that represents a non `Error` variable that was or will be thrown.
 * The common use case for this is to create an `Error` object from a caught variable of a try/catch block that is not an `Error`.
 */
export class NonErrorThrownError extends Error {
  constructor(public readonly cause: unknown) {
    super(`NonErrorThrownError`, { cause });
    this.name = this.constructor.name;
  }
}
