import { useUser } from '../contexts/user.context.tsx';
import { Link } from 'react-router-dom';
import { formatNumberWithCommas } from '../utils/numberUtils.ts';
import { useActiveMainPlan } from '../hooks/plans.hooks.ts';

export function CreditsCounter({ onClick }: { onClick?: () => void }) {
  const { user } = useUser();
  const activePlan = useActiveMainPlan();
  const hasUnlimitedCredits = activePlan?.hasUnlimitedCredits;
  const isMeteredPlan = activePlan?.is_metered_plan ?? false;
  const charsCountExists = !(user?.usage?.chars_count ?? false);
  const wordsCount = user?.usage?.words_count ?? 0;
  const charsCount = user?.usage?.chars_count ?? 0;
  const charsOverQuota = user?.usage?.current_metered_char_usage;

  if (user?.isTeamMember) {
    return null;
  }

  return (
    <div className="rounded-lg p-3 text-center text-white mb-2 bg-gray-900 flex flex-col gap-3 border border-gray-900">
      {hasUnlimitedCredits ? null : (
        <>
          {wordsCount ? (
            <span className="text-[12px] leading-[20px] font-normal">
              {formatNumberWithCommas(wordsCount)} words left
            </span>
          ) : null}
          {wordsCount && charsCount ? <span className="text-xs">and</span> : null}
          {charsCount ? (
            <span className="text-[12px] leading-[20px] font-normal">
              {formatNumberWithCommas(charsCount)} characters left
            </span>
          ) : null}
          {!wordsCount && !charsCount && !isMeteredPlan ? (
            <span className="text-[12px] leading-[20px] font-normal">
              You've run out of {charsCountExists ? 'characters' : 'words'}
            </span>
          ) : null}
          {charsOverQuota ? (
            <span className="text-[12px] leading-[20px] font-normal">
              {formatNumberWithCommas(charsOverQuota)} characters over quota
            </span>
          ) : null}
        </>
      )}
      <Link to="/studio/pricing" className="block">
        <button
          type="button"
          onClick={onClick}
          className="font-semibold py-2 px-[13px] text-[12px] leading-[20px] bg-gray-700 rounded-md hover:opacity-90 duration-200 ease-out w-full"
        >
          Upgrade
        </button>
      </Link>
    </div>
  );
}
