import { z } from 'zod';

const EnvVars = z.object({
  VITE_PLAYHT_ENVIRONMENT: z.union([z.literal('LOCAL'), z.literal('STAGING'), z.literal('PRODUCTION')]),
  VITE_PLAYHT_API_URL: z.string(),
  VITE_PLAYHT_STUDIO_API_URL: z.string(),
  VITE_PLAYHT_BFF_API_URL: z.string(),
  VITE_PLAYHT_DASHBOARD_URL: z.string(),
  VITE_PLAYHT_WEBSITE_URL: z.string(),
  VITE_FIREBASE_API_KEY: z.string(),
  VITE_FIREBASE_AUTH_DOMAIN: z.string(),
  VITE_FIREBASE_PROJECT_ID: z.string(),
  VITE_FIREBASE_STORAGE_BUCKET: z.string(),
  VITE_FIREBASE_MESSAGING_SENDER_ID: z.string(),
  VITE_FIREBASE_APP_ID: z.string(),
  VITE_FIREBASE_DATABASE_URL: z.string(),
  VITE_FIREBASE_DATABASE_V2_URL: z.string(),
  VITE_FIREBASE_DATABASE_URL_FOR_AUDIO_FRAMES: z.string(),
  VITE_STRIPE_PUBLIC_KEY: z.string(),
  VITE_STRIPE_PRICING_TABLE_ID: z.string(),
  VITE_PLAYHT_PAYMENT_API_URL: z.string(),
  VITE_MIXPANEL_KEY: z.string(),
  VITE_SENTRY_REPORTING_FOR_RUNNING_JEST_ON_LOCAL: z.union([z.literal('ENABLED'), z.literal('DISABLED')]).optional(),
  SENTRY_AUTH_TOKEN: z.string().optional(), // used only by vite.config.ts; should be set only in CI
  JEST_WORKER_ID: z.string().optional(),
});

const ENV_VARS = EnvVars.parse(import.meta.env);

const MS_STORE_VERSION_NUMBER = '1.0.1';
const PWA_VERSION_NUMBER = `${MS_STORE_VERSION_NUMBER}-2`;

const environment = ENV_VARS.VITE_PLAYHT_ENVIRONMENT;
const isProduction = environment === 'PRODUCTION';
const isLocalhost = environment === 'LOCAL';
const isRunningJest = !ENV_VARS.JEST_WORKER_ID;
const isNotProduction = !isProduction;
const isNotLocalhost = !isLocalhost;
const isNotRunningJest = !isRunningJest;
const isRunningJestOnLocal = isRunningJest && isLocalhost;
const isSentryReportingForRunningJestOnLocalEnabled =
  ENV_VARS.VITE_SENTRY_REPORTING_FOR_RUNNING_JEST_ON_LOCAL === 'ENABLED';
const isSentryReportingForRunningJestOnLocalDisabled = !isSentryReportingForRunningJestOnLocalEnabled;

console.log('[Play.ht]', environment, `v${PWA_VERSION_NUMBER}`);

export const CONFIG = {
  msStoreVersionNumber: MS_STORE_VERSION_NUMBER,
  pwaVersionNumber: PWA_VERSION_NUMBER,
  environment,
  isProduction,
  isLocalhost,
  isRunningJest,
  isNotProduction,
  isNotLocalhost,
  isNotRunningJest,
  isRunningJestOnLocal,
  isSentryReportingForRunningJestOnLocalEnabled,
  isSentryReportingForRunningJestOnLocalDisabled,
  firebase: {
    apiKey: ENV_VARS.VITE_FIREBASE_API_KEY,
    authDomain: ENV_VARS.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: ENV_VARS.VITE_FIREBASE_PROJECT_ID,
    storageBucket: ENV_VARS.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: ENV_VARS.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: ENV_VARS.VITE_FIREBASE_APP_ID,
    databaseURL: ENV_VARS.VITE_FIREBASE_DATABASE_URL,
    databaseURLV2: ENV_VARS.VITE_FIREBASE_DATABASE_V2_URL,
    databaseURLForAudioFrames: ENV_VARS.VITE_FIREBASE_DATABASE_URL_FOR_AUDIO_FRAMES,
  },
  playAPIBaseUrl: ENV_VARS.VITE_PLAYHT_API_URL + '/api',
  playStudioAPIBaseUrl: ENV_VARS.VITE_PLAYHT_STUDIO_API_URL + '/api/studio',
  playDashboardBaseUrl: ENV_VARS.VITE_PLAYHT_DASHBOARD_URL + '/app',
  playWebsiteBaseUrl: ENV_VARS.VITE_PLAYHT_WEBSITE_URL,
  playPaymentAPIBaseUrl: ENV_VARS.VITE_PLAYHT_PAYMENT_API_URL + '/pay',
  roostBffAPIBaseUrl: ENV_VARS.VITE_PLAYHT_BFF_API_URL + '/api/v2/bff',
  roostUrl: ENV_VARS.VITE_PLAYHT_API_URL + '/studio',
  stripePublicKey: ENV_VARS.VITE_STRIPE_PUBLIC_KEY,
  stripePricingTableId: ENV_VARS.VITE_STRIPE_PRICING_TABLE_ID,
  mixpanelKey: ENV_VARS.VITE_MIXPANEL_KEY,
  googleAnalyticsTag: 'G-6S5N2RJHSR',
  googleTagManagerId: 'GTM-THXS68BM',
  profitwellKey: 'fb5c8f4b9bf44db0460b3ade9b01a3b6',
  sentryDsn: isProduction
    ? undefined // 'https://fd6295c5ec57460e945d4d0614d7d4bb@o106756.ingest.sentry.io/4505235522781184'
    : 'https://f6bdcd6d3fa14a8994cb880a3e5e6ad9@o4504283437465600.ingest.sentry.io/4505479791837184',
  configCatKey: isProduction
    ? 'bD7aCFJs9k6BcPvG3LyhpA/p71Aj8dKHEqf68MkWsMOyA'
    : 'bD7aCFJs9k6BcPvG3LyhpA/3Oq0dWdI5EiKvCZ3mXXgpQ',
  configCatInterval: isProduction ? 60 * 60 : 5 * 60, //  1 hour in production, 5 minutes in staging
} as const;
