import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL } from '@ffmpeg/util';

const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/esm';

export async function convertBlobToOpus(blob: Blob): Promise<File> {
  const ffmpeg = new FFmpeg();
  if (!ffmpeg.loaded) {
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
      workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript'),
    });
  }

  const arrayBuffer = await blob.arrayBuffer();
  const uint8Array = new Uint8Array(arrayBuffer);

  const mimeType = blob.type;
  let extension = 'mp3';
  if (mimeType.endsWith('webm')) {
    extension = 'webm';
  } else if (mimeType.endsWith('ogg')) {
    extension = 'ogg';
  } else if (mimeType.endsWith('mpeg')) {
    extension = 'mp3';
  } else if (mimeType.endsWith('aac') || mimeType.endsWith('x-m4a') || mimeType.endsWith('mp4')) {
    extension = 'm4a';
  } else if (mimeType.endsWith('wav')) {
    extension = 'wav';
  }

  const inputFileName = `input.${extension}`;
  const outputFileName = 'output.opus';

  await ffmpeg.writeFile(inputFileName, uint8Array);
  await ffmpeg.exec(['-i', inputFileName, outputFileName]);
  const convertedData = await ffmpeg.readFile(outputFileName);
  const opusBlob = new Blob([convertedData], { type: 'audio/opus' });

  const opusFile = new File([opusBlob], 'new-recording.opus', { type: 'audio/opus' });
  return opusFile;
}
