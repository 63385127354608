import { useNavigate } from 'react-router-dom';
import { PaymentView } from '../../components/PaymentView/PaymentView';
import { eventsTracker, MixpanelEventType } from '../../infra/analytics/eventsTracker.ts';
import { useUser } from '../../contexts/user.context.tsx';
import { generateUserDataForTracking } from '../../utils/user.helpers';
import { PlanT } from '../../utils/plans.helpers.tsx';
import { formatterMoney } from '../../utils/formatCurrency.ts';
import { useState } from 'react';

export function Pay({ targetPlan }: { targetPlan: PlanT }) {
  const navigate = useNavigate();
  const { user } = useUser();
  const [coupon] = useState<string | undefined>(undefined);

  const onSuccess = () => {
    eventsTracker.sendGtmPurchaseEvent(targetPlan);
    if (user) {
      eventsTracker.sendMixpanelEvent(MixpanelEventType.Purchase, generateUserDataForTracking(user));
    }
    navigate(`/studio/billing?paymentSuccessful=true`, {
      replace: true,
    });
  };

  return (
    <div className="my-3 lg:w-[63%] lg:border-r-[.5px] lg:pr-12">
      <div className="flex justify-between">
        <p>Item</p>
        <p>
          Price (<strong>in USD</strong>)
        </p>
      </div>

      <hr className="my-3 border-[.5px]" />

      <div className="mb-4 flex justify-between">
        <p>
          <span>{targetPlan.name + ' '}</span>
          <span className="font-semibold">paid {targetPlan.cycle}</span>
        </p>
        <p className="text-[.75rem] font-normal">
          <span className="text-[1rem] font-semibold">{formatterMoney.format(targetPlan.price)}</span> due today
        </p>
      </div>
      {targetPlan.isOnFiftyPercentDiscount && (
        <div className="mb-2 mt-3 text-sm font-medium text-primary">
          <p className="text-primary">You are on a 50% discount!</p>
        </div>
      )}

      {/* Not used currently but  it's functional */}
      {/* <div className="flex gap-2 items-center justify-between my-2 mb-4">
        <label className="text-[.875rem] font-medium">Coupon</label>
        <div className="mb-1 [&>*]:rounded-lg [&>*]:border [&>*]:border-gray-700/60 [&>*]:bg-white/5 [&>*]:px-3 [&>*]:py-2 [&>*]:outline-0">
          <input
            type="text"
            className="rounded-md border-gray-300 shadow-sm p-1 max-w-[150px] bg-inherit text-sm focus:border-none focus:ring-0 focus:outline-none"
            value={coupon ?? ''}
            onChange={(e) => setCoupon(e.target.value)}
          />
        </div>
      </div> */}

      <PaymentView onSuccess={onSuccess} plan={targetPlan} coupon={targetPlan.discountCouponCode ?? coupon} />
    </div>
  );
}
