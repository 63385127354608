import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';

import { FileSelector } from '../../components/FileSelector';
import { useAuth } from '../../contexts/auth.context';
import { createZeroShotClone, getPresignedURL, uploadToS3 } from '../../API/voiceCloning.requests';
import { IAudioURL } from './HFVoiceCloningModal.tsx';
import { Modal } from '../../components/Modal.tsx';
import { TextField } from '../../components/TextField.tsx';
import { Divider } from '../../components/Divider.tsx';
import { Checkbox } from '../../components/Checkbox.tsx';
import { TextLink } from '../../components/TextLink.tsx';
import { ExpandableText } from '../../components/ExpandableText.tsx';
import { Button } from '../../components/Button.tsx';
import { Gender } from '../KettlePage/hooks/useVoices.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { VOICE_CLONING_QUERY_KEY } from '../../hooks/voiceCloning.hooks.ts';
import { Tab } from '@headlessui/react';
import { clsx } from 'clsx';
import { NOTIFICATION_TYPE_ERROR, useNotifications } from '../../__playrepo/ui-components/src/NotificationSnackbar.tsx';

export function LegacyInstantVoiceCloningModal({
  open,
  setOpen,
  onCloneCompleted,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onCloneCompleted?: (voiceId: string) => Promise<void> | void;
}) {
  return (
    <Modal open={open} setOpen={setOpen}>
      <LegacyInstantVoiceCloningModalContent setOpen={setOpen} onCloneCompleted={onCloneCompleted} />
    </Modal>
  );
}

function LegacyInstantVoiceCloningModalContent({
  setOpen,
  onCloneCompleted,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
  onCloneCompleted?: (voiceId: string) => Promise<void> | void;
}) {
  const { currentUser: user } = useAuth();
  const { setNotification } = useNotifications();

  const [speakerName, setSpeakerName] = useState('');
  const [audioURLs, setAudioURLs] = useState<IAudioURL[]>([]);
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [gender, setGender] = useState<Gender>(Gender.Male);

  const audioUrl = audioURLs.map((audio) => audio.url)[0];

  const { mutate: submit, isLoading: isSubmitting } = useCloneInstantVoice({
    onSuccess: async (voiceId) => {
      await onCloneCompleted?.(voiceId);
      setOpen(false);
    },
  });

  useEffect(() => {
    setIsDisabled(!speakerName || audioURLs.length < 1 || isUploading || isSubmitting || !agreeToTerms);
  }, [speakerName, audioURLs, isUploading, isSubmitting, agreeToTerms]);

  const uploadFile = async (files: File[]) => {
    if (!files.length) {
      setNotification({
        message: 'Please upload an audio file',
        status: NOTIFICATION_TYPE_ERROR,
      });
      return false;
    }
    if (!user) return;
    setIsUploading(true);
    try {
      const file = files[0];
      const currentFileName = file.name;
      const currentFileExt = currentFileName.split('.').pop();

      if (!currentFileExt) {
        setNotification({
          message: 'Please upload an audio file',
          status: NOTIFICATION_TYPE_ERROR,
        });
        return false;
      }

      if (file.size < 10000 || file.size > 50000000) {
        setNotification({
          message: 'Please upload an audio file between 10KB to 50MB size',
          status: NOTIFICATION_TYPE_ERROR,
        });
        return false;
      }

      const token = await user.getIdToken();

      setCurrentFile(file);

      const { preSignedUrl, url } = await getPresignedURL({
        token,
        fileInfo: {
          ext: currentFileExt,
          contentType: file.type,
        },
      });

      await uploadToS3({
        preSignedUrl,
        file,
        setUploadProgress,
      });

      setAudioURLs((prev) => [
        ...prev,
        {
          url,
          name: file.name,
        },
      ]);
    } catch (error: unknown) {
      setNotification({
        message: (error as Error)?.message ?? 'Something went wrong',
        status: NOTIFICATION_TYPE_ERROR,
      });
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
      setCurrentFile(null);
    }
  };

  const handleDeleteAudio = (index: number) => {
    setAudioURLs((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="max-w-[520px]">
      <h1 className="mb-12 text-xl font-semibold flex items-center">
        Let's create your Instant Voice Clone
        <span className="ml-2 py-0.5 px-1.5 rounded-md font-semibold text-xs bg-primary text-neutral-100">
          PlayHT2.0
        </span>
      </h1>
      <div className="mb-4">
        <TextField
          name="voiceName"
          label="Voice Name"
          value={speakerName}
          placeholder="Enter voice name"
          width="auto"
          onChange={(value) => setSpeakerName(value)}
        />
      </div>

      <LegacyGenderToggle value={gender} onToggle={setGender} />

      <label className="mb-3 block">Upload High Quality Audio sample</label>

      {isUploading ? (
        <div className="flex h-[80px] flex-col items-center justify-center gap-2 rounded-xl bg-neutral-900/50 ring-1 ring-inset ring-neutral-800/50 transition-all">
          <label className="block overflow-ellipsis font-semibold">Uploading: {currentFile?.name}</label>
          <div className="w-full">
            <div className="mx-auto mt-2 h-2 w-9/12 rounded-xl bg-neutral-700">
              <div className="h-full rounded-xl bg-primary" style={{ width: `${uploadProgress}%` }} />
            </div>
          </div>
        </div>
      ) : (
        audioURLs.length < 1 && (
          <div className="transition-all">
            <FileSelector
              name="audioUploader"
              uploadFile={uploadFile}
              accept={{
                'audio/*': ['.aac', '.mp3', '.ogg', '.wav', '.webm', '.flac', '.mp4', '.m4a', '.vnd', '.amr'],
                'video/*': ['.mov'],
              }}
            />
          </div>
        )
      )}

      {/* uploaded files */}
      {audioURLs.length > 0 && (
        <div className="flex h-[80px] flex-col items-center justify-center gap-2 rounded-xl bg-neutral-900/50 ring-1 ring-inset ring-neutral-800/50 transition-all">
          <div className="flex flex-col gap-2">
            {audioURLs.map((file, index) => (
              <div key={index} className="flex flex-col items-center justify-center gap-2">
                <p className="overflow-ellipsis text-base font-semibold">{file.name}</p>
                <div className="cursor-pointer underline" onClick={() => handleDeleteAudio(index)}>
                  Remove
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <p className="mt-3 text-xs">Upload at least 30 seconds of high quality audio</p>

      {/* agree to terms checkbox */}
      <div className="mb-6 mt-16">
        <Divider />
      </div>
      <div className="my-8">
        <Checkbox
          name="agreeToTerms"
          value="agreeToTerms"
          checked={agreeToTerms}
          onChange={(elem) => setAgreeToTerms(elem.target.checked)}
          label={
            <div className="flex flex-col gap-2">
              <ExpandableText resumeText="I hereby confirm that I have all the necessary rights or consents to clone and use this voice... ">
                <>
                  <p>
                    I hereby confirm that I have all the necessary rights or consents to clone and use this voice
                    without violating any copyrights and rights of publicity.
                  </p>{' '}
                  <p>
                    I also confirm that I have the rights to the audio files used in cloning this voice, and I will not
                    use this or any other voice on{' '}
                    <a className="" href="https://play.ht/privacy/">
                      PlayHT
                    </a>{' '}
                    to generate any sexual, hateful, abusive, violent, illegal, fraudulent, or deceitful content
                    whatsoever.
                  </p>{' '}
                  <p>
                    I fully understand and agree to PlayHT’s
                    <TextLink to="https://play.ht/terms/"> Terms of Use</TextLink> and
                    <TextLink to="https://play.ht/privacy/"> Privacy Policy</TextLink>.
                  </p>
                </>
              </ExpandableText>
            </div>
          }
        />
      </div>

      {/* submit button */}
      <div className="mt-8 flex justify-end">
        <Button
          intent="primary"
          width="full"
          disabled={isDisabled}
          loading={isSubmitting}
          onClick={() => submit({ audioUrl, speakerName, gender })}
        >
          Create
        </Button>
      </div>
    </div>
  );
}

function LegacyGenderToggle({ value, onToggle }: { value: Gender; onToggle: (value: Gender) => void }) {
  const options = [
    {
      text: 'Male',
      value: Gender.Male,
      onSelect: () => onToggle(Gender.Male),
    },
    {
      text: 'Female',
      value: Gender.Female,
      onSelect: () => onToggle(Gender.Female),
    },
  ];

  return (
    <div className="mb-4">
      <label className="mb-2 block" htmlFor={'gender-toggle'}>
        Gender:
      </label>
      <Tab.Group
        id="gender-toggle"
        selectedIndex={options.findIndex(({ value: opt }) => opt === value)}
        as="div"
        className="inline-block rounded-lg text-sm font-medium h-10 py-0 ring-1 ring-gray-300 dark:ring-neutral-50/20"
      >
        <Tab.List>
          {options.map(({ text, onSelect }) => (
            <Tab as={Fragment} key={text}>
              {({ selected }) => (
                <button
                  className={clsx('h-10 px-5 py-2 rounded-lg text-black dark:text-white focus:outline-none w-28', {
                    'bg-gray-300 dark:bg-zinc-700': selected,
                  })}
                  onClick={onSelect}
                >
                  {text}
                </button>
              )}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  );
}

export function useCloneInstantVoice({
  onSuccess = () => undefined,
  onSettled = () => undefined,
}: {
  onSuccess?: (voiceId: string) => Promise<void> | void;
  onSettled?: () => Promise<void> | void;
}) {
  const queryClient = useQueryClient();
  const { setNotification } = useNotifications();
  const { currentUser } = useAuth();
  return useMutation({
    mutationFn: async ({
      audioUrl,
      speakerName,
      gender,
      parentVoiceId,
    }: {
      audioUrl: string;
      speakerName: string;
      gender?: Gender;
      parentVoiceId?: string;
    }) => {
      const token = (await currentUser?.getIdToken()) ?? '';
      return await createZeroShotClone(
        { voiceName: speakerName, sampleFileURL: audioUrl, gender, parentVoiceId },
        token
      );
    },
    onSuccess: async (voiceId) => {
      queryClient.invalidateQueries({ queryKey: [VOICE_CLONING_QUERY_KEY] });
      setNotification({ status: 'success', message: 'Cloning request created successfully' });
      await onSuccess(voiceId);
    },
    onError: (error: unknown) => {
      let message = 'Something went wrong';
      if (error instanceof AxiosError && error.response?.data?.message) {
        message = error.response.data.message;
      } else if (error instanceof Error) {
        message = error.message;
      }
      setNotification({ message, status: NOTIFICATION_TYPE_ERROR });
    },
    onSettled,
  });
}
