import { useUser } from '../contexts/user.context.tsx';
import { useCallback, useEffect } from 'react';
import { createFile, deleteFile } from '../API/files.requests.ts';
import { useFilesStore } from '../stores/files.store.ts';
import { useMutation } from '@tanstack/react-query';
import { useAuth } from '../contexts/auth.context.tsx';
import { useNavigate } from 'react-router-dom';
import { File, FileModel } from '../domain/files/File.ts';
import { errorsTracker } from '../infra/errors/errorsTracker.ts';
import { useNotifications } from '../__playrepo/ui-components/src/NotificationSnackbar.tsx';

export function useFiles() {
  const { user } = useUser();
  const previousUserId = useFilesStore((state) => state.userId);
  const files = useFilesStore((state) => state.files);
  const listenFiles = useFilesStore((state) => state.listenFiles);
  const unsubscribe = useFilesStore((state) => state.unsubscribe);

  // TODO: implement loading control
  useEffect(() => {
    if (user?.id) listenFiles(user?.id);
  }, [listenFiles, user?.id]);

  return [user?.id === previousUserId ? files : null, unsubscribe ?? (() => null)] as const;
}

export interface CreateAndOpenFileParams {
  model?: FileModel;
  defaultVoice?: string;
}
export function useCreateAndOpenFile(onSuccess?: (file: File) => void) {
  const maxRetry = 4;
  const navigate = useNavigate();
  const { user } = useUser();
  const { setNotification } = useNotifications();

  const { mutate, ...mutationState } = useMutation({
    mutationFn: async (params: CreateAndOpenFileParams) => {
      const { model, defaultVoice } = params ?? {};
      if (!user) throw new Error("Can't delete files. User not logged in.");
      const file = await createFile(user, model);
      let to = `/studio/files/${file.id}`;
      if (defaultVoice) to += `?voice=${encodeURIComponent(defaultVoice)}`;
      navigate(to);
      return file;
    },
    onSuccess,
    retry: maxRetry,
    retryDelay: (failureCount, error) => {
      if (failureCount) {
        const errorMessage = `Failed to create file. Attempt ${failureCount}/${maxRetry}`;
        errorsTracker.report('warning', error, errorMessage);
      }
      return failureCount ** 2 * 3000;
    },
    onError: () => setNotification({ message: 'An error occurred trying to create a new file', status: 'error' }),
  });

  const createAndOpenFile = useCallback(
    (params?: CreateAndOpenFileParams) => {
      return mutate(params ?? {});
    },
    [mutate]
  );
  return { createAndOpenFile, ...mutationState };
}

export function useDeleteFiles() {
  const { currentUser } = useAuth();
  const { setNotification } = useNotifications();
  return useMutation({
    mutationFn: async (filesIds: string[]) => {
      if (!currentUser) throw new Error("Can't delete files. User not logged in.");
      const promises = filesIds.map((id) => deleteFile(currentUser, id));
      await Promise.all(promises);
    },
    onError: () => setNotification({ message: 'An error occurred trying to delete the files', status: 'error' }),
  });
}
