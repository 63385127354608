import { Package } from './Plan';

export function getAddonQuantityFromMainPlan(activeMainPlan: Package, type: 'high-fid-clone' | 'instant-clone') {
  if (Object.values(activeMainPlan.addons ?? {}).length > 0) {
    const addon = Object.values(activeMainPlan.addons ?? {}).find((a) => a.type === type);
    if (addon) {
      return addon.quantity;
    }
  }
  return 0;
}
