export function LanguagesIcon({ className }: { className?: string }) {
  return (
    <div className={className ?? ''}>
      <svg viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.275 20C11.8917 20 11.6125 19.875 11.4375 19.625C11.2625 19.375 11.2417 19.0667 11.375 18.7L15.05 8.95C15.15 8.68333 15.3333 8.45833 15.6 8.275C15.8667 8.09167 16.1417 8 16.425 8C16.7083 8 16.9875 8.09167 17.2625 8.275C17.5375 8.45833 17.725 8.68333 17.825 8.95L21.575 18.6C21.725 18.9833 21.7083 19.3125 21.525 19.5875C21.3417 19.8625 21.0333 20 20.6 20C20.4167 20 20.2417 19.9417 20.075 19.825C19.9083 19.7083 19.7917 19.5583 19.725 19.375L18.9 16.85H14.1L13.15 19.4C13.0833 19.5667 12.9667 19.7083 12.8 19.825C12.6333 19.9417 12.4583 20 12.275 20ZM14.65 15.1H18.2L16.45 10.25H16.4L14.65 15.1ZM6.225 6.55C6.475 6.98333 6.75 7.40417 7.05 7.8125C7.35 8.22083 7.675 8.64167 8.025 9.075C8.775 8.29167 9.4 7.47917 9.9 6.6375C10.4 5.79583 10.825 4.91667 11.175 4H1C0.716667 4 0.479167 3.90417 0.2875 3.7125C0.0958333 3.52083 0 3.28333 0 3C0 2.71667 0.0958333 2.47917 0.2875 2.2875C0.479167 2.09583 0.716667 2 1 2H7V1C7 0.716667 7.09583 0.479167 7.2875 0.2875C7.47917 0.0958333 7.71667 0 8 0C8.28333 0 8.52083 0.0958333 8.7125 0.2875C8.90417 0.479167 9 0.716667 9 1V2H15C15.2833 2 15.5208 2.09583 15.7125 2.2875C15.9042 2.47917 16 2.71667 16 3C16 3.28333 15.9042 3.52083 15.7125 3.7125C15.5208 3.90417 15.2833 4 15 4H13.175C12.8083 5.15 12.3208 6.27917 11.7125 7.3875C11.1042 8.49583 10.3583 9.55 9.475 10.55L11.925 13.025L11.175 15.05L8 12L3.675 16.325C3.49167 16.5083 3.26667 16.6 3 16.6C2.73333 16.6 2.50833 16.5 2.325 16.3C2.125 16.1167 2.025 15.8917 2.025 15.625C2.025 15.3583 2.125 15.125 2.325 14.925L6.725 10.525C6.29167 10.0083 5.88333 9.4875 5.5 8.9625C5.11667 8.4375 4.76667 7.9 4.45 7.35C4.25 6.98333 4.23333 6.66667 4.4 6.4C4.56667 6.13333 4.86667 6 5.3 6C5.48333 6 5.6625 6.05417 5.8375 6.1625C6.0125 6.27083 6.14167 6.4 6.225 6.55Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
