import { Link, NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { BillingPlansSection } from './BillingPlansSection.tsx';
import { BillingPaymentMethodSection } from './BillingPaymentMethodSection.tsx';
import { BillingDetailsSection } from './BillingDetailsSection.tsx';
import { BillingInvoicesHistorySection } from './BillingInvoicesHistorySection.tsx';
import { useUser } from '../../contexts/user.context.tsx';
import { clsx } from 'clsx';
import { TeamMembersBlockingUI } from '../../components/TeamMembersBlockingUI.tsx';

const tabs = [
  { id: 'plans', title: 'Plans', component: <BillingPlansSection /> },
  { id: 'payment-method', title: 'Payment method', component: <BillingPaymentMethodSection /> },
  { id: 'details', title: 'Billing details', component: <BillingDetailsSection /> },
  { id: 'invoices-history', title: 'Invoices history', component: <BillingInvoicesHistorySection /> },
];

export function BillingPage() {
  const navigate = useNavigate();
  const { tab: currentTab = tabs[0].id } = useParams();
  const { user } = useUser();

  if (user?.isTeamMember) {
    return <TeamMembersBlockingUI pageName="Billing" />;
  }

  return (
    <div>
      <h1 className="mb-9 text-xl font-semibold">Billing</h1>
      <div className="max-w-5xl">
        {renderTabsNavForMobile(currentTab, navigate)}
        {renderTabsNavForDesktop(currentTab)}
        <div className="mt-12">{getTab(currentTab)?.component}</div>
      </div>
    </div>
  );
}

function renderTabsNavForMobile(currentTab: string, navigate: NavigateFunction) {
  return (
    <div className="tablet:hidden">
      <label htmlFor="tabs" className="sr-only">
        Select a tab
      </label>
      <select
        id="tabs"
        name="tabs"
        className="block w-full rounded-md border-none bg-inherit py-2 pl-3 pr-10 text-base shadow-sm ring-1 tablet:text-sm"
        value={getTab(currentTab)?.id}
        onChange={(e) => navigate(getTabPath(e.target.value))}
      >
        <optgroup className="bg-inherit">
          {tabs.map((tab) => (
            <option key={tab.id} value={tab.id} className=" bg-inherit">
              {tab.title}
            </option>
          ))}
        </optgroup>
      </select>
    </div>
  );
}

function renderTabsNavForDesktop(currentTab: string) {
  return (
    <div className="hidden tablet:block">
      <nav className="flex border-neutral-50/10 py-4">
        <ul role="list" className="flex min-w-full flex-none text-base font-semibold leading-6">
          {tabs.map((tab, idx) => (
            <li
              key={tab.id}
              className={clsx('px-6 pb-4', { 'border-b-2 border-primary': tab.id === currentTab, '-ml-6': idx === 0 })}
            >
              <Link to={getTabPath(tab.id)}>{tab.title}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}

function getTab(tabId: string) {
  return tabs.find((tab) => tab.id === tabId);
}

function getTabPath(tabId: string) {
  return `/studio/billing/${tabId}`;
}
