import { LanguagesIcon } from './Icons/LanguagesIcon.tsx';
import { useState } from 'react';
import { LegacyDashModal } from './LegacyDashModal.tsx';

export function LegacyAppLink() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="flex items-center w-full bg-gray-900 border-gray-800 p-3 rounded-lg justify-between mb-2 hover:opacity-90 duration-200 ease-out"
      >
        <span className="leading-[20px] text-xs text-white">Standard Studio</span>
        <LanguagesIcon className="h-6 w-6 text-white" />
      </button>
      <LegacyDashModal open={open} setOpen={setOpen} />
    </>
  );
}
