import { Link, Outlet } from 'react-router-dom';
import { PlayhtLogo } from '../components/PlayhtLogo.tsx';
import { FC, PropsWithChildren } from 'react';

export const ErrorLayout = () => (
  <ErrorLayoutWrapper>
    <Outlet />
  </ErrorLayoutWrapper>
);

export const ErrorLayoutWrapper: FC<PropsWithChildren> = ({ children }) => (
  <div className="flex min-h-screen flex-col items-center justify-center gap-2 px-6 py-12 desktop:px-8 desktop:pb-32">
    <Link className="mb-2 block" to="/studio/files">
      <PlayhtLogo className="h-12 pb-2 pt-4" />
    </Link>
    <div className="flex flex-col items-center justify-center gap-5 py-10 text-center">{children}</div>
    <Link to="/studio/" className="underline">
      Home page
    </Link>
  </div>
);
