import { Dispatch, SetStateAction, useState } from 'react';
import { Voice } from '../../utils/VC.helpers';
import { useAuth } from '../../contexts/auth.context';
import { shareVoice } from '../../API/voiceCloning.requests';
import { CONFIG } from '../../config.ts';
import { AxiosError } from 'axios';
import { Button } from '../../components/Button.tsx';
import { TextField } from '../../components/TextField.tsx';
import { Modal } from '../../components/Modal.tsx';

export function VCShareModal({
  visible,
  setVisible,
  voice,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  voice: Voice;
}) {
  const { currentUser } = useAuth();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | false>(false);

  const [voiceHash, setVoiceHash] = useState('');
  const redirectLink = voiceHash ? `${CONFIG.roostUrl}/voice-cloning/claim-voice/${voiceHash}` : '';

  const [textCopied, setTextCopied] = useState(false);

  async function copyToClipboard(text: string) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      const el = document.createElement('textarea');
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    } finally {
      setTextCopied(true);
      setTimeout(() => {
        setTextCopied(false);
      }, 2000);
    }
  }

  const handleShareVoice = async () => {
    if (!currentUser) return;
    setLoading(true);
    setError(false);
    try {
      const idToken = await currentUser?.getIdToken();
      const res = await shareVoice({
        token: idToken,
        data: { voiceId: voice.id, voiceName: voice.name, isZeroShot: voice.isZeroShot ?? false },
      });

      setVoiceHash(res.data?.data);
      setSuccess(true);
    } catch (err: unknown) {
      const message =
        err instanceof AxiosError && err.response?.data?.error
          ? err.response.data.error
          : 'Something went wrong please try again later';
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal maxWidth="500px" open={visible} setOpen={setVisible}>
      {success ? (
        <div className="space-y-5 text-center">
          <div className="mb-2 text-lg font-semibold">Shared Cloned Voice</div>
          <TextField name="sharedLink" value={redirectLink} disabled width="400px" />
          <Button
            intent={textCopied ? 'success' : 'primary'}
            width="full"
            onClick={() => copyToClipboard(redirectLink)}
          >
            {textCopied ? 'Copied' : 'Copy'}
          </Button>
        </div>
      ) : error ? (
        <div>
          <p className="mb-0 text-center text-lg font-semibold text-red-600">{error}</p>
        </div>
      ) : (
        <div>
          <h3 className="mb-2 text-left text-lg font-semibold">Share {voice?.name}</h3>
          <p className="p-4 text-left">
            Anyone who has access to this link can use this voice. Are you sure you want to share this voice?
          </p>
          <div className="mt-5 flex justify-end gap-4">
            <Button format="outlined" onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Button intent="primary" loading={loading} onClick={() => handleShareVoice()}>
              Share
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
}
