import axios from 'axios';
import { CONFIG } from '../config.ts';
import { PaymentMethod } from '../domain/billing/PaymentMethod.ts';
import { BillingInfo } from '../domain/billing/BillingInfo.ts';
import { Invoice } from '../domain/billing/Invoice.ts';

const axiosInstance = axios.create({ baseURL: CONFIG.playAPIBaseUrl });

// TODO: get idToken from zustand store
// TODO: use Zod to validate response data types

export async function fetchPaymentMethods(idToken: string) {
  type Response = {
    defaultPaymentId: string;
    paymentMethods: PaymentMethod[];
  };
  const url = `/user/fetchPaymentMethod?token=${idToken}`;
  return await axiosInstance.get<Response>(url);
}

export async function fetchBillingInfo(idToken: string) {
  type Response = {
    data: BillingInfo;
  };
  const url = `/user/fetchBillingInfo?token=${idToken}`;
  return await axiosInstance.get<Response>(url);
}

export async function fetchInvoices(idToken: string) {
  type Response = {
    data: Invoice[];
  };
  const url = `/user/fetchInvoices?token=${idToken}`;
  return await axiosInstance.get<Response>(url);
}

export async function addPaymentMethod(idToken: string, paymentMethodId: string) {
  type Response = {
    message: string;
  };
  const url = `/user/addPaymentMethod?token=${idToken}`;
  return await axiosInstance.post<Response>(url, { paymentMethodId });
}

export async function removePaymentMethod(idToken: string, paymentMethodId: string) {
  type Response = {
    message: string;
  };
  const url = `/user/detachPaymentMethod/${paymentMethodId}?token=${idToken}`;
  return await axiosInstance.delete<Response>(url);
}

export async function updateBillingInfo(idToken: string, billingInfo: BillingInfo) {
  type Response = {
    message: string;
  };
  const url = `/user/updateBillingInfo?token=${idToken}`;
  return await axiosInstance.put<Response>(url, billingInfo);
}

export async function cancelPlan(idToken: string, reason: string, planId: string, userId: string, userName: string) {
  type Response = {
    message: string;
  };

  return await axiosInstance.post<Response>('/unsubscribe', {
    token: idToken,
    userId,
    name: userName,
    reason,
    packageId: planId,
  });
}
