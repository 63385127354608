export const VoiceCloningIcon = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7798_17625)">
      <path
        d="M0.59375 5.9375H1.78125V13.0625H0.59375V5.9375ZM13.9531 5.9375H15.1406V13.0625H13.9531V5.9375ZM3.85938 3.26562H5.04688V15.4375H3.85938V3.26562ZM10.6875 3.5625H11.875V15.4375H10.6875V3.5625ZM17.2188 3.5625H18.4062V15.4375H17.2188V3.5625ZM7.125 0.59375H8.3125V18.4062H7.125V0.59375Z"
        fill="#01DA72"
      />
    </g>
    <defs>
      <clipPath id="clip0_7798_17625">
        <rect width="19" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
