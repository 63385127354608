import Payment from '../../API/payment.requests.ts';
import type { Stripe, StripeCardElement } from '@stripe/stripe-js';

export interface StripeSubscription {
  id: string;
  status: string;
  latest_invoice: {
    payment_intent: {
      status: string;
      client_secret: string;
    };
  };
}

export const stripeService = {
  subscribeCustomerToPlan,
};

async function subscribeCustomerToPlan({
  stripe,
  userId,
  userName,
  customerId,
  planId,
  queryQty,
  card,
  coupon,
}: {
  stripe: Stripe;
  userId: string;
  userName: string;
  customerId: string;
  planId: string;
  queryQty: number;
  card: StripeCardElement;
  coupon?: string;
}) {
  // Set up payment method for recurring usage
  const billingName = userName;
  const priceId = planId;

  return stripe
    .createPaymentMethod({
      type: 'card',
      card,
      billing_details: {
        name: billingName,
      },
    })
    .then((result) => {
      if (result.error) {
        throw result.error;
      }

      // Create the subscription
      return createSubscription({
        stripe,
        userId,
        customerId,
        paymentMethodId: result.paymentMethod?.id ?? '',
        priceId,
        quantity: queryQty,
        coupon,
      });
    })
    .then((result) => {
      return {
        transactionId: result?.subscription.id,
      };
    });
}

function createSubscription({
  stripe,
  userId,
  customerId,
  paymentMethodId,
  priceId,
  quantity,
  coupon,
}: {
  stripe: Stripe;
  userId: string;
  customerId: string;
  paymentMethodId: string;
  priceId: string;
  quantity: number;
  coupon?: string;
}) {
  return (
    Payment.createSubscription({
      userId,
      customerId,
      paymentMethodId,
      priceId,
      quantity,
      coupon,
    })
      // Normalize the result to contain the object returned by Stripe.
      // Add the additional details we need.
      .then((result) => {
        if (result.error) {
          throw result.error;
        }

        return { stripe, subscription: result, priceId, paymentMethodId, quantity };
      })
      // Some payment methods require a customer to be on session
      // to complete the payment process (e.g. 3D Secure). Check the status of the
      // payment intent to handle these actions.
      .then(handlePaymentThatRequiresCustomerAction)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail, you
      // get a `requires_payment_method` error.
      .then((res) => {
        if (res) return handleRequiresPaymentMethod(res);
      })
  );
}

async function handlePaymentThatRequiresCustomerAction({
  stripe,
  subscription,
  priceId,
  paymentMethodId,
}: {
  stripe: Stripe;
  subscription: StripeSubscription;
  priceId: string;
  paymentMethodId: string;
}) {
  if (subscription && subscription?.status === 'active') {
    // Subscription is active, no customer actions required.
    return { subscription, priceId, paymentMethodId };
  }

  // The payment intent is on the subscription latest invoice.
  const paymentIntent = subscription.latest_invoice.payment_intent;

  if (paymentIntent?.status === 'requires_source_action' || paymentIntent?.status === 'requires_action') {
    return stripe
      .confirmCardPayment(paymentIntent.client_secret, { payment_method: paymentMethodId })
      .then((result) => {
        if (result.error) {
          throw result.error;
        }

        if (result.paymentIntent?.status === 'succeeded') {
          return { subscription, priceId, paymentMethodId };
        }
      });
  }

  return { subscription, priceId, paymentMethodId };
}

async function handleRequiresPaymentMethod({
  subscription,
  paymentMethodId,
  priceId,
}: {
  subscription: StripeSubscription;
  paymentMethodId: string;
  priceId: string;
}) {
  if (subscription.latest_invoice.payment_intent?.status === 'requires_payment_method') {
    throw { error: { message: 'Your card was declined.' } };
  }

  return { subscription, priceId, paymentMethodId };
}
