import { Link, Location, useLocation } from 'react-router-dom';
import { clsx } from 'clsx';
import { User as FirebaseUser } from '@firebase/auth';
import { Disclosure } from '@headlessui/react';
import {
  ArrowRightOnRectangleIcon,
  ChevronRightIcon,
  ClockIcon,
  CreditCardIcon,
  FolderIcon,
  KeyIcon,
  SparklesIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import { RecentFile, useRecentFiles } from '../hooks/recentFiles.hooks.ts';
import { PlayhtLogo } from './PlayhtLogo.tsx';
import TwitterIcon from '../assets/twitter-x.svg';
import DiscordIcon from '../assets/discord.svg';
import { LegacyAppLink } from './LegacyAppLink.tsx';
import { CreditsCounter } from './CreditsCounter.tsx';
import { CONFIG } from '../config.ts';
import { MenuItem } from './Sidebar.tsx';
import { defaultFileName } from '../domain/files/File.ts';
import { useAuth } from '../contexts/auth.context.tsx';
import { useCreateAndOpenFile } from '../hooks/files.hooks.ts';
import NewFilePicker from './NewFilePicker/NewFilePicker.tsx';
import { ShareFeedback } from './ShareFeedback.tsx';
import { MicrophoneIcon } from '@heroicons/react/24/solid';
import { VoiceCloningModal } from './CreateVoiceCloneWizard/CreateVoiceCloneWizard.tsx';
import { Play3AnnouncementModal } from './News/Play3AnnouncementModal.tsx';

const getRecentFilesMenuItems: (recentFiles: RecentFile[]) => MenuItem[] = (recentFiles) => [
  {
    name: 'Recent Files',
    icon: ClockIcon,
    children: recentFiles.map((f) => ({
      name: f.title ?? defaultFileName,
      href: `/studio/files/${f.id}`,
    })),
  },
];

const generalMenuItems: MenuItem[] = [
  { name: 'Files', icon: FolderIcon, href: '/studio/files' },
  { name: 'Voice Cloning', icon: SparklesIcon, href: '/studio/voice-cloning' },
  { name: 'API Access', href: '/studio/api-access', icon: KeyIcon },
  { name: 'Billing', href: '/studio/billing', icon: CreditCardIcon },
  // { name: 'Team Access', href: '/studio/team-access', icon: UserGroupIcon },
];

export function SidebarContent({ toggleSidebar = () => null }: { toggleSidebar?: (open: boolean) => void }) {
  const { currentUser, logOut } = useAuth();
  const location = useLocation();
  const recentFiles = useRecentFiles() ?? [];
  const { createAndOpenFile, isLoading: isCreatingFile } = useCreateAndOpenFile(() => toggleSidebar(false));
  const openVoiceCloningModal = VoiceCloningModal.useStore((state) => state.open);
  const menuItems = [...getRecentFilesMenuItems(recentFiles), ...generalMenuItems];

  return (
    <div className="flex flex-col gap-y-5 bg-gray-800 pb-6 pt-5 border-r border-r-gray-700 h-full">
      <div className="flex items-center justify-between px-6">
        <Link className="my-2 ml-2 block" to="/studio/files">
          <PlayhtLogo className="h-12" />
        </Link>

        <div className="flex gap-4">
          <Link to="https://twitter.com/play_ht" target="_blank">
            <img src={TwitterIcon} alt="Twitter" className="h-6" />
          </Link>
          <Link to="https://discord.gg/ZGganaStFq" target="_blank">
            <img src={DiscordIcon} alt="Discord" className="h-6" />
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-y-3">
        <NewFilePicker isCreatingFile={isCreatingFile} createAndOpenFile={createAndOpenFile} />
        <div className="px-6 flex">
          <button
            type="button"
            className="bg-gray-900 transition duration-200 ease-out hover:opacity-90 flex-1 p-4 rounded-2xl border border-gray-700 hover:border-white/50 flex items-center justify-center gap-1"
            onClick={() => openVoiceCloningModal()}
          >
            <MicrophoneIcon className="w-[18px] h-[18px] text-white " />
            <span className="text-white leading-[20px] text-xs font-semibold">Create Voice Clone</span>
          </button>
        </div>
      </div>
      <nav className="flex flex-col h-full overflow-hidden">
        <div className="flex flex-col justify-between  h-full overflow-y-auto px-6">
          <div>{renderMenuItems(location, menuItems, toggleSidebar)}</div>
          <div className="flex flex-col ">
            <Play3AnnouncementModal />
            <ShareFeedback />
            <LegacyAppLink />
            <CreditsCounter onClick={() => toggleSidebar(false)} />
            {renderProfile(currentUser, logOut)}
          </div>
        </div>
      </nav>
    </div>
  );
}

function renderMenuItems(location: Location, items: MenuItem[], toggleSidebar: (open: boolean) => void) {
  return (
    <ul role="list" className="space-y-2">
      {items.map((item) => (
        <li key={item.name}>
          {!item.children && renderMenuItemWithoutSubItems(location, item, toggleSidebar)}
          {item.children && renderMenuItemWithSubItems(location, item, toggleSidebar)}
        </li>
      ))}
    </ul>
  );
}

function renderMenuItemWithoutSubItems(location: Location, item: MenuItem, toggleSidebar: (open: boolean) => void) {
  return (
    <Link to={item.href ?? '#'} onClick={() => toggleSidebar(false)}>
      {renderMenuItem(location, item, isCurrentItem(location, item))}
    </Link>
  );
}

function renderMenuItemWithSubItems(location: Location, item: MenuItem, toggleSidebar: (open: boolean) => void) {
  return (
    <div key={location.pathname + getItemHash(item)}>
      <Disclosure as="div" defaultOpen={isItemInitiallyOpen(location, item)}>
        {({ open }) => (
          <div
            className={clsx('rounded-xl transition duration-300 ease-in-out', {
              'bg-neutral-100 pb-2 font-semibold dark:bg-neutral-800': isCurrentItem(location, item) || open,
            })}
          >
            <Disclosure.Button className="flex w-full items-center justify-between">
              {renderMenuItem(location, item, false)}
              <ChevronRightIcon className="mr-3 h-3.5 rotate-90" />
            </Disclosure.Button>
            <Disclosure.Panel as="ul">
              {item.children?.map((subItem) => (
                <li key={subItem.name + subItem.href} className="px-4 py-2.5 font-normal text-xs">
                  <Link to={subItem.href ?? '#'} onClick={() => toggleSidebar(false)}>
                    <div className="truncate" title={subItem.name}>
                      {subItem.name}
                    </div>
                  </Link>
                </li>
              ))}
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </div>
  );
}

function renderMenuItem(location: Location, item: MenuItem, isCurrent: boolean) {
  return (
    <div
      className={clsx(
        'group flex items-center gap-x-3 rounded-xl px-3 py-3 text-xs transition duration-300 ease-in-out hover:dark:bg-neutral-800',
        { 'bg-neutral-100 font-semibold dark:bg-neutral-800': isCurrent }
      )}
    >
      {item.icon && <item.icon className="h-5 w-5 shrink-0" aria-hidden="true" />}
      {item.name}
    </div>
  );
}

function renderProfile(user: FirebaseUser | null, logOut: () => void) {
  const userDisplayName = getUserDisplayName(user);
  return (
    <div className="flex items-center gap-x-2 rounded-xl px-2 py-3 bg-gray-900">
      {user?.photoURL ? (
        <img className="h-8 w-8 rounded-full" src={user.photoURL} alt="" />
      ) : (
        <UserCircleIcon className="h-11 w-11 rounded-full" />
      )}
      <div className="flex flex-grow items-center justify-between ">
        <span className="text-white text-[14px] leading-[20px] font-semibold">{userDisplayName}</span>
        <button onClick={logOut} className="duration-200 ease-out hover:opacity-80">
          <ArrowRightOnRectangleIcon className="h-6 w-6 text-white" title={`v${CONFIG.pwaVersionNumber}`}>
            Log out
          </ArrowRightOnRectangleIcon>
        </button>
      </div>
    </div>
  );
}

function isCurrentItem(location: Location, item: MenuItem) {
  return location.pathname === item.href;
}

function isItemInitiallyOpen(location: Location, item: MenuItem) {
  return item.defaultOpen || item.children?.some((c) => isCurrentItem(location, c));
}

function getUserDisplayName(user: FirebaseUser | null) {
  const splitAt = 12;
  const userName = user?.displayName ?? user?.email ?? 'User';
  const userFirstName = userName?.split(' ')[0];
  return userFirstName.slice(0, splitAt) + (userFirstName.length > splitAt ? '...' : '');
}

function getItemHash(item: MenuItem): string {
  const childrenHash = item.children?.map((c) => getItemHash(c)).join('') ?? '';
  return `${item.name}-${item.href}-${childrenHash}`;
}
