import { useCallback, useEffect, useRef, useState } from 'react';

import { useBillingInfo } from '../../hooks/billing.hooks.ts';
import { Spinner } from '../../components/Spinner.tsx';
import { TextField } from '../../components/TextField.tsx';
import { BillingAddress } from '../../domain/billing/BillingInfo.ts';
import { updateBillingInfo } from '../../API/billing.requests.ts';
import { useAuth } from '../../contexts/auth.context.tsx';
import { Button } from '../../components/Button.tsx';

export function BillingDetailsSection() {
  const auth = useAuth();

  const details = useBillingInfo();
  // const { name, email, phone, address } = details;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [address, setAddress] = useState<BillingAddress>({});

  const [requestInProgress, setRequestInProgress] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const successRemoveTimer = useRef<number | undefined>();

  useEffect(() => {
    setName(details?.name || '');
    setEmail(details?.email || '');
    setPhone(details?.phone || '');
    setAddress(details?.address || {});
  }, [details]);

  const resetSuccess = useCallback(() => {
    setSuccess(false);
    clearTimeout(successRemoveTimer.current);
  }, [setSuccess]);

  const setAddressField = useCallback(
    (field: keyof BillingAddress, value: string) => {
      resetSuccess();
      setAddress((prev) => ({ ...prev, [field]: value }));
    },
    [setAddress, resetSuccess]
  );

  if (!details) return <Spinner className="pt-4" label="Loading billing details..." />;

  return (
    <div>
      <div className="mt-6">
        <div className="mb-10">
          <TextField
            name="fullName"
            label="Full name"
            type="text"
            autoComplete="name"
            value={name}
            onChange={(value) => {
              setName(value);
              resetSuccess();
            }}
          />
        </div>
        <div className="mb-10">
          <TextField
            name="email"
            label="Email"
            type="email"
            autoComplete="email"
            value={email}
            onChange={(value) => {
              setEmail(value);
              resetSuccess();
            }}
          />
        </div>
        <div className="mb-10">
          <TextField
            name="phone"
            label="Phone"
            type="text"
            autoComplete="tel"
            value={phone}
            onChange={(value) => {
              setPhone(value);
              resetSuccess();
            }}
          />
        </div>
        <div className="mb-1.5">
          <TextField
            name="addressLine1"
            label="Billing Address"
            type="text"
            autoComplete="address-line1"
            placeholder="Address line 1"
            value={address.line1 || ''}
            onChange={(value) => setAddressField('line1', value)}
          />
        </div>
        <div className="mb-1.5">
          <TextField
            name="addressLine2"
            label=""
            type="text"
            autoComplete="address-line2"
            placeholder="Address line 2"
            value={address.line2 || ''}
            onChange={(value) => setAddressField('line2', value)}
          />
        </div>
        <div className="mb-1.5">
          <TextField
            name="city"
            label=""
            type="text"
            placeholder="City"
            autoComplete="address-level2"
            value={address.city || ''}
            onChange={(value) => setAddressField('city', value)}
          />
        </div>
        <div className="mb-1.5">
          <TextField
            name="state"
            label=""
            type="text"
            placeholder="State"
            autoComplete="address-level1"
            value={address.state || ''}
            onChange={(value) => setAddressField('state', value)}
          />
        </div>
        <div className="mb-1.5">
          <TextField
            name="country"
            label=""
            type="text"
            placeholder="Country"
            autoComplete="country-name"
            value={address.country || ''}
            onChange={(value) => setAddressField('country', value)}
          />
        </div>
        <div className="mb-6">
          <TextField
            name="postalCode"
            label=""
            type="text"
            placeholder="Postal code"
            autoComplete="postal-code"
            value={address.postal_code || ''}
            onChange={(value) => setAddressField('postal_code', value)}
          />
        </div>
        <Button
          onClick={async () => {
            if (!auth.currentUser) {
              throw new Error('User not authorized');
            }
            const idToken = await auth.currentUser?.getIdToken();
            if (!idToken) {
              throw new Error('No idToken');
            }

            setRequestInProgress(true);
            setError(null);
            setSuccess(false);
            try {
              await updateBillingInfo(idToken, {
                name,
                email,
                phone,
                address,
              });
              setSuccess(true);
              successRemoveTimer.current = window.setTimeout(() => {
                setSuccess(false);
              }, 2000);
            } catch (error) {
              setError(`Error. Couldn't update billing info: ${(error as Error).message}`);
            }
            setRequestInProgress(false);
          }}
          intent="primary"
          size="medium"
          disabled={requestInProgress || success}
        >
          {requestInProgress ? 'Saving...' : success ? 'Saved!' : 'Save'}
        </Button>
        {error && <div className="text-error-600">{error}</div>}
      </div>
    </div>
  );
}
