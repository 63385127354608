import { useEffect, useState } from 'react';
import { Bars3Icon } from '@heroicons/react/20/solid';
import { Sidebar } from '../components/Sidebar.tsx';
import { Link, Outlet } from 'react-router-dom';
import RequireAuth from '../components/RequireAuth.tsx';
import { RequireOnboarding } from '../components/RequireOnboarding.tsx';
import { PlayhtLogo } from '../components/PlayhtLogo.tsx';
import DiscordIcon from '../assets/discord.svg';
import TwitterIcon from '../assets/twitter-x.svg';
import { clsx } from 'clsx';

export function SidebarLayout({ contentPadded = false }: { contentPadded?: boolean }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <RequireAuth>
      <RequireOnboarding>
        <>
          <Sidebar open={sidebarOpen} toggleSidebar={setSidebarOpen} />
          <div className="h-fit desktop:pl-[var(--sidebar-width)]">
            <HeaderForContentPadded setSidebarOpen={setSidebarOpen} />
            <main
              className={clsx('h-full', {
                'px-8 py-4 max-tablet:px-4 max-desktop:pt-24': contentPadded,
                'max-tablet:pt-16': !contentPadded,
              })}
            >
              <Outlet />
            </main>
          </div>
        </>
      </RequireOnboarding>
    </RequireAuth>
  );
}

export function HeaderForContentPadded({ setSidebarOpen }: { setSidebarOpen: (value: boolean) => void }) {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isScrolledDown, setIsScrolledDown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 10 && scrollTop > lastScrollTop) {
        setIsScrolledDown(true);
      } else {
        setIsScrolledDown(false);
      }
      setLastScrollTop(scrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  return (
    <header
      className={clsx(
        'fixed top-0 z-20 flex h-16 w-full transform items-center justify-between gap-4 border-b border-neutral-800 bg-neutral-950 p-4 transition-all duration-200 ease-linear desktop:hidden',
        isScrolledDown ? '-translate-y-full' : 'translate-y-0'
      )}
    >
      <div className="flex items-center justify-center gap-4">
        <button
          type="button"
          className="-m-2.5 p-2.5 dark:text-neutral-200 desktop:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6" aria-hidden="true" />
        </button>
        <PlayhtLogo className="relative top-0.5 h-11" />
      </div>
      <div className="flex gap-4">
        <Link to="https://twitter.com/play_ht" target="_blank">
          <img src={TwitterIcon} alt="Twitter" className="h-6" />
        </Link>
        <Link to="https://discord.gg/ZGganaStFq" target="_blank">
          <img src={DiscordIcon} alt="Discord" className="h-6" />
        </Link>
      </div>
    </header>
  );
}
