import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export function useQueryParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export function useQueryParam(key: string) {
  const params = useQueryParams();
  return params.get(key);
}
