import { clsx } from 'clsx';

export function StepsIndicator({ totalSteps, currentStep }: { totalSteps: number; currentStep: number }) {
  return (
    <div className="flex flex-nowrap items-center justify-center gap-2">
      {Array.from({ length: totalSteps }).map((_, i) => (
        <div
          key={i}
          className={clsx('h-1 w-10 rounded-full', i + 1 === currentStep ? 'bg-primary' : 'bg-neutral-700')}
        />
      ))}
    </div>
  );
}
