import { type DropzoneProps, useDropzone } from 'react-dropzone';
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import { MAX_VOICE_SAMPLE_FILE_SIZE } from '../../play-voice-domain/src/Voice';
import clsx from 'clsx';

export interface DropzoneFile {
  file: File;
  progress: number;
  url?: string;
}

export interface FilesDropzoneProps extends DropzoneProps {
  name: string;
  title: string;
  className?: string;
  files?: Array<DropzoneFile>;
  onFilesSelected: (files: Array<File>) => void;
}

export function FilesDropzone({ name, title, onFilesSelected, className, ...dropzoneProps }: FilesDropzoneProps) {
  const { getRootProps, getInputProps } = useDropzone({
    ...dropzoneProps,
    onDrop: onFilesSelected,
  });

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    window.open('https://help.play.ht/en/article/voice-cloning-tips-1m8z242', '_blank');
  };

  return (
    <>
      <button {...getRootProps()} className={clsx('flex flex-col  py-3', className)}>
        <input name={name} {...getInputProps()} />
        <div className="flex items-center mb-1 gap-2">
          <ArrowUpOnSquareIcon className="h-5 w-5 text-primary-green-500 m-[5px]" />
          <span className="text-[14px] leading-[21px] text-primary-green-500 font-medium text-left">{title}</span>
        </div>

        <div className="flex flex-col px-[40px] ">
          <span className="text-left text-[12px] leading-[20px] text-white/60 font-normal">
            [ .mp3, .wav, .m4a, .mp4, .AAC, ... up to {MAX_VOICE_SAMPLE_FILE_SIZE / (1024 * 1024)}MB. ]
          </span>
          <span className="text-left text-[12px] leading-[20px] text-white/60 font-normal">
            For best quality{' '}
            <span className="underline" onClick={onClick}>
              read these tips.
            </span>
          </span>
        </div>
      </button>
    </>
  );
}
