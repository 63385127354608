import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import {
  BrowserTracing,
  captureException,
  init as initSentry,
  reactRouterV6Instrumentation,
  setUser as setSentryUser,
  wrapCreateBrowserRouter,
} from '@sentry/react';
import { Contexts, Extras } from '@sentry/types';
import { CONFIG } from '../../config.ts';
import { Primitive } from 'zod';
import { NonErrorThrownError } from './errortypes/NonErrorThrownError.ts';
import { stringifySafelyEachProp } from '../../utils/stringUtils.ts';
import { isErrorSkippableForSentry } from './errortypes/SentrySkippableError.ts';
import axios, { CreateAxiosDefaults, isAxiosError } from 'axios';
import { removePropsFromObject } from '../../utils/removePropsFromObject.ts';
import { QueryClient, QueryClientConfig } from '@tanstack/react-query';

export type SentryErrorLevel =
  // 'fatal' // Indicates a critical error which MUST be investigated immediately; Ideally it should not be sent manually
  // 'log' // In practice we would use it the same way as 'info', so let's use 'info' only to avoid confusion
  // 'debug' // In practice we would use it the same way as 'info', so let's use 'info' only to avoid confusion
  | 'error' // Indicates an error which MUST be investigated
  | 'warning' // May indicate an error that MAYBE SHOULD be investigated
  | 'info'; // Use only as a possible investigation complementary info, so it SHOULD NOT be investigated

type Tags = Record<string, Primitive>;
export interface SentryEventAdditionalData {
  tags?: Tags; // Custom key/value pairs that are indexed and searchable, so don't over use it since it will increase Sentry costs
  contexts?: Contexts; // Structured record of custom contexts that are not indexed nor searchable; each context has its own set of key/value pairs
  extra?: Extras; // Custom key/value pairs that are not indexed nor searchable
}

let sentryInitialized = false;
let axiosTrackingEnabled = false;
wrapAxiosForTracking();

export const errorsTracker = {
  init,
  createRouterWithErrorHandler: wrapCreateBrowserRouter(createBrowserRouter),
  createReactQueryClient,
  setUserContext: setSentryUser,
  report,
};

// We need to initialize Sentry for React and for Service Workers separately.
// Ideally we shouldn't use tracing in Service Workers.
function init({ disableTracing = false, disableAxiosTracking = false } = {}) {
  if (sentryInitialized) return;
  sentryInitialized = true;
  axiosTrackingEnabled = !disableAxiosTracking;
  initSentry({
    dsn: CONFIG.sentryDsn,
    ...(!disableTracing && {
      tracesSampleRate: 0.05,
      integrations: [
        new BrowserTracing({
          tracePropagationTargets: ['localhost', /^https:\/\/((pwa|staging)\.)?play\.ht\/(api|bff)/],
          routingInstrumentation: reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
      ],
    }),
  });
}

function createReactQueryClient({ defaultOptions, ...config }: QueryClientConfig = {}) {
  const onQueryError = defaultOptions?.queries?.onError;
  const onMutationError = defaultOptions?.mutations?.onError;
  const wrappedDefaultOptions = {
    ...defaultOptions,
    queries: {
      ...defaultOptions?.queries,
      onError: (error: unknown) => {
        report('warning', error, 'Unhandled Query Error', { tags: { reactQueryError: true } });
        onQueryError?.(error);
      },
    },
    mutations: {
      ...defaultOptions?.mutations,
      onError: (error: unknown, variables: unknown, context: unknown) => {
        report('warning', error, 'Unhandled Mutation Error', { tags: { reactQueryError: true }, extra: { variables } });
        onMutationError?.(error, variables, context);
      },
    },
  };
  return new QueryClient({ ...config, defaultOptions: wrappedDefaultOptions });
}

/**
 * Log or send the error to Sentry.
 *
 * @param level The severity level of the event. See [`SentryErrorLevel `](#SentryErrorLevel) for more details.
 * @param throwable It is supposed to be an `Error` object that was or will be thrown at some point, but it can be
 *   anything. If it's not an Error, it will be wrapped in a [`NonErrorThrownError`](./errors/NonErrorThrownError.ts).
 * @param title It will be concatenated to the default issue title (which is the error name).
 * @param additionalData Additional data (tags, contexts, extras) to be sent to Sentry.
 *   See [`SentryEventAdditionalData`](#SentryEventAdditionalData) for more details.
 */
function report(
  level: SentryErrorLevel,
  throwable: unknown,
  title?: string,
  additionalData?: SentryEventAdditionalData
) {
  const exception = throwable instanceof Error ? throwable : new NonErrorThrownError(throwable);
  const issueContext = createIssueContext(exception, title, additionalData);
  exception.name = issueContext.title;
  const result = shouldLogOrReportErrorToSentry(exception, level, issueContext.tags, issueContext.extra);
  if (result.shouldSendToSentry) captureException(exception, { level, ...issueContext });
  if (result.shouldLog) console[result.logLevel](...result.logParams);
}

function createIssueContext(exception: Error, title?: string, additionalData?: SentryEventAdditionalData) {
  let titleExtension = '';
  const tags: Tags = additionalData?.tags ?? {};
  const contexts: Contexts = additionalData?.contexts ?? {};
  const extra: Extras = additionalData?.extra ?? {};
  contexts['error-props'] = stringifySafelyEachProp(removePropsFromObject(exception, 'stack', 'cause'));
  if (isAxiosError(exception)) {
    tags.axiosError = true;
    titleExtension += exception.response
      ? ` -- Axios Response Error [${exception.response.status}]`
      : ' -- Request Error';
    contexts['axios-error-summary'] = {
      hasConfig: !!exception.config,
      hasRequest: !!exception.request,
      hasResponse: !!exception.response,
      responseStatus: exception.response?.status,
    };
    if (exception.config) contexts['axios-error-config'] = stringifySafelyEachProp(exception.config);
    if (exception.request) contexts['axios-error-request'] = stringifySafelyEachProp(exception.request);
    if (exception.response) contexts['axios-error-response'] = stringifySafelyEachProp(exception.response);
  }
  titleExtension += title ? ` -- ${title}` : '';
  const issueTitle = (exception.name + titleExtension).slice(0, 100);
  const fingerprint = [issueTitle.replace(/\s/g, '-')];
  if (!titleExtension) fingerprint.push(exception.message);
  return { title: issueTitle, fingerprint, tags, contexts, extra };
}

function shouldLogOrReportErrorToSentry(exception: Error, level: SentryErrorLevel, tags: Tags, extras: Extras) {
  if (CONFIG.isProduction) {
    return { shouldSendToSentry: true, shouldLog: false } as const;
  }
  if (CONFIG.isRunningJestOnLocal && CONFIG.isSentryReportingForRunningJestOnLocalDisabled) {
    const message = `[SENTRY] Error would be reported - Change SENTRY_REPORTING_FOR_RUNNING_JEST_ON_LOCAL env var to ENABLED if you want errors to be reported. `;
    const logParams = [message, exception, tags, extras];
    return { shouldSendToSentry: false, shouldLog: true, logLevel: 'info', logParams: logParams } as const;
  }
  if (isErrorSkippableForSentry(exception)) {
    const message = `[SENTRY] Error not reported - The error has a skipSentry flag set to true. `;
    const logParams = [message, exception];
    return { shouldSendToSentry: false, shouldLog: true, logLevel: 'info', logParams: logParams } as const;
  }
  const logLevel = level === 'warning' ? 'error' : level;
  return { shouldSendToSentry: true, shouldLog: true, logLevel, logParams: [exception] } as const;
}

function wrapAxiosForTracking() {
  axios.interceptors.request.use((config) => config, onAxiosError);
  axios.interceptors.response.use((response) => response, onAxiosError);
  const originalAxiosCreate = axios.create;
  axios.create = (config?: CreateAxiosDefaults) => {
    const instance = originalAxiosCreate(config);
    instance.interceptors.request.use((config) => config, onAxiosError);
    instance.interceptors.response.use((response) => response, onAxiosError);
    return instance;
  };
}

function onAxiosError(error: unknown) {
  if (axiosTrackingEnabled) {
    const level: SentryErrorLevel = isAxiosError(error) && (error.response?.status ?? 0) >= 400 ? 'warning' : 'info';
    report(level, error);
  }
  return Promise.reject(error);
}
