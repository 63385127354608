import { Dialog, Transition } from '@headlessui/react';
import { ForwardRefExoticComponent, Fragment, PropsWithoutRef, SVGProps } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { SidebarContent } from './SidebarContent.tsx';

export interface MenuItem {
  name: string;
  icon?: ForwardRefExoticComponent<PropsWithoutRef<SVGProps<SVGSVGElement>>>;
  href?: string;
  defaultOpen?: boolean;
  children?: MenuItem[];
}

export interface SidebarProps {
  open: boolean;
  toggleSidebar: (open: boolean) => void;
}

export function Sidebar({ open, toggleSidebar }: SidebarProps) {
  return (
    <>
      <div className="hidden desktop:fixed desktop:inset-y-0 desktop:z-[var(--z-index-nav)] desktop:flex w-[var(--sidebar-width)] desktop:flex-col h-screen">
        <SidebarContent />
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-30 desktop:hidden" onClose={toggleSidebar}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-neutral-700/10 backdrop-blur-sm" />
          </Transition.Child>
          <div className="fixed inset-0 flex ">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-col">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => toggleSidebar(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-neutral-50" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <SidebarContent toggleSidebar={toggleSidebar} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
