import { UserInfo as FbUserInfo } from 'firebase/auth';
import { User } from '../contexts/user.context.tsx';
import { getActivePackage } from './plans.helpers.tsx';

export function generateUserDataForTracking(userInfo: User) {
  const utmPayload = JSON.parse(localStorage.getItem('utm') ?? '{}');
  return {
    userId: userInfo?.id || '',
    is_pro: userInfo.is_pro ?? false,
    plan: getActivePackage(userInfo)?.id ?? 'free',
    date: new Date().toJSON(),
    userEmail: userInfo.email,
    ...utmPayload,
  };
}

export function generateUserDataFromFirebaseUserForTracking(userInfo: FbUserInfo) {
  return {
    userId: userInfo.uid,
    date: new Date().toJSON(),
    userEmail: userInfo.email,
    email: userInfo.email,
  };
}
