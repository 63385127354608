import LogoDark from '../assets/playht-logo-dark.svg';
import LogoLight from '../assets/playht-logo-light.svg';
import { clsx } from 'clsx';

export function PlayhtLogo({ className = '' }: { className?: string }) {
  return (
    <>
      <img src={LogoDark} className={clsx('hidden dark:block', className)} alt="PlayHT" />
      <img src={LogoLight} className={clsx('block dark:hidden', className)} alt="PlayHT" />
    </>
  );
}
