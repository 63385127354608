// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removePropsFromObject(obj: Record<string, any>, ...propsToRemove: string[]) {
  const newObj: Record<string, unknown> = {};
  Object.getOwnPropertyNames(obj).forEach((key) => {
    if (!propsToRemove.includes(key)) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}
