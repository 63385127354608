import mixpanel from 'mixpanel-browser';
import { CONFIG } from '../../config.ts';
import { Primitive } from 'zod';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { PlanT } from '../../utils/plans.helpers.tsx';
import { v4 as uuid } from 'uuid';

export enum MixpanelEventType {
  SigupWithEmail = 'Sign Up with Email and Password',
  SigupWithGoogle = 'Sign Up with Google',
  Purchase = 'Purchase',
  Onboard = 'Onboard',
  FILE_DOWNLOAD = 'FILE_DOWNLOAD',
  DOWNLOAD_INDIVIDUAL_SAMPLE = 'DOWNLOAD_INDIVIDUAL_SAMPLE',
  EXPORT_PROJECT_AS_ZIP = 'EXPORT_PROJECT_AS_ZIP',
  EXPORT_PROJECT_AS_AUDIO = 'EXPORT_PROJECT_AS_AUDIO',
  EXPORT_PROJECT_AS_VIDEO = 'EXPORT_PROJECT_AS_VIDEO',
  FAILED_EXPORT_PROJECT_BY_TIMEOUT = 'FAILED_EXPORT_PROJECT_BY_TIMEOUT',
  IMPORT_VIDEO = 'IMPORT_VIDEO',
  VIDEO_UPLOADED = 'VIDEO_UPLOADED',
}

export const eventsTracker = {
  init,
  sendMixpanelEvent,
  sendGtmEvent,
  sendGtmPurchaseEvent,
  sendProfitwellEvent,
};

function init() {
  initMixpanel();
  initGoogleTracking();
  initProfitwell();
}

function initMixpanel() {
  mixpanel.init(CONFIG.mixpanelKey);
  mixpanel.set_config({ opt_out_tracking_by_default: true });
}

function initGoogleTracking() {
  if (CONFIG.environment === 'PRODUCTION') {
    ReactGA.initialize(CONFIG.googleAnalyticsTag);
    TagManager.initialize({ gtmId: CONFIG.googleTagManagerId });
  }
}

function initProfitwell() {
  const script = document.createElement('script');
  script.id = 'profitwell-js';
  script.setAttribute('data-pw-auth', CONFIG.profitwellKey);
  script.textContent = `
    (function (i, s, o, g, r, a, m) {
      i[o] =
        i[o] ||
        function () {
          (i[o].q = i[o].q || []).push(arguments);
        };
      a = s.createElement(g);
      m = s.getElementsByTagName(g)[0];
      a.async = 1;
      a.src = r + '?auth=' + s.getElementById(o + '-js').getAttribute('data-pw-auth');
      m.parentNode.insertBefore(a, m);
      console.log('Loading Profitwell script');
    })(window, document, 'profitwell', 'script', 'https://public.profitwell.com/js/profitwell.js');
  `;
  document.body.appendChild(script);
}

// TODO: So far all calls to this function the payload is something like `generateUserDataForTracking(user)`, so we could:
//   - Pass the user object directly to this function and extract the data here
//   - Get the user inside this function somehow (since user is from a React context, would need a hook or change for something like Zustand)
function sendMixpanelEvent(type: MixpanelEventType, payload: Record<string, Primitive>) {
  switch (type) {
    case MixpanelEventType.SigupWithEmail:
      return trackRoostMixpanelEvent(MixpanelEventType.SigupWithEmail, payload);
    case MixpanelEventType.SigupWithGoogle:
      return trackRoostMixpanelEvent(MixpanelEventType.SigupWithGoogle, payload);
    case MixpanelEventType.Purchase:
      return trackRoostMixpanelEvent(MixpanelEventType.Purchase, payload);
    case MixpanelEventType.Onboard:
      return trackRoostMixpanelEvent(MixpanelEventType.Onboard, payload);
    case MixpanelEventType.FILE_DOWNLOAD:
      return trackRoostMixpanelEvent('download', payload);
    case MixpanelEventType.DOWNLOAD_INDIVIDUAL_SAMPLE:
      return trackRoostMixpanelEvent('download-individual-sample', payload);
    case MixpanelEventType.EXPORT_PROJECT_AS_ZIP:
      return trackRoostMixpanelEvent('export-project', { ...payload, exportType: 'zip' });
    case MixpanelEventType.EXPORT_PROJECT_AS_AUDIO:
      return trackRoostMixpanelEvent('export-project', { ...payload, exportType: 'audio' });
    case MixpanelEventType.EXPORT_PROJECT_AS_VIDEO:
      return trackRoostMixpanelEvent('export-project', { ...payload, exportType: 'video' });
    case MixpanelEventType.FAILED_EXPORT_PROJECT_BY_TIMEOUT:
      return trackRoostMixpanelEvent('failed-export-project', { ...payload, failType: 'error' });
    case MixpanelEventType.IMPORT_VIDEO:
      return trackRoostMixpanelEvent('import-video', payload);
    case MixpanelEventType.VIDEO_UPLOADED:
      return trackRoostMixpanelEvent('video-uploaded', payload);
    default:
      return;
  }
}

function trackRoostMixpanelEvent(eventName: string, payload: Record<string, Primitive>) {
  return mixpanel.track(eventName, { ...payload, platform: 'roost' });
}

function sendGtmEvent(eventName: string, payload: Record<string, unknown>) {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ...payload,
    },
  });
}

function sendGtmPurchaseEvent(plan: PlanT) {
  sendGtmEvent(`purchase_${plan.priceId}`, {
    transaction_id: uuid(),
    value: plan.price,
    currency: 'USD',
    items: [
      {
        item_id: plan.priceId,
        item_name: plan.name,
        price: plan.price,
        currency: 'USD',
        quantity: 1,
      },
    ],
  });
}

type ProfitwellFunction = undefined | ((eventName: string, payload?: ProfitWellPayload) => Promise<ProfitWellResponse>);
type ProfitWellPayload = { subscription_id?: string; user_id?: string; user_email?: string };
type ProfitWellResponse = { status: string; cancelReason?: string };

function sendProfitwellEvent(eventName: string, payload: ProfitWellPayload) {
  // @ts-expect-error - profitwell is being added to `window` by `initProfitwell`
  const profitwell: ProfitwellFunction = window.profitwell;
  return (
    profitwell?.(eventName, payload) ??
    Promise.resolve({ status: 'unavailable', cancelReason: 'profitwell not available' })
  );
}
