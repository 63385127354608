import { useUser } from '../contexts/user.context.tsx';
import { useFirebaseListener } from './firebase.hooks.ts';
import { FileId } from '../domain/files/File.ts';
import { useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/auth.context.tsx';
import { CONFIG } from '../config.ts';

interface RecentFilesNode {
  lastCleanUpDate: number; // unix timestamp in milliseconds
  files?: Record<FileId, RecentFile>;
}
export interface RecentFile {
  id: FileId;
  title: string | null;
  modifiedDate: number; // unix timestamp in milliseconds
}

// the value below must be in sync with Play-Studio/backend
// https://github.com/playht/Play-Studio/blob/c70a69e45f7dcd57c7137fc71aa4510a9d6ada93/backend/src/services/kettle/RecentFiles.service.js#L5
const NUMBER_OF_RECENT_FILES = 5;

// Notice this is a global state, not a component state. The intention is to
// never dispatch the request more than once, even if the hook is used in
// multiple components.
let refreshRequested = false;

export function useRecentFiles() {
  const { currentUser } = useAuth();
  const { user } = useUser();
  const [recentFilesNode, state] = useFirebaseListener<{ exists: boolean; files: Array<RecentFile> }, RecentFilesNode>(
    `recentFiles/${user?.id}`,
    {
      transform(recentFilesFB) {
        return {
          exists: recentFilesFB !== null,
          // set to null all titles that are undefined
          files: Object.entries(recentFilesFB?.files ?? {})
            .map(([, rf]) => ({ ...rf, title: rf.title || null }))
            .sort((a, b) => b.modifiedDate - a.modifiedDate)
            .slice(0, NUMBER_OF_RECENT_FILES),
        };
      },
      databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_V2_URL,
    }
  );

  useEffect(() => {
    if (state === 'LISTENING' && !recentFilesNode?.exists && !refreshRequested) {
      refreshRequested = true;
      currentUser
        ?.getIdToken()
        .then((token) =>
          axios.put(`${CONFIG.playStudioAPIBaseUrl}/kettle/recent-files/refresh`, {
            token,
          })
        )
        .catch(() => {
          alert('An error occurred while trying to obtain your recent files list. Please refresh the page.');
        });
    }
  }, [currentUser, recentFilesNode, state]);

  if (!recentFilesNode?.exists) {
    return null;
  }
  return recentFilesNode.files;
}
