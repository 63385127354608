import { useFiles } from '../../hooks/files.hooks.ts';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { FilesTable } from './FilesTable.tsx';
import { CounterBullet } from '../../components/CounterBullet.tsx';
import { TextField } from '../../components/TextField.tsx';
import { DeleteFilesButton } from './DeleteFilesButton.tsx';
import { defaultFileName } from '../../domain/files/File.ts';

export function FilesListPage() {
  const [files, unsubscribeAndCleanUp] = useFiles();
  const [filteredFiles, setFilteredFiles] = useState(files);
  const [selectedFilesIds, setSelectedFilesIds] = useState<string[]>([]);
  const [searchingValue, setSearchingValue] = useState('');

  const onDelete = (deletedFilesIds: string[]) => {
    setSelectedFilesIds(selectedFilesIds.filter((id) => !deletedFilesIds.includes(id)));
  };

  useEffect(() => {
    if (searchingValue && files) {
      const newFilteredFiles = files.filter((file) =>
        (file.title ?? defaultFileName).toLowerCase().includes(searchingValue.toLowerCase())
      );
      setFilteredFiles(newFilteredFiles);
      setSelectedFilesIds(selectedFilesIds.filter((fileId) => newFilteredFiles.some((f) => f.id === fileId)));
    } else {
      setFilteredFiles(files);
    }
  }, [searchingValue, files, selectedFilesIds]);

  useEffect(() => {
    return unsubscribeAndCleanUp;
  }, [unsubscribeAndCleanUp]);

  return (
    <div className="max-w-5xl">
      <div className="mb-12 flex items-center gap-3">
        <h1 className="text-xl font-semibold">Files</h1>
        {files && <CounterBullet>{files.length}</CounterBullet>}
      </div>
      <div className="mb-8 flex flex-wrap items-center justify-between gap-6">
        <div className="w-96">
          <TextField
            name="search"
            value={searchingValue}
            placeholder="Search audio files"
            type="search"
            autoComplete="off"
            onChange={setSearchingValue}
            width="auto"
            leftIcon={<MagnifyingGlassIcon className="h-5 text-neutral" />}
          />
        </div>
        <div className="flex gap-3">
          <DeleteFilesButton filesIds={selectedFilesIds} onDelete={onDelete} />
        </div>
      </div>
      <div className="relative mt-14">
        <FilesTable files={filteredFiles} selectedFiles={selectedFilesIds} setSelectedFiles={setSelectedFilesIds} />
      </div>
    </div>
  );
}
