import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/20/solid/index.js';
import { Spinner } from '../../../components/Spinner.tsx';

function Loader({ spinnerLabel, error, description }) {
  const navigate = useNavigate();

  return (
    <div className="fixed inset-0 top-14 flex items-center justify-center xl:left-[var(--sidebar-width)]">
      {error ? (
        error
      ) : description ? (
        <>
          <div className="flex justify-center">
            <Spinner label={spinnerLabel} />
          </div>
          {description}
        </>
      ) : (
        <div className="flex justify-center">
          <Spinner label={spinnerLabel} />
        </div>
      )}
      {(error || description) && (
        <div className="flex items-center justify-center gap-2">
          <ArrowLeftIcon className="h-4 w-4 cursor-pointer text-[#272724]" onClick={() => navigate(-1)} />
          <span className="text-lg">Go Back</span>
        </div>
      )}
    </div>
  );
}

export default Loader;
