const ORIGINAL_VOICE_PRESETS = [
  {
    key: 'low-latency',
    label: 'Good (Fast)',
  },
  {
    key: 'balanced',
    label: 'High Quality',
  },
];

const ORIGINAL_PLUS_FT_VOICE_PRESETS = [
  ...ORIGINAL_VOICE_PRESETS,
  {
    key: 'high-quality',
    label: 'High Quality (Fast)',
  },
  {
    key: 'real-time',
    label: 'Real-Time',
  },
];

export function useAvailableVoicePresets() {
  return ORIGINAL_PLUS_FT_VOICE_PRESETS;
}
