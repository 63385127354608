import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../contexts/auth.context';
import {
  cancelPendingInvite,
  createATeam,
  fetchTeamInvites,
  inviteTeamMember,
  removeTeamMember,
} from '../API/teams.requests';
import { User } from '@firebase/auth';
import {
  NOTIFICATION_TYPE_ERROR,
  SetNotification,
  useNotifications,
} from '../__playrepo/ui-components/src/NotificationSnackbar.tsx';

const TEAM_INVITES_QUERY_KEY = 'teamInvites';

interface APIErrorResponse {
  error?: string;
}

function handleMutationError(
  error: AxiosError<APIErrorResponse>,
  defaultErrorMessage: string,
  setNotification: SetNotification
) {
  let message = defaultErrorMessage;

  if (error?.message) {
    message = error.message;
  }

  if (typeof error?.response?.data?.error === 'string') {
    message = error.response.data.error;
  }

  setNotification({
    status: NOTIFICATION_TYPE_ERROR,
    message,
  });
}

async function getUserIdTokenAndThrowIfNotExists(currentUser: User | null) {
  if (!currentUser) {
    throw new Error('cant find user, please try again, or contact support if this persists.');
  }
  const idToken = await currentUser?.getIdToken();

  if (!idToken) {
    throw new Error('cant find user id token, please try again, or contact support if this persists.');
  }

  return idToken;
}

export function useCreateATeam() {
  const { currentUser } = useAuth();
  const { setNotification } = useNotifications();
  return useMutation({
    mutationFn: async () => {
      const idToken = await getUserIdTokenAndThrowIfNotExists(currentUser);
      return createATeam(idToken);
    },
    onSuccess: () => {},
    onError: (error: AxiosError<APIErrorResponse>) => {
      handleMutationError(error, 'Something went wrong while creating your team.', setNotification);
    },
  });
}

export function useTeamInvites(teamId: string) {
  const { currentUser } = useAuth();
  return useQuery([TEAM_INVITES_QUERY_KEY, currentUser?.uid], () => fetchTeamInvites(teamId));
}

export function useInviteTeamMember() {
  const { setNotification } = useNotifications();
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  return useMutation({
    mutationFn: async ({ teamId, memberEmail }: { teamId: string; memberEmail: string }) => {
      const idToken = await getUserIdTokenAndThrowIfNotExists(currentUser);
      return inviteTeamMember(teamId, memberEmail, idToken);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [TEAM_INVITES_QUERY_KEY] }),
    onError: (error: AxiosError<APIErrorResponse>) => {
      handleMutationError(error, 'Something went wrong while inviting your team member.', setNotification);
    },
  });
}

export function useCancelPendingInvite() {
  const { setNotification } = useNotifications();
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  return useMutation({
    mutationFn: async ({ teamId, inviteId }: { teamId: string; inviteId: string }) => {
      const idToken = await getUserIdTokenAndThrowIfNotExists(currentUser);
      return cancelPendingInvite(teamId, inviteId, idToken);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [TEAM_INVITES_QUERY_KEY] }),
    onError: (error: AxiosError<APIErrorResponse>) => {
      handleMutationError(error, 'Something went wrong while cancelling your invite.', setNotification);
    },
  });
}

export function useRemoveTeamMember() {
  const { setNotification } = useNotifications();
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  return useMutation({
    mutationFn: async ({ teamId, inviteId }: { teamId: string; inviteId: string }) => {
      const idToken = await getUserIdTokenAndThrowIfNotExists(currentUser);
      return removeTeamMember(teamId, inviteId, idToken);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [TEAM_INVITES_QUERY_KEY] }),
    onError: (error: AxiosError<APIErrorResponse>) => {
      handleMutationError(error, 'Something went wrong while removing your team member.', setNotification);
    },
  });
}
