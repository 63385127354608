import { useCreateAndOpenFile } from '../../hooks/files.hooks.ts';
import { useAuth } from '../../contexts/auth.context.tsx';
import { useUser } from '../../contexts/user.context.tsx';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { SpinnerFullScreen } from '../../components/SpinnerFullScreen.tsx';
import { useRecentFiles } from '../../hooks/recentFiles.hooks.ts';
import { Button } from '../../components/Button.tsx';

export function KettleOpenMostRecentFile() {
  const { currentUser } = useAuth();
  const { user } = useUser(); // TODO: use just one user
  const { createAndOpenFile, isIdle, isError, reset } = useCreateAndOpenFile();
  const navigate = useNavigate();
  const recentFiles = useRecentFiles();

  useEffect(() => {
    if (!user || !currentUser || !recentFiles) return;
    if (recentFiles.length > 0) return navigate(`/studio/files/${recentFiles[0].id}`);
    if (isIdle) createAndOpenFile();
  }, [user, currentUser, recentFiles, navigate, createAndOpenFile, isIdle]);

  if (isError) {
    return (
      <div className="flex flex-col gap-8 items-center justify-center h-full">
        <p className="text-xl font-semibold text-center">Something went wrong while creating a new file.</p>
        <Button intent="primary" onClick={reset}>
          Try again
        </Button>
      </div>
    );
  }
  return <SpinnerFullScreen label={recentFiles ? 'Creating new file...' : 'Loading recent files...'} />;
}
