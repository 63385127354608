import { DataSnapshot, equalTo, orderByChild, query, get, ref } from 'firebase/database';
import { database } from './firebase';

export type CloningRequestStatus = 'processing' | 'processed' | 'failed' | 'complete';

export const processingStatus: CloningRequestStatus = 'processing';
export const processedStatus: CloningRequestStatus = 'processed';
export const failedStatus: CloningRequestStatus = 'failed';
export const completeStatus: CloningRequestStatus = 'complete';

export interface CloningRequest {
  ar_epochs?: number;
  consentAudioUrl?: string;
  created: string;
  diff_epochs?: number;
  error?: string;
  id: string;
  isZeroShot?: boolean;
  message?: string;
  name: string;
  sampleFileName?: string;
  single_speaker?: boolean;
  status: CloningRequestStatus;
  training_data?: string[];
  type?: string;
  userId: string;
  voiceId?: string;
}

export interface Voice {
  id: string;
  name: string;
  requestId?: string;
  isVerified?: boolean;
  isZeroShot?: boolean;
}

export interface ClonedVoices {
  adminId: string;
  voices?: Voice[];
}

export type VCRequestOrVoice = (CloningRequest & { isVoice: false }) | (Voice & { isVoice: true });

export const fetchCloningRequests = async (userId: string) => {
  const cloningReqRef = ref(database, 'cloningRequests');
  const queryRef = query(cloningReqRef, orderByChild('userId'), equalTo(userId));
  const snapshot: DataSnapshot = await get(queryRef);
  const requests: CloningRequest[] = snapshot.val();
  if (!requests) {
    return [];
  } else {
    return Object.values(requests).sort(
      (a, b) => Number(new Date(b?.created || '')) - Number(new Date(a?.created || ''))
    );
  }
};

export const getMyClonedVoices = async (userId: string) => {
  const ClonedRef = ref(database, 'clonedVoices');
  const queryRef = query(ClonedRef, orderByChild('adminId'), equalTo(userId));
  const snapshot: DataSnapshot = await get(queryRef);
  const clonedVoices: ClonedVoices[] = snapshot.val();

  if (clonedVoices) {
    return Object.values(clonedVoices)[0].voices || [];
  } else {
    return [] as Voice[];
  }
};

export function getStatusColor(status: CloningRequestStatus) {
  switch (status) {
    case 'processed':
      return '#FF8944';
    case 'processing':
      return '#FF8944';
    case 'complete':
      return '#01DA72';
    case 'failed':
      return '#EC635C';
    default:
      return '#000';
  }
}
