import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { claimVoice, getSharedVoiceDetails } from '../../API/voiceCloning.requests';
import { AxiosError } from 'axios';
import { useAuth } from '../../contexts/auth.context';
import { Spinner } from '../../components/Spinner';
import { VoiceCloningIcon } from '../../components/Icons/VoiceCloningIcon.tsx';
import { OldButton } from '../../components/OldButton.tsx';

interface VoiceData {
  createdAt: string;
  sharedBy: string;
  voiceHash: string;
  voiceId: string;
  voiceName: string;
}

export function VCClaimVoicePage() {
  const { voiceHash } = useParams();
  const { currentUser } = useAuth();

  const [voiceDetails, setVoiceDetails] = useState<null | VoiceData>(null);
  const [isLoadingVoiceData, setLoadingVoiceData] = useState(false);
  const [loadingVoiceError, setLoadingVoiceError] = useState<string | null>(null);

  const [isClaimingVoice, setClaimingVoice] = useState(false);
  const [claimingVoiceError, setClaimingVoiceError] = useState<string | null>(null);
  const [claimingVoiceSuccess, setClaimingVoiceSuccess] = useState(false);

  useEffect(() => {
    if (voiceHash) handleGetVoiceDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleGetVoiceDetails() {
    if (!voiceHash) return;
    setLoadingVoiceData(true);
    try {
      const res = await getSharedVoiceDetails(voiceHash);

      setVoiceDetails(res?.data?.data);
    } catch (err: unknown) {
      const message =
        err instanceof AxiosError && err.response?.data?.error
          ? err.response.data.error
          : 'Something went wrong while fetching voice details, please try again later.';
      setLoadingVoiceError(message);
    } finally {
      setLoadingVoiceData(false);
    }
  }

  async function handleClaimVoice() {
    if (!currentUser) return;
    if (!voiceHash) return;
    setClaimingVoice(true);
    try {
      const idToken = await currentUser?.getIdToken();
      await claimVoice({ token: idToken, hash: voiceHash });
      setClaimingVoiceSuccess(true);
    } catch (err: unknown) {
      const message =
        err instanceof AxiosError && err.response?.data?.error
          ? err.response.data.error
          : 'Something went wrong while claiming voice, please try again later.';
      setClaimingVoiceError(message);
    } finally {
      setClaimingVoice(false);
    }
  }

  return (
    <div className="max-w-4xl">
      <div className="p-8">
        <h2 className="text-lg font-bold">Claim a voice</h2>
      </div>
      <div className=" max-w-[500px] rounded-lg px-5 py-8">
        {isLoadingVoiceData ? (
          <div className="text-center">
            <Spinner label="Loading voice data..." />
          </div>
        ) : loadingVoiceError ? (
          <div className="text-red-500">{loadingVoiceError}</div>
        ) : voiceDetails ? (
          <div className="flex items-center justify-between">
            <div className="my-4 flex items-center gap-2">
              <VoiceCloningIcon />
              <span className="overflow-ellipsis whitespace-nowrap">
                {voiceDetails?.voiceName.slice(0, 20)} {voiceDetails?.voiceName.length > 20 && '...'}
              </span>
            </div>
            {claimingVoiceSuccess ? (
              <div className="text-sm font-normal text-green-500">Voice claimed successfully.</div>
            ) : isClaimingVoice ? (
              <div className="text-center">
                <Spinner label="Claiming voice..." />
              </div>
            ) : claimingVoiceError ? (
              <div className="ml-4 text-sm font-normal text-red-500">{claimingVoiceError}</div>
            ) : (
              <div>
                <OldButton onClick={handleClaimVoice} className="btn-cta--primary px-5 py-1 text-base font-semibold">
                  Claim Voice
                </OldButton>
              </div>
            )}
          </div>
        ) : (
          <div>No voice found</div>
        )}
      </div>
    </div>
  );
}
