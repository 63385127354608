import { FC } from 'react';
import { FilesDropzone } from '../../__playrepo/ui-components/src/FilesDropzone';
import {
  MAX_VOICE_SAMPLE_FILE_SIZE,
  MIN_VOICE_SAMPLE_FILE_SIZE,
  VOICE_SAMPLE_FILE_MIME_TYPES,
} from '../../__playrepo/play-voice-domain/src/Voice';

type InstantCloneMethodProps = {
  onFilesSelected: (files: File[]) => void;
  onRecord: () => void;
};

const InstantCloneMethod: FC<InstantCloneMethodProps> = ({ onFilesSelected, onRecord }) => {
  return (
    <div className="flex flex-col px-4 py-1 rounded-2xl border border-white/10">
      <FilesDropzone
        className="border-b border-b-gray-700"
        name="voiceSampleFile"
        accept={VOICE_SAMPLE_FILE_MIME_TYPES}
        maxFiles={1}
        multiple={false}
        minSize={MIN_VOICE_SAMPLE_FILE_SIZE}
        maxSize={MAX_VOICE_SAMPLE_FILE_SIZE}
        onFilesSelected={onFilesSelected}
        title="Upload High Quality Audio Sample"
      />
      <button className="flex flex-col py-3" onClick={onRecord}>
        <div className="flex items-center gap-2">
          <span className="h-3 w-3 rounded-full bg-red-500 m-[10px]"></span>
          <span className="text-[14px] leading-[21px] text-primary-green-500 font-medium">Record audio</span>
        </div>
      </button>
    </div>
  );
};

export default InstantCloneMethod;
