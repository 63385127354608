import { cva, type VariantProps } from 'class-variance-authority';
import { MouseEventHandler, ReactNode } from 'react';
import { GoogleIcon } from './Icons/GoogleIcon.tsx';

const buttonCva = cva('flex items-center justify-center gap-2 font-semibold transition-all', {
  variants: {
    format: {
      filled: '',
      outlined: 'ring-1 ring-inset bg-neutral/0 enabled:hover:bg-neutral/10',
      opaque: 'text-neutral-100 bg-neutral-100',
    },
    intent: {
      default: 'bg-neutral-800 enabled:hover:bg-neutral-700',
      primary: 'bg-primary enabled:hover:bg-primary-400',
      success: 'bg-success enabled:hover:bg-success-400 text-neutral-900',
      warning: 'bg-warning enabled:hover:bg-warning-400',
      error: 'bg-error enabled:hover:bg-error-400',
      google: 'bg-neutral-100 enabled:hover:bg-neutral-50 text-neutral-950',
    },
    size: {
      small: 'text-sm py-2 px-3 rounded-md',
      medium: 'text-base py-2.5 px-5 rounded-md',
      large: 'text-base py-3 px-5 rounded-lg',
    },
    width: {
      full: 'w-full',
      auto: 'w-auto',
    },
    disabled: {
      true: 'opacity-60 cursor-not-allowed',
      false: 'opacity-100',
    },
  },
  compoundVariants: [
    { format: 'outlined', intent: 'default', class: 'text-neutral-400 ring-neutral-400' },
    { format: 'outlined', intent: 'primary', class: 'text-primary ring-primary' },
    { format: 'outlined', intent: 'success', class: 'text-success ring-success' },
    { format: 'outlined', intent: 'warning', class: 'text-warning ring-warning' },
    { format: 'outlined', intent: 'error', class: 'text-error ring-error' },
  ],
  defaultVariants: {
    format: 'filled',
    intent: 'default',
    size: 'medium',
    width: 'auto',
    disabled: false,
  },
});

export interface ButtonProps extends VariantProps<typeof buttonCva> {
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

export function Button({
  type = 'button',
  onClick,
  disabled,
  loading,
  width,
  size,
  children,
  format,
  intent,
}: ButtonProps) {
  return (
    <button
      className={buttonCva({ format, intent, size, width, disabled: disabled || loading })}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {intent === 'google' && <GoogleIcon className="mr-1 h-5 w-auto" />}
      {children}
      {loading && renderLoading()}
    </button>
  );
}

export function renderLoading() {
  return (
    <div className="ml-1">
      <svg
        className="-ml-1 mr-3 h-5 w-5 animate-spin text-neutral-50"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );
}
