export function Divider({ text }: { text?: string }) {
  return (
    <div className="flex justify-items-stretch">
      {renderLine()}
      {text && (
        <div className="flex justify-center text-sm font-medium">
          <span className="px-2 text-neutral-900 dark:text-neutral-100">{text}</span>
        </div>
      )}
      {renderLine()}
    </div>
  );
}

function renderLine() {
  return (
    <div className="flex flex-grow items-center" aria-hidden="true">
      <div className="w-full border-t border-t-neutral-700 opacity-50" />
    </div>
  );
}
