import { Checkbox } from '../../__playrepo/ui-components/src/Checkbox.tsx';
import { Link } from 'react-router-dom';
import { HyperlinkText } from '../../__playrepo/ui-components/src/HyperlinkText.tsx';
import { ExpandableText } from '../../components/ExpandableText.tsx';

export function VoiceCloningConsentCheckbox({
  consent,
  setConsent,
}: {
  consent: boolean;
  setConsent: (value: boolean) => void;
}) {
  return (
    <Checkbox
      className="mb-8"
      name="consent"
      label={
        <span className="text-white text-[14px] leading-[20px] font-normal">
          <ExpandableText resumeText="I hereby confirm that I have all the necessary rights or consents to clone and use this voice... ">
            <>
              <p>
                I hereby confirm that I have all the necessary rights or consents to clone and use this voice without
                violating any copyrights and rights of publicity.
              </p>{' '}
              <p>
                I also confirm that I have the rights to the audio files used in cloning this voice, and I will not use
                this or any other voice on{' '}
                <a className="" href="https://play.ht/privacy/">
                  PlayHT
                </a>{' '}
                to generate any sexual, hateful, abusive, violent, illegal, fraudulent, or deceitful content whatsoever.
              </p>{' '}
              <p>
                I fully understand and agree to PlayHT’s
                <Link to="https://play.ht/terms/">
                  {' '}
                  <HyperlinkText>Terms of Use</HyperlinkText>
                </Link>{' '}
                and
                <Link to="https://play.ht/privacy/">
                  {' '}
                  <HyperlinkText>Privacy Policy</HyperlinkText>
                </Link>
                .
              </p>
            </>
          </ExpandableText>
        </span>
      }
      checked={consent}
      onChange={setConsent}
    />
  );
}
