import { type ButtonHTMLAttributes, forwardRef } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

export const buttonCva = cva(
  [
    'flex',
    'justify-center',
    'items-center',
    'gap-2',
    'p-4',
    'font-medium',
    'shadow-sm',
    'focus-visible:outline',
    'focus-visible:outline-2',
    'focus-visible:outline-offset-2',
    'disabled:cursor-not-allowed',
    'transition-all duration-500',
  ],
  {
    variants: {
      intent: {
        'opaque-green': [
          'bg-primary-green-600',
          'bg-opacity-10',
          'text-primary-green-600',
          'ring-primary-green-600/20',
          'enabled:hover:bg-opacity-20',
          'disabled:text-gray-600',
        ],
        'opaque-green-plus': [
          'bg-primary-green-500',
          'bg-opacity-5',
          'text-primary-green-600',
          'ring-primary-green-500/20',
          'enabled:hover:bg-opacity-10',
          'disabled:text-gray-600',
        ],
        'opaque-blue-plus': [
          'bg-primary-blue-400',
          'bg-opacity-5',
          'text-primary-blue-400',
          'ring-primary-blue-400/30',
          'enabled:hover:bg-opacity-10',
          'disabled:text-gray-600',
        ],
        'opaque-neutral': ['bg-gray-800', 'text-white', 'enabled:hover:bg-gray-700/50', 'disabled:text-gray-600'],
        neutral: [
          'bg-gray-800',
          'text-primary-green-500',
          'ring-white',
          'ring-1',
          'ring-opacity-20',
          'enabled:hover:bg-opacity-90',
          'disabled:text-gray-600',
          '!rounded-2xl',
        ],
        cta: [
          'bg-primary-green-600',
          'text-dark-band-green-700',
          'ring-black',
          'enabled:hover:bg-opacity-90',
          'disabled:opacity-50',
        ],
        stop: ['bg-red-500', 'text-white', 'ring-white', 'enabled:hover:bg-opacity-90', 'disabled:opacity-50'],
      },
      size: {
        small: ['text-sm', 'h-[2.125rem]'],
        base: ['text-[14px] leading-none', 'h-[3rem]'],
        large: ['text-lg', 'h-[3.25rem]'],
      },
      rounded: {
        true: ['!rounded-full'],
        false: ['rounded-2xl'],
      },
      bordered: {
        true: ['!ring-1'],
        false: [],
      },
    },
    defaultVariants: {
      intent: 'opaque-green',
      size: 'base',
      rounded: false,
      bordered: false,
    },
  }
);

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonCva> {
  loading?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, intent, size, rounded, bordered, disabled, loading, children, ...props }, ref) => (
    <button
      ref={ref}
      className={buttonCva({ intent, size, rounded, bordered, className })}
      disabled={disabled || loading}
      {...props}
    >
      {/* To avoid button growing when button change to loading; Width should be sync with button gap and spinner width */}
      {loading === false && <div className="w-1.5" />}
      {children}
      {loading === false && <div className="w-1.5" />}
      {loading && renderLoading()}
    </button>
  )
);

export function renderLoading() {
  return (
    <div>
      <svg className="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );
}
