import { useState } from 'react';
import { useAuth } from '../../contexts/auth.context';
import { DropzoneFile } from '../../__playrepo/ui-components/src/FilesDropzone';
import { useMutation } from '@tanstack/react-query';
import { getPresignedURL } from '../../API/voiceCloning.requests';
import { s3Utils } from '../../__playrepo/play-utils/src/s3/s3Utils';

export function useUploadFile({
  onSuccess,
  onError,
}: {
  onSuccess: (data: { url: string; fileName: string }) => void;
  onError: (e: unknown) => void;
}) {
  const { currentUser: user } = useAuth();

  const [uploadedSampleFile, setUploadedSampleFile] = useState<DropzoneFile | null>(null);
  const deleteFile = () => setUploadedSampleFile(null);
  const updateUploadedSampleFile = (newFile: Partial<DropzoneFile>) =>
    setUploadedSampleFile((prev) => (!prev ? prev : { ...prev, ...newFile }));

  const { mutate: uploadFile } = useMutation({
    mutationFn: async (file: File) => {
      if (!user) return;
      const token = await user.getIdToken();
      const fileName = file.name;
      const fileExt = fileName.split('.').pop();
      if (!fileExt) throw new Error("File doesn't have an extension");
      setUploadedSampleFile({ file, progress: 0 });
      const fileInfo = { ext: fileExt, contentType: file.type };
      try {
        const { preSignedUrl, url } = await getPresignedURL({ token, fileInfo });
        updateUploadedSampleFile({ url });
        await s3Utils.uploadToS3WithPresignedUrl(preSignedUrl, file, (p) => updateUploadedSampleFile({ progress: p }));
        return { url, fileName };
      } catch (e) {
        throw new Error('Failed to upload file.');
      }
    },
    onSuccess: (data) => data && onSuccess(data),
    onError,
  });

  return { uploadFile, uploadedSampleFile, deleteFile };
}
