import React from 'react';
import { Button } from '../../__playrepo/ui-components/src/Button';

interface StartRecordingProps {
  onStartRecording: () => void;
  isRecording: boolean;
}

const StartRecording: React.FC<StartRecordingProps> = ({ isRecording, onStartRecording }) => {
  return (
    <div className="flex flex-1 flex-col mt-[30px]">
      <div className="mx-auto w-full">
        <p className="text-center font-sfpro text-[16px] leading-[19px] text-white">Speak naturally for 30 seconds.</p>
      </div>
      <Button intent="cta" className="w-full mt-auto" onClick={onStartRecording} disabled={isRecording}>
        Start
      </Button>
    </div>
  );
};

export default StartRecording;
