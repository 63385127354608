import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import FeedbackModal from '../pages/KettlePage/components/FeedbackModal';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFirebaseListener } from '../hooks/firebase.hooks';
import { CONFIG } from '../config';

type EditorConfig = {
  editorPreset: string;
};

export const ShareFeedback = () => {
  const { id: projectId } = useParams();
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [editorConfig] = useFirebaseListener<EditorConfig | null>(`audioFrames/${projectId}/editorConfig`, {
    databaseURL: CONFIG.firebase.databaseURLForAudioFrames,
  });

  const preset = editorConfig?.editorPreset || 'not set';

  return projectId ? (
    <>
      <FeedbackModal
        open={feedbackModalOpen}
        setOpen={setFeedbackModalOpen}
        projectId={projectId}
        editorPreset={preset}
      />
      <button
        type="button"
        onClick={() => setFeedbackModalOpen(true)}
        className="flex items-center w-full bg-gray-900 border-gray-800 p-3 rounded-lg justify-between mb-2 hover:opacity-90 duration-200 ease-out"
      >
        <span className="leading-[20px] text-xs text-white">Share Feedback</span>
        <ArrowTopRightOnSquareIcon className="w-5 h-5 text-white" />
      </button>
    </>
  ) : null;
};
