import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { CONFIG } from '../config.ts';

const app = initializeApp(CONFIG.firebase);

export const auth = getAuth(app);
export const database = getDatabase(app);

export const firestoreDB = getFirestore(app);
