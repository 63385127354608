import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon, LanguageIcon } from '@heroicons/react/24/solid';
import { clsx } from 'clsx';
import { CheckIcon } from '@heroicons/react/24/solid';
import { capitalizeWord } from '../utils/capitalizeWord';
import { Language, languages } from './CreateVoiceCloneWizard/useCreateVoiceCloneWizard';

export function LanguageSelect({
  language,
  setLanguage,
  className,
}: {
  language: Language;
  setLanguage: (value: Language) => void;
  className?: string;
}) {
  return (
    <div className={clsx('flex w-full flex-col', className)}>
      <Listbox value={language} onChange={setLanguage}>
        <div className="relative z-10 font-sfpro">
          <Listbox.Button className="relative flex h-12 w-full cursor-default items-center justify-between gap-[9px] rounded-2xl bg-gray-800 py-3 px-4 text-left duration-200 ease-out hover:bg-[#0E140A] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
            <div className="flex items-center">
              <LanguageIcon className="text-primary-green-500 w-6 h-6 mr-4" />
              <span className="truncate text-[14px] leading-[21px] font-medium text-primary-green-500">
                {capitalizeWord(language)}
              </span>
            </div>
            <ChevronDownIcon className="pointer-events-none h-4 w-4 text-gray-500" aria-hidden="true" />
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute mt-1 w-full overflow-auto rounded-2xl border-1 border-primary-green-700 bg-gray-800 p-0 focus:outline-none">
              {languages.map((lang) => (
                <Listbox.Option
                  key={lang}
                  className="relative cursor-default select-none rounded-2xl py-3 px-4 duration-200 ease-out hover:bg-[#0E140A]"
                  value={lang}
                >
                  {({ selected }) => (
                    <div className="flex items-center justify-between">
                      <div className="mr-4 flex flex-col">
                        <span
                          className={clsx(
                            'truncate text-[14px] leading-[21px] font-medium',
                            selected ? 'text-primary-green-500' : 'text-white'
                          )}
                        >
                          {capitalizeWord(lang)}
                        </span>
                      </div>
                      {selected && <CheckIcon className="h-4 w-4 text-[#CCF0B499]" aria-hidden="true" />}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
