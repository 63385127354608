import { PaymentView, TPlan } from '../../components/PaymentView/PaymentView';
import { formatter } from '../../utils/formatCurrency';

function UpgradeCard({
  plan,
  isMonthly,
  setIsSuccessfulPurchase,
  quantity = 1,
}: {
  plan: TPlan;
  isMonthly: boolean;
  setIsSuccessfulPurchase: (isSuccess: boolean) => void;
  quantity?: number;
}) {
  return (
    <div className="max-w-500 mx-auto p-4">
      <h2 className="text-center text-xl font-semibold">Payment</h2>
      <div className="mt-4 grid grid-cols-2 items-start gap-4 py-6 text-sm font-normal">
        <span className="text-base font-normal">Voices Count</span>
        <p className="text-base font-semibold">{quantity}</p>
        <p className="mb-0 flex flex-col text-base">Total (paid {isMonthly ? 'monthly' : 'yearly'})</p>

        <div>
          <p className="mb-0 flex flex-col text-lg font-semibold">
            {formatter.format(plan.price * quantity)}{' '}
            <span className="text-sm font-normal">per {isMonthly ? 'month' : 'year'}</span>
          </p>
          {!isMonthly && <span className="text-sm font-medium text-red-700">You save 40%</span>}
        </div>
      </div>

      {/* payment view */}
      <div className="mt-4">
        <PaymentView plan={plan} onSuccess={() => setIsSuccessfulPurchase(true)} />
      </div>
    </div>
  );
}

export default UpgradeCard;
