import V3Tour1 from '../../assets/v3-tour/V3tour-multilingual.png';
import V3Tour2 from '../../assets/v3-tour/V3tour-KHZ.png';
import V3Tour3 from '../../assets/v3-tour/V3tour-Stability.png';
import { FeatureFlags } from '../../hooks/useFeatureFlags.ts';

export type AnnouncementId = 'V3-TOUR-1' | 'V3-TOUR-2' | 'V3-TOUR-3';

export type Announcement = {
  id: AnnouncementId;
  featureFlag: keyof FeatureFlags | null;
  publishedAt: string;
  image: string;
  desc: string;
  title: string;
};

export const ANNOUNCEMENTS = [
  {
    id: 'V3-TOUR-1',
    featureFlag: 'ENABLE_V3_VOICE_MODEL',
    publishedAt: '2024-09-01',
    image: V3Tour1,
    title: 'Multilingual Support',
    desc: 'This model now supports 36 languages, making it easier than ever to create content in your preferred language. Simply select your desired language, and our AI will generate natural and fluent delivery.',
  },
  {
    id: 'V3-TOUR-1',
    featureFlag: 'ENABLE_V3_VOICE_MODEL',
    publishedAt: '2024-09-01',
    image: V3Tour2,
    title: 'Increased Performance and Sound Quality ',
    desc: "Enjoy richer, clearer, faster audio generations. We've cut latency by 2x, and doubled the sound resolution from 24kHz to 48kHz. Play 3.0 introduces crystal-clear audio and enhanced workflow performance.",
  },
  {
    id: 'V3-TOUR-1',
    featureFlag: 'ENABLE_V3_VOICE_MODEL',
    publishedAt: '2024-09-01',
    image: V3Tour3,
    title: 'Enhanced Output Stability',
    desc: 'Experience 4x more stability with our latest model, ensuring consistent and reproducible generations. You can now rely on more accurate and dependable outputs, streamlining your creative process.',
  },
] as const satisfies ReadonlyArray<Announcement>;
