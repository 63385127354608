import { KettleEditorPage } from './KettleEditorPage.jsx';
import { useParams } from 'react-router-dom';
import './KettlePage.css';
import { useAuth } from '../../contexts/auth.context.tsx';
import { ErrorPage } from '../Error/ErrorPage.tsx';
import { SentryFatalErrorBoundary } from '../../infra/errors/SentryErrorBoundary.tsx';

export function KettlePage() {
  const { currentUser } = useAuth();
  const { id: projectId } = useParams();
  if (!currentUser) return null;
  return (
    <div className="kettle h-full tablet:pb-72 tablet:pt-16 desktop:pt-0">
      <SentryFatalErrorBoundary fallback={ErrorPage}>
        <KettleEditorPage key={projectId} />
      </SentryFatalErrorBoundary>
    </div>
  );
}
