// TODO: remove mocks
import { Link } from 'react-router-dom';

import { useInvoices } from '../../hooks/billing.hooks.ts';
import { formatCurrency } from '../../utils/formatCurrency.ts';
import { capitalizeFirstChar } from '../../utils/stringUtils.ts';
import { convertUTCDatetimeToLocaleDate } from '../../utils/dateUtils.ts';
import { Spinner } from '../../components/Spinner.tsx';
import { clsx } from 'clsx';

export function BillingInvoicesHistorySection() {
  const invoices = useInvoices();
  if (!invoices) return <Spinner className="pt-4" label="Loading invoices history..." />;
  return (
    <table className="w-full overflow-hidden whitespace-nowrap text-left">
      <colgroup></colgroup>
      <thead className="border-b border-neutral-50/10 text-sm font-semibold leading-6">
        <tr>
          <th scope="col" className="py-4 pr-6">
            Plan
          </th>
          <th scope="col" className="hidden py-4 pr-6 tablet:table-cell">
            Amount
          </th>
          <th scope="col" className="hidden py-4 pr-6 tablet:table-cell">
            Date
          </th>
          <th scope="col" className="py-4 pr-6">
            Status
          </th>
          <th scope="col" className="py-4 pr-6">
            Receipt
          </th>
        </tr>
      </thead>
      <tbody>
        {invoices?.map((f, idx) => {
          const paddingClassName = clsx('py-3 pr-6', { 'pt-7': idx === 0 });
          return (
            <tr key={f.id}>
              <td className={clsx('py-3 pr-6', { 'pt-7': idx === 0 })}>
                <div className="fs-center flex gap-x-4">
                  <div className="truncate text-sm leading-6">
                    {f.planName}
                    <dl className="font-normal tablet:hidden">
                      <dd className="mt-1 truncate text-neutral-400 tablet:hidden">{formatCurrency(f.amount)}</dd>
                      <dd className="mt-1 truncate text-neutral-400 tablet:hidden">{f.date}</dd>
                    </dl>
                  </div>
                </div>
              </td>
              <td className={clsx(paddingClassName, 'hidden text-sm leading-6 tablet:table-cell')}>
                <span>{formatCurrency(f.amount)}</span>
              </td>
              <td className={clsx('hidden text-sm leading-6 tablet:table-cell', paddingClassName)}>
                <span>{convertUTCDatetimeToLocaleDate(f.date)}</span>
              </td>
              <td className={clsx('text-sm leading-6', paddingClassName)}>
                <span>{capitalizeFirstChar(f.status)}</span>
              </td>
              <td className={clsx('text-sm leading-6', paddingClassName)}>
                <Link to={f.invoiceUrl} className="underline underline-offset-4 dark:text-neutral-100">
                  Download
                </Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
