import { ChangeEvent, ReactNode } from 'react';

export function Checkbox({
  name,
  value,
  checked,
  disabled,
  label,
  onChange,
}: {
  name: string;
  value: string | number;
  checked: boolean;
  disabled?: boolean;
  onChange: (elem: ChangeEvent<HTMLInputElement>) => void;
  label?: ReactNode;
}) {
  return (
    <div className="relative inline-flex items-start gap-1">
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        className="peer relative h-4 w-4 cursor-pointer appearance-none rounded-[4px] border border-gray-300 bg-transparent transition-all before:absolute before:left-2/4 before:top-2/4 before:block before:h-12 before:w-12 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full !outline-black before:bg-gray-500 before:opacity-0 text-black checked:text-primary-green-600 before:transition-opacity checked:border-primary-green-600 checked:bg-primary-green-600 checked:before:bg-primary-green-600 hover:before:opacity-10 disabled:cursor-not-allowed disabled:border-gray-500 disabled:bg-gray-700/70"
      />
      {label && <label className="text-white text-[14px] leading-[20px] font-normal">{label}</label>}
    </div>
  );
}
