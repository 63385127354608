import axios from 'axios';
import { CONFIG } from '../../../config';

export const getUserPlanSummaryAPI = async (token, projectId) =>
  axios.get(`${CONFIG.playStudioAPIBaseUrl}/kettle/${projectId}/summary`, {
    headers: {
      Authorization: token,
    },
  });
export const refreshProjectStateAPI = async (token, projectId) =>
  axios
    .post(`${CONFIG.playStudioAPIBaseUrl}/kettle/${projectId}/refresh`, {
      token,
    })
    .catch(console.error); // let's ignore errors, the backend already reports them and the UI doesn't use this response
export const updateEditorStateAPI = async (token, projectId, serializedEditorState) =>
  axios.put(`${CONFIG.playStudioAPIBaseUrl}/kettle/${projectId}/editor-state`, {
    token,
    serializedEditorState,
  });
export const updateEditorTitleAPI = async (token, projectId, newTitle, isAutogenerated) => {
  await axios.put(`${CONFIG.playAPIBaseUrl}/articleInfo/title`, {
    token,
    title: newTitle,
    isAutogenerated,
    articleId: projectId,
  });
  await axios.put(`${CONFIG.playStudioAPIBaseUrl}/kettle/recent-files/${projectId}/title`, {
    token,
    title: newTitle,
  });
};
export const updateEditorPresetAPI = async (token, projectId, newPreset) =>
  axios.put(`${CONFIG.playStudioAPIBaseUrl}/kettle/${projectId}/editor-config/editor-preset`, {
    token,
    newPreset,
  });
export const updateSelectedVideoAPI = async (token, projectId, selectedVideo) =>
  axios.put(`${CONFIG.playStudioAPIBaseUrl}/kettle/${projectId}/editor-config/selected-video`, {
    token,
    selectedVideo,
  });
export const createNewEmptyFramesAPI = async (token, projectId, frameIdsAndOptions) =>
  axios.post(`${CONFIG.playStudioAPIBaseUrl}/kettle/${projectId}/frames`, {
    token,
    frames: frameIdsAndOptions,
  });
export const generatePreviewsForFrameAPI = async (
  isStreamingRequest,
  userId,
  token,
  projectId,
  frameId,
  options,
  preset,
  text,
  textContentHash
) => {
  if (isStreamingRequest) {
    const streamingOptions = {
      voice: options.style ?? options.voice,
      speed: options.speed,
      text,
      ...options,
    };

    return axios
      .post(
        `${CONFIG.roostBffAPIBaseUrl}/tts/request-streaming-generation`,
        {
          kettleFileId: projectId,
          frameId,
          options: streamingOptions,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .catch((e) => Promise.reject({ e, message: e.message, status: e.response?.status, ...e.response?.data }));
  }
  return axios
    .post(`${CONFIG.playStudioAPIBaseUrl}/process-audio`, {
      userId,
      size: 1,
      voice: options.style ?? options.voice,
      preset,
      speed: options.speed,
      content: text,
      hash: frameId,
      projectId,
      token,
      textContentHash,
    })
    .catch((e) => Promise.reject({ e, message: e.message, status: e.response?.status, ...e.response?.data }));
};
export const updateSelectedAudioSampleAPI = async (token, projectId, frameId, selectedAudioURL) =>
  axios.put(`${CONFIG.playStudioAPIBaseUrl}/kettle/${projectId}/frames/${frameId}/selected-sample`, {
    token,
    selectedAudioURL,
  });
export const updateAudioSampleTitleAPI = async (token, projectId, frameId, audioURL, newTitle) =>
  axios.put(`${CONFIG.playStudioAPIBaseUrl}/kettle/${projectId}/frames/${frameId}/samples/title`, {
    token,
    audioURL,
    newTitle,
  });
export const removeAudioSampleAPI = async (token, projectId, frameId, audioURL) =>
  axios.post(`${CONFIG.playStudioAPIBaseUrl}/kettle/${projectId}/frames/${frameId}/samples/delete`, {
    token,
    audioURL,
  });
export const exportAudioAPI = async (token, projectId, audioURLs) => {
  return fetch(`${CONFIG.playStudioAPIBaseUrl}/export-audio`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token, projectId, audioURLs }),
  });
};
export const exportTimelineMediaAPI = async (token, projectId, fileName, videoFile, audioFiles, avatar) => {
  return axios
    .post(`${CONFIG.playStudioAPIBaseUrl}/kettle/${projectId}/mixer`, {
      token,
      projectId,
      fileName,
      videoFile,
      audioFiles,
      avatar,
    })
    .then((r) => r.data?.requestId);
};
export const convertEditorToKettleAPI = async (token, projectId) =>
  axios.put(`${CONFIG.playStudioAPIBaseUrl}/kettle/${projectId}/migrate?migrateToNewDB=true`, {
    token,
    projectId,
  });

export const updateFrameOptionsAPI = async (
  token,
  projectId,
  frameId,
  newOptions,
  changeOptionsType,
  previousOptions
) =>
  axios.post(`${CONFIG.playStudioAPIBaseUrl}/kettle/${projectId}/frames/${frameId}/options`, {
    token,
    newOptions,
    changeOptionsType,
    previousOptions,
  });

export const updateFramesDelaysAPI = async (token, projectId, frameIdsWithNewDelays) => {
  return axios.put(`${CONFIG.playStudioAPIBaseUrl}/kettle/${projectId}/frames/delays`, {
    token,
    frameIdsWithNewDelays,
  });
};

export const getUploadUrlAPI = async (token, fileName, fileType) =>
  axios.post(`${CONFIG.playStudioAPIBaseUrl}/video/upload-url`, {
    token,
    fileName,
    type: fileType,
  });

export const createTranscriptionRequestAPI = async (token, url) =>
  axios.post(`${CONFIG.playStudioAPIBaseUrl}/video/transcription`, {
    token,
    url,
  });

export const uploadMediaAPI = async (token, file, onProgressFn) => {
  const {
    data: { data },
  } = await getUploadUrlAPI(token, file.name, file.type);
  const { fileUrl, preSignedUrl } = data;

  await axios.request({
    method: 'put',
    url: preSignedUrl,
    data: file,
    onUploadProgress: (p) => onProgressFn((p.loaded / p.total) * 100),
    headers: {
      'Content-Type': file.type,
    },
  });

  return fileUrl;
};

export const updateUploadedMediaAPI = async (token, media) =>
  axios.put(`${CONFIG.playStudioAPIBaseUrl}/video/update`, {
    video: {
      ...media,
    },
    token,
  });

export const createAvatarRequestAPI = async (token, projectId, urls = {}, opts = {}) => {
  const {
    data: { data: id },
  } = await axios.post(`${CONFIG.playStudioAPIBaseUrl}/video/avatar`, {
    projectId,
    token,
    audioUrl: urls.audio,
    videoUrl: urls.video,
    options: { ...opts },
  });

  return id;
};

export const removeAvatarAPI = async (token, projectId) =>
  axios.delete(`${CONFIG.playStudioAPIBaseUrl}/${projectId}/avatar`, {
    headers: {
      authorization: token,
    },
  });

export const getAvatarProgressAPI = async (token, projectId, jobId) => {
  const { data } = await axios.get(`${CONFIG.playStudioAPIBaseUrl}/${projectId}/avatar/progress/${jobId}`, {
    headers: {
      authorization: token,
    },
  });

  return data;
};
