export function formatUnixTimestampToLocaleDate(timestamp: number) {
  return new Date(timestamp).toLocaleDateString();
}

export function formatUnixMillisecondsToDifferenceFromNow(timestamp: number) {
  const now = Math.floor(Date.now() / 1000); // Get current Unix timestamp in seconds
  const difference = now - timestamp / 1000; // Calculate the difference
  const absDifference = Math.abs(difference);
  const timeUnits = [
    ['year', 60 * 60 * 24 * 365],
    ['month', 60 * 60 * 24 * 30],
    ['day', 60 * 60 * 24],
    ['hour', 60 * 60],
    ['minute', 60],
  ] as const;
  if (absDifference < 60) {
    return `few seconds ${difference > 0 ? 'ago' : 'left'}`;
  }
  for (const [name, seconds] of timeUnits) {
    if (absDifference >= seconds) {
      const count = Math.floor(absDifference / seconds);
      return `${count} ${name}${count !== 1 ? 's' : ''} ${difference > 0 ? 'ago' : 'left'}`;
    }
  }
}

export function convertUTCDatetimeToLocaleDate(date: string) {
  return new Date(date).toLocaleDateString();
}
