import React, { useCallback, useEffect, useRef, useState } from 'react';
import KettleEditor, { UserNotificationType, UserResponse } from '@playht/component-kettle-editor';
import '@playht/component-kettle-editor/index.css';
import { useParams, useSearchParams } from 'react-router-dom';
import debounce from 'lodash.debounce';

import {
  convertEditorToKettleAPI,
  createAvatarRequestAPI,
  createNewEmptyFramesAPI,
  createTranscriptionRequestAPI,
  exportAudioAPI,
  exportTimelineMediaAPI,
  generatePreviewsForFrameAPI,
  getAvatarProgressAPI,
  getUserPlanSummaryAPI,
  refreshProjectStateAPI,
  removeAudioSampleAPI,
  removeAvatarAPI,
  updateAudioSampleTitleAPI,
  updateEditorPresetAPI,
  updateEditorStateAPI,
  updateEditorTitleAPI,
  updateFrameOptionsAPI,
  updateFramesDelaysAPI,
  updateSelectedAudioSampleAPI,
  updateSelectedVideoAPI,
  updateUploadedMediaAPI,
  uploadMediaAPI,
} from './API/kettle.requests.js';
import Loader from './components/Loader.jsx';
import { usePreventUnload } from './hooks/usePreventUnload';
import { KETTLE_LATEST_VERSION } from './constants/kettle.constants.js';
import useVoices from './hooks/useVoices.ts';
import { useFirebaseIsConnectedListener } from './hooks/useFirebaseIsConnectedListener';
import { useAvailableVoicePresets } from './hooks/useAvailableVoicePresets';
import { useFirebaseUploadedMedia } from './hooks/useFirebaseUploadedMedia';
import { useAuth } from '../../contexts/auth.context';
import { useUser } from '../../contexts/user.context.tsx';
import { LegacyInstantVoiceCloningModal } from '../VoiceCloning/LegacyInstantVoiceCloningModal.tsx';
import { useCreateAndOpenFile } from '../../hooks/files.hooks';
import { AddNewStyleModal } from '../VoiceCloning/AddNewStyleModal';
import { updateClonedVoiceAPI } from '../../API/voiceCloning.requests.ts';
import FeedbackModal from './components/FeedbackModal.jsx';
import { getActivePackage } from '../../utils/plans.helpers';
import { errorsTracker } from '../../infra/errors/errorsTracker';
import { eventsTracker } from '../../infra/analytics/eventsTracker';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { getCreditTypeForUser } from '../../domain/credit/CreditType';
import { useFirebaseListener } from '../../hooks/firebase.hooks.ts';
import { CONFIG } from '../../config.ts';
import { DEFAULT_FILE_MODEL } from '../../domain/files/File.ts';
import { useFirestoreListener } from '../../hooks/useFirestoreListener.hook.ts';

async function notifyUser(type, message, retryFn) {
  const displayMessage = `${type.toUpperCase()}\n\n${message}`;
  if (retryFn) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`${displayMessage}\n\nClick OK to retry, Cancel to dismiss.`)) {
      setTimeout(retryFn);
      return UserResponse.RETRIED;
    }
  } else {
    alert(displayMessage);
    console.log(displayMessage);
  }
  return UserResponse.DISMISSED;
}

const reportKettleError = (projectId, message, error) => reportKettleIssue(projectId, message, error, 'error');

const reportKettleInfo = (projectId, message, error) => reportKettleIssue(projectId, message, error, 'info');

const reportKettleIssue = (projectId, message, error, level) =>
  errorsTracker.report(level, error, `Kettle -- ${message}`, { extra: { 'project-id': projectId } });

function generateUserData(user, userInfo) {
  return {
    userId: user.uid || userInfo?.id || 'unknown',
    is_pro: userInfo?.is_pro ?? false,
    plan: getActivePackage(userInfo)?.id ?? 'free',
    date: new Date().toJSON(),
    userEmail: user?.email || userInfo?.email || 'unknown',
  };
}

export function KettleEditorPage() {
  let [searchParams] = useSearchParams();
  const { id: projectId } = useParams();
  const disableAnimation = searchParams.get('animation') === 'false';
  const enableDebugger = searchParams.get('debug') === 'true';

  const { currentUser: user } = useAuth();
  const { user: userInfo } = useUser();

  const { createAndOpenFile } = useCreateAndOpenFile();
  const [voiceCloningOpen, setVoiceCloningOpen] = useState(false);
  const [addNewStyleOpen, setAddNewStyleOpen] = useState({ parentVoiceId: null, open: false });
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

  const availableVoicePresets = useAvailableVoicePresets();
  const { featureFlagsLoading, featureFlags } = useFeatureFlags();

  // useInjectClasses(['dark:kt-bg-slate-800', 'dark:kt-text-neutral-50']);

  const [editorMetadata, editorMetadataLoadStatus] = useFirestoreListener(`studio-files-metadata/${projectId}`);

  const [editorConfig, editorConfigLoadStatus] = useFirebaseListener(`audioFrames/${projectId}/editorConfig`, {
    databaseURL: CONFIG.firebase.databaseURLForAudioFrames,
  });
  const [exportedMedia, exportedMediaLoadStatus] = useFirebaseListener(`audioFrames/${projectId}/exportedMedia`, {
    databaseURL: CONFIG.firebase.databaseURLForAudioFrames,
  });
  const [avatars, avatarsLoadStatus] = useFirebaseListener(`avatars/${projectId}`);

  const [uploadedMedia] = useFirebaseUploadedMedia();

  const [frames, framesLoadStatus] = useFirebaseListener(`audioFrames/${projectId}/frames`, {
    transform: (firebaseFrames) =>
      (firebaseFrames ?? []).map((fr) => {
        return { ...fr, samples: uniqueAudioSamples(fr.samples ?? []) };
      }),
    initialState: [],
    databaseURL: CONFIG.firebase.databaseURLForAudioFrames,
  });

  const [serializedEditorState, serializedEditorStateLoadStatus] = useFirebaseListener(
    `audioFrames/${projectId}/serializedEditorState`,
    {
      transform: (firebaseSerializedEditorState) =>
        firebaseSerializedEditorState ? JSON.stringify(firebaseSerializedEditorState) : undefined,
      databaseURL: CONFIG.firebase.databaseURLForAudioFrames,
    }
  );

  const { planSummaryLoadingState, planSummaryData } = usePlanSummary(user, projectId);

  const [pendingPersistEditorState, setPendingPersistEditorState] = useState(false);
  const hasRefeshedProjectState = useRef(false);
  const persistEditorStateChange = useCallback(
    debounce(async (editorState) => {
      const persistEditorChanges = async () =>
        updateEditorStateAPI(await user.getIdToken(), projectId, editorState.toJSON())
          .catch((e) => {
            if (planSummaryData.at === 758983) return;
            notifyUser(
              UserNotificationType.ERROR,
              'Error while saving your editor changes.\n' +
                'Please check your internet connection before proceeding.\n' +
                'If the error persists, try refreshing the page.',
              () => persistEditorChanges()
            );
            reportKettleInfo(projectId, 'Error while saving editor changes: ' + e.message, e);
          })
          .then(() => setPendingPersistEditorState(false));
      // noinspection ES6MissingAwait
      persistEditorChanges();
    }, 10000),
    [user, projectId, planSummaryData]
  );
  const handleEditorStateChange = (editorState) => {
    setPendingPersistEditorState(true);
    persistEditorStateChange(editorState);
  };

  useEffect(() => {
    const refreshProjectState = async () => {
      hasRefeshedProjectState.current = true;
      try {
        const token = await user.getIdToken();
        await refreshProjectStateAPI(token, projectId);
      } catch (e) {
        reportKettleError(projectId, 'Error while refreshing project state: ' + e.message, e);
      }
    };

    if (!hasRefeshedProjectState.current) {
      refreshProjectState();
    }
  }, [user, projectId]);

  useEffect(() => {
    return () => persistEditorStateChange.flush();
  }, [persistEditorStateChange]);

  const { data: availableVoices, refreshVoices } = useVoices();
  const defaultVoice = searchParams.get('voice');

  usePreventUnload(() => pendingPersistEditorState);

  const reportAnalyticsEvent = (event) => eventsTracker.sendMixpanelEvent(event.type, generateUserData(user, userInfo));

  const [userIsConnected, userIsConnectedLoadStatus] = useFirebaseIsConnectedListener();

  const updateEditorTitle = useCallback(
    async (newTitle, isAutogenerated) => {
      return updateEditorTitleAPI(await user.getIdToken(), projectId, newTitle, isAutogenerated);
    },
    [user, projectId]
  );

  const updateEditorPreset = useCallback(
    async ({ newPreset }) => {
      return updateEditorPresetAPI(await user.getIdToken(), projectId, newPreset);
    },
    [user, projectId]
  );

  const updateSelectedVideo = useCallback(
    async (selectedVideo) => {
      return updateSelectedVideoAPI(await user.getIdToken(), projectId, selectedVideo);
    },
    [user, projectId]
  );

  const createNewEmptyFrames = useCallback(
    async (frameIdsAndOptions) => {
      return createNewEmptyFramesAPI(await user.getIdToken(), projectId, frameIdsAndOptions);
    },
    [user, projectId]
  );

  const generatePreviewsForFrame = useCallback(
    async ({ frameId, options, preset, text, textContentHash }) => {
      return generatePreviewsForFrameAPI(
        featureFlags.ENABLE_STREAMING &&
          (editorMetadata?.model === 'PlayHT2.0' ||
            editorMetadata?.model === 'PlayHT2.0-gargamel' ||
            editorMetadata?.model === 'Play3.0' ||
            editorMetadata?.model === 'PlayDialog'),
        user.uid,
        await user.getIdToken(),
        projectId,
        frameId,
        options,
        preset,
        text,
        textContentHash
      );
    },
    [featureFlags.ENABLE_STREAMING, editorMetadata, user, projectId]
  );

  const updateSelectedAudioSample = useCallback(
    async ({ frameId, audioURL }) => {
      return updateSelectedAudioSampleAPI(await user.getIdToken(), projectId, frameId, audioURL);
    },
    [user, projectId]
  );

  const updateAudioSampleTitle = useCallback(
    async ({ frameId, audioURL, newTitle }) => {
      return updateAudioSampleTitleAPI(await user.getIdToken(), projectId, frameId, audioURL, newTitle);
    },
    [user, projectId]
  );

  const removeAudioSample = useCallback(
    async ({ frameId, audioURL }) => {
      return removeAudioSampleAPI(await user.getIdToken(), projectId, frameId, audioURL);
    },
    [user, projectId]
  );

  const exportTimelineMedia = useCallback(
    async (fileName, videoURL, audioURLsWithOffsets, avatarUrl) => {
      if (featureFlags.ENABLE_TIMELINE) {
        return exportTimelineMediaAPI(
          await user.getIdToken(),
          projectId,
          fileName,
          videoURL,
          audioURLsWithOffsets,
          avatarUrl
        );
      } else {
        return exportAudioAPI(
          await user.getIdToken(),
          projectId,
          audioURLsWithOffsets.map((awo) => awo.url)
        );
      }
    },
    [user, projectId, featureFlags.ENABLE_TIMELINE]
  );

  const updateFrameOptions = useCallback(
    async (frameId, newOptions, changeOptionsType, previousOptions) => {
      return updateFrameOptionsAPI(
        await user.getIdToken(),
        projectId,
        frameId,
        newOptions,
        changeOptionsType,
        previousOptions
      );
    },
    [user, projectId]
  );

  const updateFramesDelays = useCallback(
    async (frameIdsWithNewDelays) => {
      return updateFramesDelaysAPI(await user.getIdToken(), projectId, frameIdsWithNewDelays);
    },
    [user, projectId]
  );

  const uploadMedia = useCallback(
    async (file, onProgressFn) => {
      return uploadMediaAPI(await user.getIdToken(), file, onProgressFn);
    },
    [user]
  );

  const updateUploadedMedia = useCallback(
    async (media) => {
      return updateUploadedMediaAPI(await user.getIdToken(), media);
    },
    [user]
  );

  const createTranscriptionRequest = useCallback(
    async (url) => {
      return createTranscriptionRequestAPI(await user.getIdToken(), url);
    },
    [user]
  );

  const createAvatarRequest = useCallback(
    async (urls, opts) => {
      return createAvatarRequestAPI(await user.getIdToken(), projectId, urls, opts);
    },
    [user, projectId]
  );

  const removeAvatar = useCallback(async () => {
    return removeAvatarAPI(await user.getIdToken(), projectId);
  }, [user, projectId]);

  const getAvatarProgress = useCallback(
    async (jobId) => {
      return getAvatarProgressAPI(await user.getIdToken(), projectId, jobId);
    },
    [user, projectId]
  );

  const updateClonedVoice = useCallback(
    async (voiceId, gender) => {
      await updateClonedVoiceAPI(await user.getIdToken(), voiceId, gender);
      refreshVoices();
    },
    [user, refreshVoices]
  );

  const projectNotUpToDate = editorConfigLoadStatus !== 'LISTENING' || editorConfig?.version !== KETTLE_LATEST_VERSION;
  const { conversionStatus } = useConvertStudioProjectToKettle(projectNotUpToDate, user, projectId);

  if (conversionStatus === CONVERSION_STATUS.ERROR)
    return renderErrorPage(
      'We apologize for the inconvenience, but an unexpected error occurred while trying to update ' +
        'your project to the latest version of our editor.',
      true
    );
  if (planSummaryLoadingState === PLAN_SUMMARY_STATUS.ERROR) {
    return renderErrorPage('Unable to complete your request due to a network error.', true);
  } else if (planSummaryLoadingState === PLAN_SUMMARY_STATUS.LOADED_NO_ACCESS) {
    return renderErrorPage(
      'The requested project cannot be found or you do not have sufficient privileges to access it.'
    );
  }

  const loadingPercent = calculateLoadingPercent([
    conversionStatus !== CONVERSION_STATUS.LOADING,
    planSummaryLoadingState !== PLAN_SUMMARY_STATUS.LOADING,
    editorMetadataLoadStatus === 'LISTENING',
    editorConfig,
    serializedEditorStateLoadStatus === 'LISTENING',
    exportedMediaLoadStatus === 'LISTENING',
    framesLoadStatus === 'LISTENING',
    userIsConnectedLoadStatus === 'LISTENING',
    avatarsLoadStatus === 'LISTENING',
    !featureFlagsLoading,
  ]);

  if (loadingPercent < 100) {
    const currentlyLoadingItem = loadingPercent < 40 ? 'voices' : loadingPercent < 70 ? 'frames' : 'presets';
    return <Loader spinnerLabel={`Loading editor ${currentlyLoadingItem}... ${loadingPercent}%`} />;
  }

  return (
    <>
      <LegacyInstantVoiceCloningModal
        open={voiceCloningOpen}
        setOpen={setVoiceCloningOpen}
        onCloneCompleted={(voiceId) => createAndOpenFile({ defaultVoice: voiceId })}
      />
      <AddNewStyleModal
        open={addNewStyleOpen.open}
        setOpen={(value) => setAddNewStyleOpen({ ...addNewStyleOpen, open: value })}
        parentVoiceId={addNewStyleOpen.parentVoiceId}
        onCloneCompleted={refreshVoices}
      />
      <FeedbackModal
        open={feedbackModalOpen}
        setOpen={setFeedbackModalOpen}
        projectId={projectId}
        editorPreset={editorConfig.editorPreset}
      />
      <KettleEditor
        projectId={projectId}
        userInfo={userInfo}
        creditType={getCreditTypeForUser(userInfo)}
        featureFlags={featureFlags}
        userIsConnected={userIsConnected}
        updateEditorTitle={updateEditorTitle}
        updateEditorPreset={updateEditorPreset}
        updateSelectedVideo={updateSelectedVideo}
        createNewEmptyFrames={createNewEmptyFrames}
        generatePreviewsForFrame={generatePreviewsForFrame}
        updateSelectedAudioSample={updateSelectedAudioSample}
        updateAudioSampleTitle={updateAudioSampleTitle}
        removeAudioSample={removeAudioSample}
        exportTimelineMedia={exportTimelineMedia}
        updateFrameOptions={updateFrameOptions}
        updateFramesDelays={updateFramesDelays}
        createTranscriptionRequest={createTranscriptionRequest}
        editorTitle={editorMetadata?.title}
        editorModel={editorMetadata?.model ?? DEFAULT_FILE_MODEL}
        isEditorTitleAutogenerated={editorMetadata?.isTitleAutogenerated ?? true}
        editorConfig={editorConfig}
        availableVoicePresets={availableVoicePresets}
        initialEditorState={serializedEditorState}
        onEditorStateChange={handleEditorStateChange}
        exportedMedia={exportedMedia ?? {}}
        framesState={frames}
        defaultVoice={defaultVoice}
        availableVoices={availableVoices}
        planBannerInfo={planSummaryData}
        // no need to have the back button on roost
        // onClickBack={() => (window.location.href = '/app/audio-files?tab=ultra')}
        notifyUser={notifyUser}
        reportError={(message, error) => reportKettleError(projectId, message, error)}
        reportEvent={reportAnalyticsEvent}
        enableAnimation={!disableAnimation}
        enableDebugger={enableDebugger}
        forceTheme="dark"
        uploadedMedia={uploadedMedia ?? []}
        uploadMedia={uploadMedia}
        updateUploadedMedia={updateUploadedMedia}
        createAvatarRequest={createAvatarRequest}
        removeAvatar={removeAvatar}
        getAvatarProgress={getAvatarProgress}
        avatars={avatars}
        createNewVoice={() => setVoiceCloningOpen(true)}
        createNewStyle={(parentVoiceId) => setAddNewStyleOpen({ parentVoiceId, open: true })}
        showFeedbackModal={() => setFeedbackModalOpen(true)}
        updateClonedVoice={updateClonedVoice}
      />
    </>
  );
}

const PLAN_SUMMARY_STATUS = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  LOADED_NO_ACCESS: 'LOADED_NO_ACCESS',
  LOADED_WITH_ACCESS: 'LOADED_WITH_ACCESS',
};

function usePlanSummary(user, projectId) {
  const [planSummaryLoadingState, setPlanSummaryLoadingState] = useState(PLAN_SUMMARY_STATUS.LOADING);

  const [planSummaryData, setPlanSummaryData] = useState(null);
  useEffect(() => {
    user
      .getIdToken()
      .then((token) => getUserPlanSummaryAPI(token, projectId))
      .then(({ data }) => {
        setPlanSummaryData(data);
        setPlanSummaryLoadingState(
          data.at ? PLAN_SUMMARY_STATUS.LOADED_WITH_ACCESS : PLAN_SUMMARY_STATUS.LOADED_NO_ACCESS
        );
      })
      .catch((e) => {
        reportKettleError(projectId, 'Error while fetching plan banner info data: ' + e.message, e);
        setPlanSummaryLoadingState(PLAN_SUMMARY_STATUS.ERROR);
      });
  }, []);
  return { planSummaryLoadingState, planSummaryData };
}

// if there are more than one sample with the same audioURL, it just keeps the first
function uniqueAudioSamples(samples) {
  const previousURLs = new Set();
  return samples.filter((sample) => {
    if (!previousURLs.has(sample.audioURL)) {
      previousURLs.add(sample.audioURL);
      return true;
    }
    return false;
  });
}

const CONVERSION_STATUS = {
  LOADING: 'LOADING',
  PROJECT_NOT_FOUND: 'PROJECT_NOT_FOUND',
  ERROR: 'ERROR',
};

function useConvertStudioProjectToKettle(projectNotLastVersion, user, projectId) {
  const [conversionStatus, setConversionStatus] = useState(CONVERSION_STATUS.LOADING);
  const conversionStarted = useRef(false);
  useEffect(() => {
    async function executeConversion() {
      conversionStarted.current = true;
      const { projectExists } = await convertEditorToKettleAPI(await user.getIdToken(), projectId);
      if (projectExists) {
        console.log('Conversion completed. Reloading.');
        window.location.reload();
      } else {
        setConversionStatus(CONVERSION_STATUS.PROJECT_NOT_FOUND);
      }
    }

    if (projectNotLastVersion && !conversionStarted.current) {
      executeConversion().catch((e) => {
        reportKettleError(projectId, 'Error while converting', e);
        setConversionStatus(CONVERSION_STATUS.ERROR);
      });
    }
  }, [projectId, projectNotLastVersion, user]);
  return { conversionStatus };
}

const renderErrorPage = (message, allowRetry) => (
  <div className="flex h-screen flex-col justify-center gap-4 text-center">
    <div>{message}</div>
    {allowRetry ? (
      <>
        <div>Click below to try again in a few moments. If the error persists, please contact our support team.</div>
        <div>
          <button
            className="kettle-button-rounded"
            style={{ display: 'inline-block' }}
            onClick={() => window.location.reload()}
          >
            Try Again
          </button>
        </div>
      </>
    ) : null}
  </div>
);

function calculateLoadingPercent(tasksDone) {
  const total = tasksDone.length;
  const loaded = tasksDone.filter((b) => b).length;
  return Math.round((loaded / total) * 100);
}
