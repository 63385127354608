import { format } from 'date-fns';
import { FileIcon } from './FileIcon';
import { DropzoneFile } from '../../__playrepo/ui-components/src/FilesDropzone';
import { TrashIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';

const getFileSubtype = (file: File) => {
  return file.type?.split('/')[1]?.toUpperCase();
};

export const UploadedFile = ({
  sample: { file, progress },
  onFileDelete,
}: {
  sample: DropzoneFile;
  onFileDelete: () => void;
}) => {
  return (
    <div className="flex items-center gap-4 rounded-2xl border-2 border-gray-800 py-6 px-4">
      <button className="flex">
        <FileIcon className="text-white w-6 h-6" file={file} />
      </button>
      <div className="flex flex-col flex-1 overflow-hidden">
        <span className="text-left text-white flex-1 truncate mb-1 text-[12px] leading-[20px] font-medium">
          {file.name}
        </span>
        <span className="text-left text-white/50 flex-1 truncate text-[12px] leading-[14px] font-normal">
          {file.type && getFileSubtype(file)} file &middot; last updated {format(new Date(file.lastModified), 'MMM dd')}
        </span>
      </div>
      {progress < 100 && (
        <div className="h-2 w-[40px] rounded-full bg-gray-600">
          <motion.div
            className="h-full rounded-full bg-primary-green-600"
            initial={{ width: 0 }}
            animate={{ width: (progress / 100) * 40 }}
          />
        </div>
      )}
      <button className="hover:opacity-80 duration-200 ease-out" onClick={onFileDelete}>
        <TrashIcon className="h-4 w-4 text-red-500" />
      </button>
    </div>
  );
};
