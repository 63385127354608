import React, { ReactNode, useState } from 'react';

export function ExpandableText({ children, resumeText }: { children: ReactNode; resumeText: string }) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  return isExpanded ? (
    <>{children}</>
  ) : (
    <span>
      {resumeText}
      <span className="cursor-pointer underline" onClick={() => setIsExpanded(!isExpanded)}>
        See more
      </span>
    </span>
  );
}
