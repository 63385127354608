import { FC } from 'react';
import { LanguageSelect } from '../LanguageSelect';
import { Button } from '../../__playrepo/ui-components/src/Button';
import { Link } from 'react-router-dom';
import { ArrowUpRightIcon } from '@heroicons/react/24/solid';
import { Language } from './useCreateVoiceCloneWizard';

interface InputLanguageProps {
  onClick: () => void;
  setLanguage: (value: Language) => void;
  language: Language;
}

const InputLanguage: FC<InputLanguageProps> = ({ onClick, language, setLanguage }) => {
  return (
    <>
      <LanguageSelect language={language} setLanguage={setLanguage} className="mb-6" />
      <Button intent="cta" className="w-full mb-6" onClick={onClick}>
        Next
      </Button>
      <div className="flex flex-col items-center text-gray-300 text-[14ppx] leading-[1.1] font-normal">
        <div className="flex items-center mb-[10px]">
          <span className="mr-[3px]">Your voice clone will be able to speak in</span>
          <Link to="/" className="underline decoration-dotted underline-offset-2">
            36 languages.
          </Link>
          <ArrowUpRightIcon className="w-[14px] h-[14px]" />
        </div>
        <span className="mr-[3px]">But it will be best in language it was recorded in.</span>
      </div>
    </>
  );
};

export default InputLanguage;
