import clsx from 'clsx';
import { CycleType, PlanT, cycle } from '../../utils/plans.helpers';
import { formatCurrency } from '../../utils/formatCurrency';
import { Link } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useUser } from '../../contexts/user.context';
import { ReactNode } from 'react';
import fakeAvatar1 from '../../assets/fakeavatars/fake-avatar-1.png';
import fakeAvatar2 from '../../assets/fakeavatars/fake-avatar-2.png';
import fakeAvatar3 from '../../assets/fakeavatars/fake-avatar-3.png';
import fakeAvatar4 from '../../assets/fakeavatars/fake-avatar-4.png';
import fakeAvatar5 from '../../assets/fakeavatars/fake-avatar-5.png';
import fakeAvatar6 from '../../assets/fakeavatars/fake-avatar-6.png';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import TooltipComponent from '../../components/Tooltip';

const fakeAvatarsForStudio = [fakeAvatar1, fakeAvatar2, fakeAvatar3];
const fakeAvatarsForApi = [fakeAvatar4, fakeAvatar5, fakeAvatar6];

export function PricingPlanCard({ plan, billingCycle }: { plan: PlanT; billingCycle: CycleType }) {
  const { user } = useUser();
  return (
    <div className="text-black">
      <div
        className={clsx(
          'min-w-[16.5rem] max-w-[18rem] text-white flex-1 max-tablet:w-full px-4 py-6 rounded-xl h-full bg-gray-800'
        )}
      >
        <div className="mb-4">
          <h3 id={plan.key} className="flex items-center justify-between">
            <span className="text-xl font-semibold">{plan.name}</span>
            {plan.isRecommended && (
              <div className="px-3 py-1 rounded-full text-primary-green-600 font-semibold text-xs">
                {plan.isOnDiscount ? 'Limited Time!' : 'Most Popular'}
              </div>
            )}
          </h3>
        </div>
        <div className="mb-6">
          <div className="h-[15px]">
            {plan.isOnDiscount ? (
              <p className="text-xs font-bold text-[#F97983]">
                {plan.discountCopy ? plan.discountCopy : 'Limited Time Deal'}
              </p>
            ) : null}
          </div>
          <Price plan={plan} billingCycle={billingCycle} />
        </div>
        <div className="mb-4">
          <PlanButtonArea plan={plan} isProUser={user?.is_pro} />
        </div>
        <ul className="flex flex-col gap-2">
          {plan.features.map((feature) => (
            <li key={feature.name} className="flex items-start justify-between">
              <div className="flex gap-1 items-start">
                <div className="mt-0.5">
                  {feature.available ? (
                    <CheckIcon className="h-4 w-4 flex-none text-green-600" aria-hidden="true" />
                  ) : (
                    <XMarkIcon className="h-4 w-4 mt-1 flex-none text-red-500" aria-hidden="true" />
                  )}
                </div>
                <span className={'font-medium leading-6 w-11/12'}>{feature.name}</span>
              </div>
              {feature.info ? (
                <TooltipComponent
                  toolTipHeaderText={feature.info}
                  customTooltipIcon={<InformationCircleIcon width={20} height={20} className="text-white" />}
                  tooltipWrapperClassName={'mt-0'}
                />
              ) : null}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function Price({ plan, billingCycle }: { plan: PlanT; billingCycle: 'monthly' | 'annually' }) {
  const getMonthlyPrice = (amount: number) => (billingCycle === cycle.MONTHLY ? amount : amount / 12);
  const price = getMonthlyPrice(plan.price);
  const priceSpan = price ? formatCurrency(price) : '$0';
  const detailSpan = plan.isEnterprisePlan
    ? 'Please contact us'
    : plan.price
    ? `USD ${formatCurrency(plan.price)} billed ${billingCycle}`
    : null;

  return (
    <div className="flex flex-col">
      {plan.isEnterprisePlan ? (
        <span className="text-[2rem] leading-8 font-bold">Custom pricing</span>
      ) : (
        <div className="flex gap-1 items-center">
          {plan.isOnDiscount ? (
            <p className="text-[2rem] font-bold line-through text-neutral-500">
              ${getMonthlyPrice(plan.priceBeforeDiscount || 0)}
            </p>
          ) : null}
          <p className="text-[2rem] leading-8 font-bold">{priceSpan}</p>
          <div className="text-xs font-medium">/month</div>
        </div>
      )}
      <div className="text-xs mb-4 font-sfpro leading-5 min-h-[20px]">{detailSpan || ''}</div>
      <div className="flex flex-shrink-0 text-sm font-sfpro font-semibold leading-5">{plan.characterLimit}</div>
    </div>
  );
}

function PlanButtonArea({ plan, isProUser }: { plan: PlanT; isProUser?: boolean }) {
  const link = plan.isEnterprisePlan
    ? 'https://play.ht/contact-us/'
    : plan.isFree
    ? ''
    : `/studio/checkout/?plan=${plan.key}`;
  const buttonText = plan.isEnterprisePlan
    ? 'Contact Sales'
    : plan.price
    ? 'Get Plan'
    : isProUser
    ? 'Default Plan'
    : 'Your current plan';
  const buttonType = plan.isRecommended ? 'featured' : plan.isEnterprisePlan || plan.price ? 'default' : 'disabled';
  const fakeAvatars = plan.target === 'studio' ? fakeAvatarsForStudio : fakeAvatarsForApi;
  return (
    <div className="h-[5.5rem]">
      <LinkElement to={link} className={link ? '' : 'cursor-default'} isExternal={plan.isEnterprisePlan ?? false}>
        <GetPlanButton type={buttonType}>{buttonText}</GetPlanButton>
      </LinkElement>
      {plan.isRecommended ? (
        <div className="flex items-center justify-center gap-2 p-1.5 rounded-b-lg bg-primary-green-900/20 text-xs">
          <div className="flex">
            {fakeAvatars.map((avatar) => (
              <img
                key={avatar}
                src={avatar}
                alt="avatar"
                className="w-7 h-7 rounded-full border-1 border-neutral-900 -ml-3 first:ml-0"
              />
            ))}
          </div>
          <div>{plan.target === 'studio' ? '74% of professionals' : '67% of developers'} choose this</div>
        </div>
      ) : null}
    </div>
  );
}

function GetPlanButton({ type, children }: { type: 'default' | 'disabled' | 'featured'; children: ReactNode }) {
  return (
    <div
      className={clsx('px-4 py-3 font-semibold text-base text-center', {
        'rounded-xl dark:bg-neutral-750 text-primary-green-600 hover:!bg-opacity-90': type === 'default',
        'rounded-xl dark:bg-neutral-800 dark:text-neutral-400': type === 'disabled',
        'rounded-t-xl bg-primary-green-600 text-neutral-900 hover:!bg-opacity-90': type === 'featured',
      })}
    >
      {children}
    </div>
  );
}

function LinkElement({
  to,
  children,
  isExternal,
  className,
}: {
  to: string;
  children: ReactNode;
  isExternal: boolean;
  className?: string;
}) {
  if (isExternal) {
    return (
      <a href={to} className={className}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to} className={className}>
      {children}
    </Link>
  );
}
