import { UserGroupIcon } from '@heroicons/react/24/outline';
import { Button } from '../../components/Button';
import { DocumentIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { useActiveMainPlan } from '../../hooks/plans.hooks';
import { useCreateATeam } from '../../hooks/teams.hooks';

const features = [
  {
    name: 'Shared File',
    description: 'The Projects and files created by team members can be accessed by the entire team',
    icon: DocumentIcon,
  },
  {
    name: 'Centralized Billing',
    description: 'Team members don’t have access to billing, only admin does',
    icon: DocumentIcon,
  },
  {
    name: 'Shared Settings',
    description: 'Pronunciations, sample rates and audio formats are common for all members',
    icon: DocumentIcon,
  },
];

export function TeamAccessEmptyState() {
  const navigate = useNavigate();
  const canUserCreateATeam = useActiveMainPlan();
  const { mutate: createATeam, isLoading: isCreatingATeam } = useCreateATeam();

  return (
    <div className="max-w-7xl">
      <h1 className="mb-8 text-xl font-semibold">
        <UserGroupIcon className="inline-block w-6 h-6 mr-2" /> Team Access
      </h1>
      <div className="flex flex-col gap-4 text-center lg:flex-row w-full justify-between items-center bg-neutral-800 px-6 py-4 rounded-2xl">
        {canUserCreateATeam ? (
          <>
            <p className="font-semibold text-base">Create a team to start using this feature</p>
            <Button intent="primary" size="large" onClick={() => createATeam()} loading={isCreatingATeam}>
              <p className="px-16">Create a team</p>
            </Button>
          </>
        ) : (
          <>
            <p className="font-semibold text-base">Please upgrade your plan to start using this feature</p>
            <Button intent="primary" size="large" onClick={() => navigate('/studio/pricing')}>
              <p className="px-16">Upgrade</p>
            </Button>
          </>
        )}
      </div>
      <div className="mt-16">
        <p className="text-lg font-semibold text-center">
          Share access to your account with your team members for collaborative work !
        </p>
        <div className="mt-8 flex gap-4 flex-wrap justify-center">
          {features.map((feature) => (
            <Feature key={feature.name} name={feature.name} description={feature.description} Icon={feature.icon} />
          ))}
        </div>
      </div>
    </div>
  );
}

function Feature({ name, description, Icon }: { name: string; description: string; Icon: typeof DocumentIcon }) {
  return (
    <div className="bg-neutral-900 w-[300px] flex flex-col gap-4 text-center items-center justify-center py-10 px-8 rounded-3xl">
      <div className="flex flex-col items-center gap-2">
        <Icon className="w-6 h-6" />
        <p className="font-semibold text-base">{name}</p>
      </div>
      <p className="text-base font-normal leading-6">{description}</p>
    </div>
  );
}
