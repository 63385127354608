import { Dispatch, FC, SetStateAction } from 'react';
import { Checkbox } from '../../__playrepo/ui-components/src/Checkbox';
import { AudioClipper } from '../AudioClipper';
import { GuideClipT } from '../../API/voiceCloning.requests';
import { Button } from '../../__playrepo/ui-components/src/Button';
import { VoiceCloningConsentCheckbox } from '../../pages/VoiceCloning/VoiceCloningConsentCheckbox';
import { useUser } from '../../contexts/user.context';
import { UploadedFile } from './UploadedFile';
import { UploadedFile as UploadedFileType } from './useCreateVoiceCloneWizard';

interface FinishHFCloneProps {
  speakerName: string;
  setSpeakerName: (name: string) => void;

  ar_epochs: number;
  diff_epochs: number;
  setArEpochs: Dispatch<SetStateAction<number>>;
  setDiffEpochs: Dispatch<SetStateAction<number>>;
  uploadedHfFiles: UploadedFileType[];
  handleDeleteAudio: (index: number) => void;
  hasMultipleSpeakers: boolean;
  setHasMultipleSpeakers: Dispatch<SetStateAction<boolean>>;
  setClippings: Dispatch<SetStateAction<GuideClipT[] | undefined>>;
  consent: boolean;
  clippings: GuideClipT[] | undefined;
  setConsent: Dispatch<SetStateAction<boolean>>;
  isDisabled: boolean;
  isSubmitting: boolean;
  submit: (params: {
    audioUrls: string[];
    speakerName: string;
    hasMultipleSpeakers: boolean;
    clippings?: GuideClipT[];
    ar_epochs: number;
    diff_epochs: number;
  }) => void;
}

const FinishHFClone: FC<FinishHFCloneProps> = ({
  speakerName,
  setSpeakerName,
  ar_epochs,
  setArEpochs,
  diff_epochs,
  setDiffEpochs,
  uploadedHfFiles,
  handleDeleteAudio,
  hasMultipleSpeakers,
  setHasMultipleSpeakers,
  setClippings,
  consent,
  setConsent,
  isDisabled,
  isSubmitting,
  clippings,
  submit,
}) => {
  const { user: userInfo } = useUser();

  return (
    <div className="flex flex-col flex-1">
      <input
        name="voiceName"
        value={speakerName}
        onChange={(event) => setSpeakerName(event.target.value)}
        placeholder="Enter voice name"
        className="mt-8 mb-4 w-full text-[16px] leading-none p-4 bg-gray-800 text-white placeholder:text-gray-600 border-2 rounded-2xl border-[rgba(180,253,131,0.2)]"
      />

      {userInfo?.isAdmin && (
        <div>
          <div className="mb-6 flex flex-col gap-2">
            <label className="text-sm font-medium">AR epochs</label>
            <input
              type="number"
              className="rounded-lg border-[.5px]"
              value={ar_epochs}
              onChange={(event) => setArEpochs(Number(event.target.value))}
            />
          </div>
          <div className="mb-6 flex flex-col gap-2">
            <label className="text-sm font-medium">Diff epochs</label>
            <input
              type="number"
              className="rounded-lg border-[.5px]"
              value={diff_epochs}
              onChange={(event) => setDiffEpochs(Number(event.target.value))}
            />
          </div>
        </div>
      )}

      {uploadedHfFiles.length ? (
        <div className="flex flex-col gap-2 mb-4">
          {uploadedHfFiles.map((sample, index) => (
            <UploadedFile sample={sample} key={sample.file.name} onFileDelete={() => handleDeleteAudio(index)} />
          ))}
        </div>
      ) : null}

      <Checkbox
        name="hasMultipleSpeakers"
        className="mb-5"
        label={
          <span className="text-white text-[14px] leading-[20px] font-normal">
            Check this box if there are multiple speakers in the recordings
          </span>
        }
        checked={hasMultipleSpeakers}
        onChange={setHasMultipleSpeakers}
      />

      {uploadedHfFiles.length > 0 && hasMultipleSpeakers && (
        <AudioClipper
          urls={uploadedHfFiles}
          onFinish={(clippings: GuideClipT[]) => setClippings(clippings)}
          speaker={speakerName}
          onSpeakerUpdate={(speaker: string) => setSpeakerName(speaker)}
        />
      )}

      <VoiceCloningConsentCheckbox consent={consent} setConsent={setConsent} />

      <Button
        intent="cta"
        disabled={isDisabled}
        loading={isSubmitting}
        onClick={() =>
          submit({
            audioUrls: uploadedHfFiles.map((a) => a.url),
            speakerName,
            hasMultipleSpeakers,
            clippings,
            ar_epochs,
            diff_epochs,
          })
        }
      >
        Finish
      </Button>
    </div>
  );
};

export default FinishHFClone;
