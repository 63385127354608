import axios, { type AxiosProgressEvent } from 'axios';

export const s3Utils = {
  getFileUrl: (bucketName: string, fileName: string) => `https://${bucketName}.s3.amazonaws.com/${fileName}`,
  getS3FileUrl: (bucketName: string, fileName: string) => `s3://${bucketName}/${fileName}`,

  uploadToS3WithPresignedUrl: (
    preSignedUrl: string,
    file: File | Blob,
    setUploadProgress?: (progress: number) => void
  ) =>
    axios.request({
      method: 'put',
      url: preSignedUrl,
      headers: { 'Content-Type': file.type },
      data: file,
      onUploadProgress: (p: AxiosProgressEvent) => p.total && setUploadProgress?.((p.loaded / p.total) * 100),
    }),
};
