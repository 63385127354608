'use client';

import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid/index.js';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

export type NotificationStatusT = 'success' | 'error';
export const NOTIFICATION_TYPE_SUCCESS: NotificationStatusT = 'success';
export const NOTIFICATION_TYPE_ERROR: NotificationStatusT = 'error';

export interface Notification {
  id: string;
  message: string;
  content?: ReactNode;
  status: NotificationStatusT;
}

export type SetNotificationPayload = {
  message: string;
  status: NotificationStatusT;
  content?: ReactNode;
};

export type SetNotification = (notification: SetNotificationPayload) => void;

const NotificationContext = createContext<{ setNotification: SetNotification }>({ setNotification: () => null });

export const NotificationsProvider = ({ children }: { children: JSX.Element }) => {
  const [notifications, setNotifications] = useState<Array<Notification>>([]);

  const setNotification = useCallback(
    ({ message, status, content }: SetNotificationPayload) => {
      const id = uuid();

      setNotifications((prev) => [
        ...prev,
        {
          id,
          message,
          status,
          content,
        },
      ]);
    },
    [setNotifications]
  );

  const clearNotification = useCallback(
    (id: string) => {
      setNotifications((prev) => prev.filter((notification) => notification.id !== id));
    },
    [setNotifications]
  );

  const value = useMemo(() => ({ setNotification }), [setNotification]);

  return (
    <NotificationContext.Provider value={value}>
      {children}
      {notifications && notifications.length > 0 && (
        <div className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:mt-16 sm:items-start sm:p-6">
          <div className="flex w-full flex-col items-center space-y-4 mobile:items-end">
            {notifications.map(({ id, message, status, content }) => (
              <NotificationSnackbar
                key={id}
                id={id}
                message={message}
                status={status}
                content={content}
                onClickClose={clearNotification}
              />
            ))}
          </div>
        </div>
      )}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationContext);

export function NotificationSnackbar({
  id,
  message,
  status,
  content,
  onClickClose,
}: {
  id: string;
  message: string;
  status: NotificationStatusT;
  content?: ReactNode;
  onClickClose: (id: string) => void;
}) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const delay = 5000;

  const clearTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const startTimer = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      onClickClose(id);
    }, delay);
  }, [id, onClickClose]);

  useEffect(() => {
    startTimer();

    return () => {
      clearTimer();
    };
  }, [startTimer]);

  const handleMouseEnter = () => {
    clearTimer();
  };

  const handleMouseLeave = () => {
    startTimer();
  };

  return (
    <div
      className={clsx(
        'pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg',
        status === 'success' ? 'bg-primary-green-600 text-black' : 'bg-red-500 text-white'
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex gap-3 p-4 items-center">
        <div className="shrink-0">
          {status === NOTIFICATION_TYPE_SUCCESS ? (
            <CheckCircleIcon className="h-7 w-7" aria-hidden="true" />
          ) : status === NOTIFICATION_TYPE_ERROR ? (
            <ExclamationCircleIcon className="h-7 w-7" aria-hidden="true" />
          ) : null}
        </div>
        <div className="flex-1 font-medium">
          <p>{message}</p>
        </div>
        {content ?? null}
        <div className="flex shrink-0">
          <button className="inline-flex cursor-pointer" onClick={() => onClickClose(id)}>
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-7 w-7" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}
