/* eslint-disable @typescript-eslint/no-explicit-any */
type ObjectKeys<T> = T extends object ? (keyof T)[] : T extends Array<unknown> | string ? string[] : never;

type ObjectEntries<T> = T extends object
  ? { [K in keyof T]: [K, T[K]] }[keyof T][]
  : T extends Array<infer V>
  ? V[]
  : never;

type ObjectFromEntries<T extends [keyof any, any][]> = {
  [K in T[number][0]]: Extract<T[number], [K, any]>[1];
};

export function keys<T>(o: T): ObjectKeys<T> {
  return Object.keys(o as object) as ObjectKeys<T>;
}

export function entries<T>(o: T): ObjectEntries<T> {
  return Object.entries(o as object) as ObjectEntries<T>;
}

export function fromEntries<T extends [keyof any, any][]>(entries: T): ObjectFromEntries<T> {
  return Object.fromEntries(entries) as ObjectFromEntries<T>;
}
