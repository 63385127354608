import { useEffect, useState } from 'react';
import { getDatabase, onValue, ref } from 'firebase/database';

export const useFirebaseListener = <T, S = unknown>(
  path: string,
  /**
   * Other than `databaseURL`, the listener will not be recreated when changes to `transform`, `initialState` or `onError` occur.
   * Which means `transform` and `onError` should be functions that only work with the arguments they receive, and not any
   * outer variables.
   */
  options: {
    transform?: (s: FirebaseObject<S> | null) => T;
    initialState?: T;
    onError?: (error: Error) => void;
    databaseURL?: string;
  } = {}
) => {
  const [firebaseSnapshotLoadStatus, setFirebaseSnapshotLoadStatus] = useState<'CONNECTING' | 'LISTENING' | 'ERROR'>(
    'CONNECTING'
  );
  const [firebaseSnapshotValue, setFirebaseSnapshotValue] = useState(options.initialState ?? null);
  useEffect(() => {
    const database = getDatabase(undefined, options.databaseURL);
    return onValue(
      ref(database, path),
      (snapshot) => {
        setFirebaseSnapshotValue(options.transform ? options.transform(snapshot.val()) : snapshot.val());
        setFirebaseSnapshotLoadStatus('LISTENING');
      },
      (error) => {
        console.error(`[useFirebaseListener] Error while reading "${path}":`, error);
        setFirebaseSnapshotLoadStatus('ERROR');
        options.onError?.(error);
      }
    );

    // We don't want to add the whole `options` as dependency array, because it would recreate the listener
    // pretty much every time the component re-renders, unless the client passes a memoized function in the
    // `transform` field, which would make the API a lot less convenient.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, options.databaseURL]);
  return [firebaseSnapshotValue, firebaseSnapshotLoadStatus] as const;
};

type ExtractElementType<T> = T extends Array<infer U> ? U : T;
type IsPlainObject<T> = T extends object
  ? T extends (...args: Array<never>) => never
    ? false
    : T extends string | number | boolean | Date | RegExp
    ? false
    : true
  : false;
type IsFullyOptional<T> = Required<T> extends T ? false : true;

/**
 * Firebase objects are a mess.
 * When you store an object in Firebase, it strips many properties, such as `null` values, empty arrays and empty objects.
 *
 * This helper type converts any field with `T | null` to `T | undefined`, because firebase removes the fields when
 * their values are `null`. It also converts `Array<T>` to `Array<T> | undefined` because firebase removes empty arrays.
 * And lastly, it converts any object to `FirebaseObject<T>`, which recursively applies the same rules to all its
 * fields -- and if the type T is fully optional, it also adds `| undefined` to the whole object.
 * https://firebase.google.com/docs/reference/js/database#set
 */
type FirebaseObject<T> = {
  [P in keyof T]: null extends T[P]
    ? NonNullable<T[P]> | undefined
    : T[P] extends Array<never>
    ? Array<ExtractElementType<T[P]>> | undefined // converts `Array<X>` types into `Array<X> | undefined`
    : IsPlainObject<T[P]> extends true // converts objects, say X, into `FirebaseObject<X>`
    ? IsFullyOptional<T[P]> extends true // if all properties of X are optional, it becomes `FirebaseObject<X> | undefined`
      ? FirebaseObject<T[P]> | undefined
      : FirebaseObject<T[P]>
    : T[P];
};
