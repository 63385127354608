import { NOTIFICATION_TYPE_ERROR, useNotifications } from '../__playrepo/ui-components/src/NotificationSnackbar.tsx';
import { useCallback } from 'react';

export function useErrorNotification() {
  const { setNotification } = useNotifications();
  return useCallback(
    (e: unknown, defaultMessage = 'Something went wrong') => {
      let message = defaultMessage;
      // TODO: Check if AxiosError and contains a friendly message (needs to standardize the error format)
      if (e instanceof Error) {
        message = e.message;
      }
      setNotification({ message, status: NOTIFICATION_TYPE_ERROR });
    },
    [setNotification]
  );
}
