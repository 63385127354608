import axios from 'axios';
import { get, orderByChild, ref, query, equalTo } from 'firebase/database';
import { database } from '../utils/firebase.ts';
import { Invite } from '../domain/teams/Invite.ts';
import { CONFIG } from '../config.ts';

const axiosInstance = axios.create({ baseURL: CONFIG.playAPIBaseUrl });

export function createATeam(idToken: string) {
  const url = '/createTeam';
  const body = { token: idToken };
  return axiosInstance.post(url, body);
}

export async function fetchTeamInvites(teamId: string): Promise<Invite[]> {
  const dbRef = ref(database, 'teamInvites');
  const invitesQuery = query(dbRef, orderByChild('teamId'), equalTo(teamId));
  const invites = await get(invitesQuery);
  return invites.val() ? Object.values(invites.val()) : [];
}

export async function inviteTeamMember(teamId: string, memberEmail: string, idToken: string) {
  const url = '/inviteMember';
  const body = { token: idToken, teamId, memberEmail };
  return axiosInstance.post(url, body);
}

export async function cancelPendingInvite(teamId: string, inviteId: string, idToken: string) {
  const url = '/cancelInvite';
  const body = { token: idToken, teamId, inviteId };
  return axiosInstance.delete(url, {
    data: body,
  });
}

export async function removeTeamMember(teamId: string, inviteId: string, idToken: string) {
  const url = '/removeFromTeam';
  const body = { token: idToken, teamId, inviteId };
  return axiosInstance.put(url, body);
}
