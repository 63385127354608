export interface OnboardingStep {
  id: string;
  question: string;
  options: OnboardingStepOption[];
}

export interface OnboardingStepOption {
  id: string;
  title: string;
  showIf?: string;
}

export const onboardingSteps: OnboardingStep[] = [
  {
    id: 'role',
    question: 'Are you an individual, or do you represent a company?',
    options: [
      {
        id: 'individual',
        title: 'Individual',
      },
      {
        id: 'company',
        title: 'Company',
      },
    ],
  },
  {
    id: 'type',
    question: 'What are you creating audio for?',
    options: [
      {
        id: 'api',
        title: 'API',
      },
      {
        id: 'videos',
        title: 'Videos',
      },
      {
        id: 'elearning',
        title: 'E-Learning',
      },
      {
        id: 'audio-articles',
        title: 'Articles / Blog Posts',
      },
      {
        id: 'apps-games-ivr',
        title: 'Apps / Games / IVR',
      },
      {
        id: 'dubbing-translations',
        title: 'Dubbing / Translations',
      },
      {
        id: 'audio-books-podcast',
        title: 'Podcasts / Audiobooks',
      },
      {
        id: 'personal',
        title: 'Personal Listening',
      },
      {
        id: 'other',
        title: 'Other',
      },
    ],
  },
  {
    id: 'usecase',
    question: 'What do you plan to use the audio for?',
    options: [
      {
        id: 'wordpress',
        title: 'WordPress',
        showIf: 'audio-articles',
      },
      {
        id: 'medium',
        title: 'Medium',
        showIf: 'audio-articles',
      },
      {
        id: 'squarespace',
        title: 'SquareSpace',
        showIf: 'audio-articles',
      },
      {
        id: 'hubspot',
        title: 'Hubspot',
        showIf: 'audio-articles',
      },
      {
        id: 'wix',
        title: 'Wix',
        showIf: 'audio-articles',
      },
      {
        id: 'ghost',
        title: 'Ghost',
        showIf: 'audio-articles',
      },
      {
        id: 'webflow',
        title: 'Webflow',
        showIf: 'audio-articles',
      },
      {
        id: 'drupal',
        title: 'Drupal',
        showIf: 'audio-articles',
      },

      {
        id: 'productivity',
        title: 'Productivity',
        showIf: 'personal',
      },
      {
        id: 'vision',
        title: 'Vision Problems',
        showIf: 'personal',
      },
      {
        id: 'dyslexia',
        title: 'Dyslexia',
        showIf: 'personal',
      },
      {
        id: 'learning',
        title: 'Learning',
        showIf: 'personal',
      },

      {
        id: 'application',
        title: 'Applications',
        showIf: 'apps-games-ivr',
      },
      {
        id: 'games',
        title: 'Games',
        showIf: 'apps-games-ivr',
      },
      {
        id: 'ivr',
        title: 'Interactive Voice Systems',
        showIf: 'apps-games-ivr',
      },

      {
        id: 'training-videos',
        title: 'Employee Training',
        showIf: 'videos',
      },
      {
        id: 'entertainment-videos',
        title: 'Entertainment Videos',
        showIf: 'videos',
      },
      {
        id: 'youtube-videos',
        title: 'YouTube Videos',
        showIf: 'videos',
      },
      {
        id: 'tiktok-videos',
        title: 'Tiktok Videos',
        showIf: 'videos',
      },
      {
        id: 'instagram-videos',
        title: 'Instagram Videos',
        showIf: 'videos',
      },
      {
        id: 'educational-videos',
        title: 'Educational Videos',
        showIf: 'videos',
      },
      {
        id: 'marketing-videos',
        title: 'Marketing Videos',
        showIf: 'videos',
      },
      {
        id: 'product-videos',
        title: 'Product Demos',
        showIf: 'videos',
      },
      {
        id: 'motivational-videos',
        title: 'Motivational Videos',
        showIf: 'videos',
      },
      {
        id: 'explainer-videos',
        title: 'Explainer Videos',
        showIf: 'videos',
      },

      {
        id: 'articulate-storyline-360',
        title: 'Articulate / Storyline 360',
        showIf: 'elearning',
      },
      {
        id: 'language-learning',
        title: 'Language Teaching',
        showIf: 'elearning',
      },
      {
        id: 'training-modules',
        title: 'Training Modules',
        showIf: 'elearning',
      },
      {
        id: 'lms',
        title: 'LMS',
        showIf: 'elearning',
      },
      {
        id: 'educational-videos',
        title: 'Educational Videos',
        showIf: 'elearning',
      },

      {
        id: 'dubbing',
        title: 'Dubbing',
        showIf: 'dubbing-translations',
      },
      {
        id: 'translation',
        title: 'Audio Translations',
        showIf: 'dubbing-translations',
      },

      {
        id: 'podcasts',
        title: 'Podcasts',
        showIf: 'audio-books-podcast',
      },
      {
        id: 'audiobooks',
        title: 'Audiobooks',
        showIf: 'audio-books-podcast',
      },
    ],
  },
];
