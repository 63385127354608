import axios from 'axios';
import { CONFIG } from '../config.ts';
import { get, ref } from 'firebase/database';
import { database } from '../utils/firebase.ts';

const axiosInstance = axios.create({ baseURL: CONFIG.playAPIBaseUrl });

export async function fetchApiSecretKey(userId?: string) {
  if (!userId) return null;
  const query = ref(database, `public-api/${userId}/secretKey`);
  const result = await get(query);
  return result.val() as string | null;
}

export function generateApiSecretKey(idToken: string) {
  const url = '/publicApi/secretKey';
  const body = { token: idToken, source: 'new-studio' };
  return axiosInstance.post(url, body);
}

export function revokeApiSecretKey(idToken: string) {
  const url = '/publicApi/secretKey';
  const body = { token: idToken };
  return axiosInstance.delete(url, { data: body });
}
