import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';
import { VoiceGender } from '../../__playrepo/play-voice-domain/src/Voice';

const gender = ['male', 'female'];

interface GenderToggleProps {
  value: VoiceGender;
  onChange: (value: VoiceGender) => void;
}

const GenderToggle: React.FC<GenderToggleProps> = ({ value, onChange }) => {
  return (
    <div className="w-full mb-4">
      <div className="mx-auto w-full max-w-md">
        <RadioGroup value={value} onChange={onChange} className="flex items-center">
          <RadioGroup.Label className="mr-5 text-white font-semibold text-[14px] leading-[20px]">
            Voice Gender:
          </RadioGroup.Label>
          <div className="flex gap-2">
            {gender.map((gender) => (
              <RadioGroup.Option key={gender} value={gender}>
                {({ checked }) => (
                  <>
                    <div className="flex items-center cursor-pointer ">
                      <span className="p-2">
                        {checked ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                              fill="#B4FD83"
                            />
                            <path
                              d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
                              fill="#B4FD83"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                              fill="#49454F"
                            />
                          </svg>
                        )}
                      </span>
                      <RadioGroup.Label
                        as="span"
                        className={clsx(
                          ' font-semibold text-[14px] leading-[20px] transition hover:opacity-80 duration-200 ease-out',
                          checked ? 'text-primary-green-600' : 'text-white'
                        )}
                      >
                        {gender}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className={clsx(checked ? 'text-primary-green-600' : 'text-white')}
                      ></RadioGroup.Description>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
};

export default GenderToggle;
