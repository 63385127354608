import { DocumentTextIcon, PhotoIcon, SpeakerWaveIcon, VideoCameraIcon } from '@heroicons/react/24/outline';

export function FileIcon({ file, className }: { file: File; className: string }) {
  switch (getFileType(file)) {
    case 'image':
      return <PhotoIcon className={className} />;
    case 'video':
      return <VideoCameraIcon className={className} />;
    case 'audio':
      return <SpeakerWaveIcon className={className} />;
    default:
      return <DocumentTextIcon className={className} />;
  }
}

function getFileType(file: File) {
  return file.type?.split('/')[0]?.toLowerCase();
}
