import { useRef, useState } from 'react';
import { clsx } from 'clsx';

export function SimpleStatefulRequestButton({
  textNormal,
  textInProgress,
  textRetry,
  retryLingerDuration = 10000,
  onClick,
}: {
  textNormal: string;
  textInProgress: string;
  textRetry: string;
  retryLingerDuration?: number;
  onClick: () => Promise<void>;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const timer = useRef<number | undefined>();

  return (
    <button
      className={clsx('text-xs', { 'cursor-pointer': !isLoading, 'text-error': isError, 'text-neutral': isLoading })}
      onClick={async () => {
        if (isLoading) return;

        clearTimeout(timer.current);
        timer.current = undefined;

        try {
          setIsLoading(true);
          await onClick();
          setIsLoading(false);
        } catch (e) {
          setIsError(true);
          setIsLoading(false);
          timer.current = window.setTimeout(() => {
            setIsError(false);
          }, retryLingerDuration);
        }
      }}
    >
      {isLoading ? textInProgress : isError ? textRetry : textNormal}
    </button>
  );
}
