import { Dialog } from '@headlessui/react';

import { motion } from 'framer-motion';
import useMeasure from 'react-use-measure';

import useCreateVoiceCloneWizard, { STEP } from './useCreateVoiceCloneWizard';
import { clsx } from 'clsx';
import { VoiceId } from '../../__playrepo/play-voice-domain/src/Voice';

import { VCbuyVoicesModal } from '../../pages/VoiceCloning/VCbuyVoicesModal';
import { LegacyInstantVoiceCloningModal } from '../../pages/VoiceCloning/LegacyInstantVoiceCloningModal';
import { HFVoiceCloningModal } from '../../pages/VoiceCloning/HFVoiceCloningModal';
import { generateZustandModal } from '../../__playrepo/ui-components/src/modal/ZustandModal';
import VoiceCloneType from './VoiceCloneType';
import InstantCloneMethod from './InstantCloneMethod';
import HFCloneMethod from './HFCloneMethod';
import RecordingControls from './RecordingControls';
import StartRecording from './StartRecording';
import FinishHFClone from './FinishHFClone';
import FinishInstantClone from './FinishInstantClone';
import InputLanguage from './InputLanguage';

const modalTitle = {
  [STEP.VOICE_CLONE_TYPE]: 'How would you like to clone your voice?',
  [STEP.INSTANT_CLONE_METHOD]: 'Choose Clone Method',
  [STEP.RECORD]: 'Record Voice Clone',
  [STEP.RECORDING]: 'Cloning Voice',
  [STEP.INSTANT_CLONE_FINISH]: 'Finish Your Clone',
  [STEP.HIGH_FIDELITY_CLONE_METHOD]: 'Create High Fidelity Voice Clone',
  [STEP.HIGH_FIDELITY_CLONE_FINISH]: 'Finish Your Clone',
  [STEP.INPUT_LANGUAGE]: 'Define Input Language',
};

export const VoiceCloningModal = generateZustandModal<VoiceId>({
  className: 'rounded-3xl bg-gray-900 border-[0.5px] border-solid border-[rgba(235,232,233,0.25)]',

  children: <CreateVoiceCloneWizard />,
});

function CreateVoiceCloneWizard() {
  const {
    step,
    isRecording,
    onPrevious,
    onRecord,
    onInstant,
    onStartRecording,
    uploadedSampleFile,
    onFileDelete,
    onFilesSelected,
    consent,
    setConsent,
    isGeneratingVoiceCloneFiles,
    handleSendRequest,
    isFinishingVoiceCloning,
    params,
    onNameChange,
    onGenderChange,
    closeModal,
    onStop,
    onRestart,
    recordingTime,
    onHighFidelity,
    audioStream,
    uploadHfFile,
    uploadedHfFiles,
    handleDeleteAudio,
    speakerName,
    setSpeakerName,
    hasMultipleSpeakers,
    setHasMultipleSpeakers,
    setClippings,
    clippings,
    isDisabled,
    ar_epochs,
    diff_epochs,
    submit,
    isSubmitting,
    setArEpochs,
    setDiffEpochs,
    modal,
    getOpenSetter,
    voiceCloningType,
    instantCloningExceeded,
    hfCloningExceeded,
    onDefineLang,
    onChangeLanguage,
  } = useCreateVoiceCloneWizard();

  const [ref, { height }] = useMeasure();

  const content = (
    <div ref={ref} className={clsx('flex flex-col min-h-[300px] p-8 w-auto md:min-w-[480px]')}>
      <div className="relative flex items-center justify-center mb-8 ">
        {step !== STEP.VOICE_CLONE_TYPE && (
          <button type="button" onClick={onPrevious} className="absolute left-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M10.7998 11.9998L14.6998 15.8998C14.8831 16.0831 14.9748 16.3165 14.9748 16.5998C14.9748 16.8831 14.8831 17.1165 14.6998 17.2998C14.5165 17.4831 14.2831 17.5748 13.9998 17.5748C13.7165 17.5748 13.4831 17.4831 13.2998 17.2998L8.6998 12.6998C8.5998 12.5998 8.52897 12.4915 8.4873 12.3748C8.44564 12.2581 8.4248 12.1331 8.4248 11.9998C8.4248 11.8665 8.44564 11.7415 8.4873 11.6248C8.52897 11.5081 8.5998 11.3998 8.6998 11.2998L13.2998 6.6998C13.4831 6.51647 13.7165 6.4248 13.9998 6.4248C14.2831 6.4248 14.5165 6.51647 14.6998 6.6998C14.8831 6.88314 14.9748 7.11647 14.9748 7.3998C14.9748 7.68314 14.8831 7.91647 14.6998 8.0998L10.7998 11.9998Z"
                fill="#E5E7EB"
              />
            </svg>
          </button>
        )}
        <Dialog.Title as="h3" className="text-[20px] leading-[1.4] text-white font-semibold text-center px-6">
          {modalTitle[step]}
        </Dialog.Title>
        <button type="button" onClick={closeModal} className="absolute right-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M12 13.4002L9.10005 16.3002C8.91672 16.4835 8.68338 16.5752 8.40005 16.5752C8.11672 16.5752 7.88338 16.4835 7.70005 16.3002C7.51672 16.1169 7.42505 15.8835 7.42505 15.6002C7.42505 15.3169 7.51672 15.0835 7.70005 14.9002L10.6 12.0002L7.70005 9.1252C7.51672 8.94186 7.42505 8.70853 7.42505 8.4252C7.42505 8.14186 7.51672 7.90853 7.70005 7.7252C7.88338 7.54186 8.11672 7.4502 8.40005 7.4502C8.68338 7.4502 8.91672 7.54186 9.10005 7.7252L12 10.6252L14.875 7.7252C15.0584 7.54186 15.2917 7.4502 15.575 7.4502C15.8584 7.4502 16.0917 7.54186 16.275 7.7252C16.475 7.9252 16.575 8.1627 16.575 8.4377C16.575 8.7127 16.475 8.94186 16.275 9.1252L13.375 12.0002L16.275 14.9002C16.4584 15.0835 16.55 15.3169 16.55 15.6002C16.55 15.8835 16.4584 16.1169 16.275 16.3002C16.075 16.5002 15.8375 16.6002 15.5625 16.6002C15.2875 16.6002 15.0584 16.5002 14.875 16.3002L12 13.4002Z"
              fill="#E5E7EB"
            />
          </svg>
        </button>
      </div>

      {step === STEP.VOICE_CLONE_TYPE && (
        <VoiceCloneType
          instantCloningExceeded={instantCloningExceeded}
          onHighFidelity={onHighFidelity}
          onInstant={onInstant}
          hfCloningExceeded={hfCloningExceeded}
        />
      )}
      {step === STEP.INPUT_LANGUAGE && (
        <InputLanguage onClick={onDefineLang} language={params.language} setLanguage={onChangeLanguage} />
      )}
      {step === STEP.INSTANT_CLONE_METHOD && (
        <InstantCloneMethod onFilesSelected={onFilesSelected} onRecord={onRecord} />
      )}
      {step === STEP.HIGH_FIDELITY_CLONE_METHOD && <HFCloneMethod uploadHfFile={uploadHfFile} />}
      {step === STEP.RECORD && <StartRecording isRecording={isRecording} onStartRecording={onStartRecording} />}
      {step === STEP.RECORDING && (
        <RecordingControls
          audioStream={audioStream}
          recordingTime={recordingTime}
          onRestart={onRestart}
          onStop={onStop}
        />
      )}
      {step === STEP.HIGH_FIDELITY_CLONE_FINISH && (
        <FinishHFClone
          speakerName={speakerName}
          setSpeakerName={setSpeakerName}
          ar_epochs={ar_epochs}
          setArEpochs={setArEpochs}
          diff_epochs={diff_epochs}
          setDiffEpochs={setDiffEpochs}
          uploadedHfFiles={uploadedHfFiles}
          handleDeleteAudio={handleDeleteAudio}
          hasMultipleSpeakers={hasMultipleSpeakers}
          setHasMultipleSpeakers={setHasMultipleSpeakers}
          setClippings={setClippings}
          consent={consent}
          setConsent={setConsent}
          isDisabled={isDisabled}
          isSubmitting={isSubmitting}
          clippings={clippings}
          submit={submit}
        />
      )}
      {step === STEP.INSTANT_CLONE_FINISH && (
        <FinishInstantClone
          params={params}
          onNameChange={onNameChange}
          uploadedSampleFile={uploadedSampleFile}
          onFileDelete={onFileDelete}
          consent={consent}
          setConsent={setConsent}
          isGeneratingVoiceCloneFiles={isGeneratingVoiceCloneFiles}
          isFinishingVoiceCloning={isFinishingVoiceCloning}
          handleSendRequest={handleSendRequest}
          onGenderChange={onGenderChange}
        />
      )}
    </div>
  );

  return (
    <>
      <motion.div
        initial={{ width: 658 }}
        animate={{ height, width: 'auto', maxWidth: step === STEP.VOICE_CLONE_TYPE ? 658 : 480 }}
      >
        {content}
      </motion.div>
      <VCbuyVoicesModal open={modal === 'upgrade'} setOpen={getOpenSetter('upgrade')} type={voiceCloningType} />
      <LegacyInstantVoiceCloningModal open={modal === 'instant'} setOpen={getOpenSetter('instant')} />
      <HFVoiceCloningModal open={modal === 'hf'} setOpen={getOpenSetter('hf')} />
    </>
  );
}
