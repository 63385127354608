import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

export const NotFoundPage = () => (
  <>
    <h1 className="text-4xl font-bold">Page not found</h1>
    <QuestionMarkCircleIcon className="h-12 w-12 pb-4 text-error" />
    <div>It seems that the page you were trying to access does not exist or may have been moved.</div>
    <div>Please check the URL for any typos or use the link below to navigate back to our app.</div>
  </>
);
