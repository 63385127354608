import { ReactNode } from 'react';
import { clsx } from 'clsx';

export function OldButton({
  children,
  onClick,
  disabled,
  loading,
  className,
}: {
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
}) {
  return (
    <button
      type="submit"
      className={clsx('flex items-center justify-center gap-2 rounded-md px-3 py-1.5 text-xs leading-6', className)}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {children}
      {loading && renderLoading()}
    </button>
  );
}

function renderLoading() {
  return (
    <div className="ml-1">
      <svg
        className="-ml-1 mr-3 h-5 w-5 animate-spin text-neutral-50"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );
}
