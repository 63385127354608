import { Dialog } from '@headlessui/react';
import { Modal } from '../../components/Modal.tsx';
import { Button } from '../../components/Button.tsx';
import { Dispatch, SetStateAction } from 'react';
import { useDeleteClonedVoice } from '../../hooks/voiceCloning.hooks.ts';

export function DeleteVoiceCloneModal({
  visible,
  setVisible,
  voiceId,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  voiceId: string;
}) {
  const { isLoading: isDeleting, mutate: deleteVoice } = useDeleteClonedVoice(() => setVisible(false));
  return (
    <Modal maxWidth="400px" open={visible} setOpen={setVisible}>
      <div>
        <div className="mt-3 text-center tablet:ml-4 tablet:mt-0 tablet:text-left">
          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-neutral-900 dark:text-neutral-50">
            Delete Voice
          </Dialog.Title>
          <div className="mt-2">
            <p>This action will REMOVE your existing voice. Are you sure you want to proceed?</p>
          </div>
        </div>
        <div className="mt-5 flex justify-end gap-3">
          <Button format="outlined" onClick={() => setVisible(false)}>
            Cancel
          </Button>
          <Button intent="error" loading={isDeleting} onClick={() => deleteVoice(voiceId)}>
            Remove
          </Button>
        </div>
      </div>
    </Modal>
  );
}
