import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Drawer as VaulDrawer } from 'vaul';

export function Drawer({
  title,
  children,
  trigger,
  open,
  setOpen,
}: {
  trigger: ReactNode;
  title: string;
  children: ReactNode;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <VaulDrawer.Root open={open} onClose={() => setOpen(false)}>
      <VaulDrawer.Trigger asChild onClick={() => setOpen(true)}>
        {trigger}
      </VaulDrawer.Trigger>
      <VaulDrawer.Portal>
        <VaulDrawer.Overlay className="fixed z-[999] inset-0 bg-[rgba(21,21,21,0.50)] backdrop-blur-[2.5px]" />
        <VaulDrawer.Content className="bg-gray-900 z-[9999]  flex flex-col items-center rounded-t-[24px] mt-24 fixed bottom-0 left-0 right-0 shadow-[0px_1px_0px_0px_rgba(255,255,255,0.10)] max-h-[96%]">
          <div className="h-full pt-[6px] pb-4 bg-gray-900 rounded-t-[24px] flex-1 flex flex-col overflow-hidden">
            <div className="mx-auto w-8 h-1 flex-shrink-0 rounded-full bg-white/20 mb-[18px]" />
            <div className="px-4 inline-flex">
              <VaulDrawer.Title className="font-semibold	mx-auto text-center px-2 py-1 bg-black rounded-[160px] text-white text-[16px] leading-6">
                {title}
              </VaulDrawer.Title>
            </div>
            <div className="overflow-auto px-4 ">{children}</div>
            <div className="px-4 flex">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="flex-1 border border-gray-700 bg-gray-800 text-white rounded-2xl px-6 py-4 leading-none text-[16px] whitespace-nowrap mt-[18px] font-semibold	"
              >
                Cancel
              </button>
            </div>
          </div>
        </VaulDrawer.Content>
      </VaulDrawer.Portal>
    </VaulDrawer.Root>
  );
}
