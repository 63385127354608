import React, { useEffect, useState } from 'react';
// import { CheckIcon } from '@heroicons/react/outline';
import { CheckIcon } from '@heroicons/react/20/solid/index.js';
import { Dialog } from '@headlessui/react';

import Modal from './Modal';

import { postFeedback } from '../API/audio.requests';
import { useNotifications } from '../../../__playrepo/ui-components/src/NotificationSnackbar.tsx';
import { useAuth } from '../../../contexts/auth.context';

function FeedbackModal({ open, setOpen, projectId, editorPreset }) {
  const [feedbackIsSent, setFeedbackIsSent] = useState(false);
  const [text, setText] = useState('');

  const { currentUser: user } = useAuth();
  const { setNotification } = useNotifications();

  useEffect(() => {
    if (open) {
      // clear text to allow user to send feedback again
      setText('');
      setFeedbackIsSent(false);
    }
  }, [open]);

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      await postFeedback({
        userId: user.uid,
        email: user.email,
        projectId,
        editorPreset,
        feedback: text,
      });
      setFeedbackIsSent(true);
    } catch (e) {
      console.error(e);
      setNotification({
        type: 'error',
        message: 'Something went wrong. Please try again later.',
      });
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      {feedbackIsSent ? (
        <div>
          <div>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 ">
              <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center tablet:mt-5">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-neutral-900 dark:text-neutral-50">
                Feedback sent successfully
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-neutral dark:text-neutral-50 ">
                  Thank you for taking the time! We are continuously improving our platform, stay tuned :)
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 tablet:mt-6">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-neutral-600 px-4 py-2 text-base font-medium text-neutral-50 shadow-sm hover:bg-neutral-700 focus:outline-none focus:ring-2 focus:ring-neutral focus:ring-offset-2 tablet:text-sm"
              onClick={() => setOpen(false)}
            >
              Go back to studio
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <div className="mt-3 text-center tablet:mt-5">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-neutral-900 dark:text-neutral-50">
                Please tell us how we can improve
              </Dialog.Title>
              <div className="mt-4">
                <textarea
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  className="h-32 w-full resize-none rounded-md border border-neutral-300 bg-neutral-50 px-4 py-2 text-neutral-900 placeholder-neutral focus:ring-0 dark:border-neutral-700 dark:bg-neutral-950 dark:text-neutral-50 "
                />
              </div>
            </div>
          </div>
          <div className="mt-5 tablet:mt-6">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-neutral-600 px-4 py-2 text-base font-medium text-neutral-50 shadow-sm hover:bg-neutral-700 focus:outline-none focus:ring-2 focus:ring-neutral focus:ring-offset-2 tablet:text-sm"
              onClick={onSubmit}
              disabled={!text}
            >
              Send feedback
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default FeedbackModal;
