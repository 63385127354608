import { ReactNode, RefAttributes } from 'react';
import { Link as RawLink, LinkProps as RawLinkProps } from 'react-router-dom';

type BaseLinkProps = RawLinkProps & RefAttributes<HTMLAnchorElement>;

export interface LinkProps extends BaseLinkProps {
  children: ReactNode;
}

export function Link({ children, to, ...props }: LinkProps) {
  const isExternalLink = typeof to === 'string' && (to.startsWith('http://') || to.startsWith('https://'));
  if (isExternalLink) {
    return (
      <a {...props} href={to}>
        {children}
      </a>
    );
  }
  return (
    <RawLink {...props} to={to}>
      {children}
    </RawLink>
  );
}
