import { Modal } from './Modal.tsx';
import { useQueryParam } from '../hooks/router.hooks.ts';
import { useEffect, useState } from 'react';
import { Button } from './Button.tsx';
import { UserGroupIcon } from '@heroicons/react/24/outline';

export function WelcomeTeamMemberModal() {
  const [open, setOpen] = useState(false);
  const queryParam = useQueryParam('welcome');

  useEffect(() => {
    if (queryParam === 'team-member') {
      setOpen(true);
    }
  }, [queryParam]);

  return (
    <Modal maxWidth="460px" open={open} setOpen={setOpen}>
      <div className="flex flex-col text-center">
        <div className="flex flex-col items-center justify-center p-12 pb-24 max-tablet:p-4">
          <UserGroupIcon className="mb-6 h-16" />
          <h1 className="mb-2 text-xl font-semibold">Welcome To Your Team</h1>
        </div>
        <Button intent="primary" onClick={() => setOpen(false)}>
          Continue
        </Button>
      </div>
    </Modal>
  );
}
