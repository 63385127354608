import { LanguagesIcon } from './Icons/LanguagesIcon.tsx';
import { Modal } from './Modal.tsx';
import { Dispatch, SetStateAction } from 'react';
import { Button } from './Button.tsx';
import { CONFIG } from '../config.ts';

export function LegacyDashModal({ open, setOpen }: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>> }) {
  return (
    <Modal maxWidth="600px" open={open} setOpen={setOpen}>
      <div className="flex flex-col items-center justify-center gap-4 p-8 text-center text-base max-tablet:p-4">
        <LanguagesIcon className="mb-2 h-12 w-12" />
        <h1 className="text-xl font-semibold">Standard Studio</h1>
        <p className="tablet:px-10">
          Switch to the Standard Studio to use Standard and Premium voices in multiple languages.
        </p>
        <p className="pb-4 pt-2">We're working to make voices in other languages available on this studio.</p>
        <a href={CONFIG.playDashboardBaseUrl} target="_blank">
          <Button intent="primary">Open Standard Studio</Button>
        </a>
        <Button onClick={() => setOpen(false)}> Stay in Ultra-Realistic Studio</Button>
      </div>
    </Modal>
  );
}
