export function TeamMembersBlockingUI({ pageName }: { pageName: string }) {
  return (
    <div>
      <h1 className="mb-9 text-xl font-semibold">{pageName}</h1>
      <div className="max-w-5xl">
        <div className="mt-12">
          <p className="text-lg font-semibold text-center">
            You are a team member. Only the team owner can access the {pageName} information.
          </p>
        </div>
      </div>
    </div>
  );
}
