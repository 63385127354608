'use client';

import { createStore } from 'zustand';
import { createBoundedUseStore } from '../../../play-utils/src/zustand/createBoundedUseStore';
import { Modal, type ModalProps } from './Modal';

export interface ZustandModalState<P> {
  visible: boolean;
  callbacks: ZustandModalCallbacks<P>;
  open: (callbacks?: ZustandModalCallbacks<P>) => void;
  close: ZustandModalClose<P>;
}

export interface ZustandModalCallbacks<P = void> {
  onGiveUp?: () => void;
  onComplete?: (payload: P) => void;
}

export interface ZustandModalClose<P> {
  (reason: 'onGiveUp'): void;
  (reason: 'onComplete', payload: P): void;
}

export function generateZustandModal<P = void>(modalProps: Omit<ModalProps, 'visible' | 'closeModal'>) {
  const modalStore = createStore<ZustandModalState<P>>()((set, get) => ({
    visible: false,
    callbacks: {},
    open: (callbacks) => set({ visible: true, callbacks: callbacks ?? {} }),
    // @ts-expect-error: TS complains about payload without knowing reason type, but it doesn't matter here
    close: (reason, payload) => {
      const { callbacks } = get();
      set({ visible: false, callbacks: {} });
      callbacks[reason]?.(payload);
    },
  }));

  const useModalStore = createBoundedUseStore(modalStore);

  function ZustandModal() {
    const visible = useModalStore((state) => state.visible);
    const close = useModalStore((state) => state.close);
    return (
      <Modal {...modalProps} visible={visible} closeModal={() => close('onGiveUp')}>
        {modalProps.children}
      </Modal>
    );
  }

  ZustandModal.store = modalStore;
  ZustandModal.useStore = useModalStore;

  return ZustandModal;
}
