import { Navigate } from 'react-router-dom';
import { errorsTracker } from './infra/errors/errorsTracker.ts';
import { Providers } from './Providers.tsx';
import { BlankLayout } from './layouts/BlankLayout.tsx';
import { LoginPage } from './pages/Auth/LoginPage.tsx';
import { NotFoundPage } from './pages/NotFound/NotFoundPage.tsx';
import { SidebarLayout } from './layouts/SidebarLayout.tsx';
import { KettlePage } from './pages/KettlePage/KettlePage.tsx';
import { FilesListPage } from './pages/File/FilesListPage.tsx';
import { VCListingPage } from './pages/VoiceCloning/VCListingPage.tsx';
import { VCClaimVoicePage } from './pages/VoiceCloning/VCClaimVoicePage.tsx';
import { KettleOpenMostRecentFile } from './pages/KettlePage/KettleOpenMostRecentFile.tsx';
import { PricingPage } from './pages/Pricing/PricingPage.tsx';
import { CheckoutPage } from './pages/Checkout/CheckoutPage.tsx';
import { BillingPage } from './pages/Billing/BillingPage.tsx';
import { ApiAccessPage } from './pages/API/ApiAccessPage.tsx';
import { ErrorLayout } from './layouts/ErrorLayout.tsx';
import { TeamAccessPage } from './pages/TeamAccess/TeamAccessPage.tsx';

export const router = errorsTracker.createRouterWithErrorHandler([
  {
    path: '/',
    element: <Navigate to="/studio" replace />,
    index: true,
  },
  {
    path: '/studio',
    element: <Providers />,
    children: [
      {
        element: <BlankLayout />,
        children: [{ path: 'auth/login', element: <LoginPage /> }],
      },
      {
        element: <ErrorLayout />,
        children: [
          { path: '404', element: <NotFoundPage /> },
          { path: '*', element: <NotFoundPage /> },
        ],
      },
      {
        element: <SidebarLayout contentPadded />,
        children: [
          { index: true, element: <KettleOpenMostRecentFile /> },
          { path: 'files', element: <FilesListPage /> },
          { path: 'voice-cloning', element: <VCListingPage /> },
          { path: 'voice-cloning/claim-voice/:voiceHash', element: <VCClaimVoicePage /> },
          { path: 'api-access', element: <ApiAccessPage /> },
          { path: 'pricing', element: <PricingPage /> },
          { path: 'checkout', element: <CheckoutPage /> },
          { path: 'billing', element: <BillingPage /> },
          { path: 'billing/:tab', element: <BillingPage /> },
          { path: 'team-access', element: <TeamAccessPage /> },
        ],
      },
      {
        element: <SidebarLayout />,
        children: [{ path: 'files/:id', element: <KettlePage /> }],
      },
    ],
  },
]);
