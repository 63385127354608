import { useState } from 'react';
import { User, useUser } from '../../contexts/user.context';
import { Invite } from '../../domain/teams/Invite';
import { Pagination, postsPerPage } from '../../components/Pagination';
import { CheckIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { useCancelPendingInvite, useRemoveTeamMember } from '../../hooks/teams.hooks';
import { Modal } from '../../components/Modal';
import { Button } from '../../components/Button';
import { MinusCircleIcon } from '@heroicons/react/20/solid';
import { NOTIFICATION_TYPE_ERROR, useNotifications } from '../../__playrepo/ui-components/src/NotificationSnackbar';

export function TeamInvitesTable({ teamInvites, user }: { teamInvites: Invite[]; user: User }) {
  const [currentPage, setCurrentPage] = useState(1);

  const totalRows = teamInvites.length;
  const indexOfLastRow = currentPage * postsPerPage;
  const indexOfFirstRow = indexOfLastRow - postsPerPage;
  const currentRows = teamInvites.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <div className="mt-4 max-w-3xl">
      <InviteRow email={user.email + ' (You)'} isAdmin={true} />
      {currentRows.map((invite) => (
        <div key={invite.inviteId}>
          <InviteRow email={invite.memberUserEmail} inviteAccepted={invite.inviteAccepted} inviteId={invite.inviteId} />
        </div>
      ))}
      <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalRows={totalRows} />
    </div>
  );
}

const InviteRow = ({
  email,
  isAdmin,
  inviteAccepted,
  inviteId,
}: {
  email: string;
  isAdmin?: boolean;
  inviteAccepted?: boolean;
  inviteId?: string;
}) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-4 my-4">
        <UserCircleIcon className="w-8 h-8" />
        <div className="flex flex-col">
          <p className="text-xs font-normal ">
            <span>{email}</span>
            {isAdmin && <span className="ml-2 text-xs bg-primary rounded-md p-1 font-semibold">ADMIN</span>}
          </p>
        </div>
      </div>
      {!isAdmin && (
        <div className="flex gap-8 font-normal text-xs">
          <p className="flex gap-2 items-center">
            {!inviteAccepted && (
              <>
                <CheckIcon className="w-4 h-4 text-green-500" />
                <span>Invite Sent</span>
              </>
            )}
          </p>
          <RemoveButton inviteAccepted={inviteAccepted} inviteId={inviteId} email={email} />
        </div>
      )}
    </div>
  );
};

const RemoveButton = ({
  inviteId,
  inviteAccepted,
  email,
}: {
  inviteId?: string;
  inviteAccepted?: boolean;
  email: string;
}) => {
  const { user } = useUser();
  const { setNotification } = useNotifications();

  const teamId = user?.adminOfTeams ? user.adminOfTeams[0] : null;
  const { mutate: cancelInvite, isLoading: canceleInviteIsLoading } = useCancelPendingInvite();
  const { mutate: removeMember, isLoading: removeMemberIsLoading } = useRemoveTeamMember();

  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <button
        className="text-red-600 cursor-pointer"
        disabled={canceleInviteIsLoading || removeMemberIsLoading}
        onClick={() => setModalOpen(true)}
      >
        Remove
      </button>
      <Modal maxWidth="500px" open={modalOpen} setOpen={setModalOpen}>
        <div className="p-2 pt-16">
          <div className="mt-2 flex flex-col items-center gap-4 text-center mb-8">
            <MinusCircleIcon className="w-20 h-20 text-white mb-4" />
            <p className="text-xl font-semibold flex flex-col">
              <span>Are you sure you want to remove</span>
              <span className="text-neutral-500">“{email}”</span>
              <span>from the team?</span>
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center gap-3 px-4 py-3">
          <Button
            intent="error"
            onClick={() => {
              if (!teamId) {
                setNotification({
                  message: 'Team not found please contact support',
                  status: NOTIFICATION_TYPE_ERROR,
                });
                return;
              }
              if (inviteId) {
                if (!inviteAccepted) {
                  // handle remove a pending invite
                  cancelInvite({ teamId, inviteId });
                } else {
                  // handle remove a member
                  removeMember({ teamId, inviteId });
                }
              }
            }}
            loading={canceleInviteIsLoading || removeMemberIsLoading}
          >
            {!inviteAccepted ? 'Cancel invite' : 'Remove User'}
          </Button>
          <button onClick={() => setModalOpen(false)}>Cancel</button>
        </div>
      </Modal>
    </>
  );
};
