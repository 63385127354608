export function PlayIcon() {
  return (
    <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="21.5" cy="21" r="21" fill="#01DA72" fill-opacity="0.1" />
      <path
        d="M29.5682 21.676L18.8307 27.9063C17.9194 28.4345 16.75 27.7949 16.75 26.7301V14.2696C16.75 13.2065 17.9177 12.5652 18.8307 13.0951L29.5682 19.3254C29.7755 19.4437 29.9478 19.6147 30.0677 19.8212C30.1875 20.0276 30.2507 20.262 30.2507 20.5007C30.2507 20.7394 30.1875 20.9738 30.0677 21.1802C29.9478 21.3867 29.7755 21.5577 29.5682 21.676Z"
        fill="#01DA72"
      />
    </svg>
  );
}
