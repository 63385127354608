import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchApiSecretKey, generateApiSecretKey, revokeApiSecretKey } from '../API/api.requests.ts';
import { useAuth } from '../contexts/auth.context.tsx';

const API_SECRET_KEY_QUERY_KEY = 'apiSecretKey';

export function useApiSecretKey() {
  const { currentUser } = useAuth();
  return useQuery([API_SECRET_KEY_QUERY_KEY, currentUser?.uid], () => fetchApiSecretKey(currentUser?.uid ?? ''));
}

export function useGenerateApiSecretKey() {
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  return useMutation({
    mutationFn: async () => {
      const idToken = await currentUser?.getIdToken();
      return generateApiSecretKey(idToken ?? '');
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [API_SECRET_KEY_QUERY_KEY] }),
  });
}

export function useRevokeApiSecretKey(onSettled: () => void = () => ({})) {
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  return useMutation({
    mutationFn: async () => {
      const idToken = await currentUser?.getIdToken();
      return revokeApiSecretKey(idToken ?? '');
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [API_SECRET_KEY_QUERY_KEY] }),
    onSettled,
  });
}
