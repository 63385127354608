import { FC, useState } from 'react';
import { Announcement } from './announcements';
import { clsx } from 'clsx';
import { Button } from '../Button.tsx';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

export const AnnouncementsJournalSteps: FC<{
  announcements: Array<Announcement>;
  onNext?: (currentStep: number) => void;
  onFinish?: () => void;
}> = ({ announcements, onNext, onFinish }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [visible, setVisible] = useState(true);
  const announcement = announcements[currentStep - 1];

  const changeStep = (newStep: number) => {
    setVisible(false);
    setTimeout(() => {
      setCurrentStep(newStep);
      setVisible(true);
    }, 100); // The time should match transition duration
  };

  const goBack = () => changeStep(currentStep - 1);
  const goNext = () => {
    if (currentStep === announcements.length) {
      onFinish?.();
    } else {
      changeStep(currentStep + 1);
    }
    onNext?.(currentStep);
  };

  const opacityClass = visible ? 'opacity-100' : 'opacity-0';

  return (
    <div className="flex flex-col gap-8 px-5 py-3 max-w-[40rem] text-center">
      <h3 className="font-semibold text-lg">What's New?</h3>
      <div className={`flex flex-col gap-8 transition-opacity duration-100 ${opacityClass}`}>
        <img src={announcement.image} alt={announcement.id} />
        <div className="text-base px-3">
          <p className="font-semibold text-base text-white mb-3 flex justify-center items-center">
            <CheckCircleIcon className="h-6 w-6 mr-2 text-primary-green-600" />
            <span>{announcement.title}</span>
          </p>
          <p>{announcement.desc}</p>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <button
          className={clsx('font-semibold text-sm w-[6rem] text-left', {
            'opacity-0': currentStep === 1,
          })}
          onClick={goBack}
          disabled={currentStep === 1}
        >
          Previous
        </button>
        <div
          className={clsx('text-sm dark:text-gray-500', {
            'opacity-0': currentStep === 1,
          })}
        >
          {currentStep}/{announcements.length}
        </div>
        <div className="w-[6rem]">
          <Button intent="primary" onClick={goNext} width="full" size="small">
            {currentStep === announcements.length ? 'Close' : 'Next'}
          </Button>
        </div>
      </div>
    </div>
  );
};
