import { useEffect, useRef } from 'react';

export function usePreventUnload(callback) {
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const onBeforeUnload = (e) => {
      const shouldPreventUnload = callbackRef.current();
      if (shouldPreventUnload) {
        e.preventDefault();
        e.returnValue = '';
      }
      return shouldPreventUnload;
    };
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, []);
}
