import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { OnboardingStepOption, onboardingSteps } from '../constants/onboarding.constants';
import { saveOnboardingAnswers } from '../API/user.requests';
import { useAuth } from '../contexts/auth.context';
import { StepsIndicator } from './StepsIndicator.tsx';
import { PlayhtLogo } from './PlayhtLogo.tsx';
import { Modal } from './Modal.tsx';
import { Button } from './Button.tsx';
import { TextField } from './TextField.tsx';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Spinner } from './Spinner.tsx';
import { clsx } from 'clsx';

export function OnboardingModal({ open, setOpen }: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>> }) {
  const auth = useAuth();
  const [answers, setAnswers] = useState<string[]>([]);
  const [customAnswer, setCustomAnswer] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const currentQuestion = onboardingSteps[answers.length];
  const answerOptions = currentQuestion?.options?.filter(
    (option) => !(option.showIf && option.showIf !== answers[answers.length - 1])
  );

  const onClickNext = (answer: string) => {
    setLoading(true);
    // if answer is 'api' add it twice to answers so that it will be the answer for both type and use case questions
    if (answer === 'api') {
      setAnswers([...answers, answer, answer]);
    } else {
      setAnswers([...answers, answer]);
    }
    setCustomAnswer(null);
    setLoading(false);
  };

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      if (!auth.currentUser) return;
      const idToken = await auth.currentUser?.getIdToken();
      const onboardingAnswers = onboardingSteps.map((question, index) => [question.id, answers[index]]);
      await saveOnboardingAnswers(idToken, onboardingAnswers);
      // wait for 500 ms so that firebase can update the user's custom claims
      await new Promise((resolve) => setTimeout(resolve, 500));

      setOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [auth.currentUser, setOpen, answers]);

  useEffect(() => {
    if (answers.length === onboardingSteps.length) {
      handleSubmit();
    }
  }, [answers, handleSubmit]);

  return (
    <Modal persistent open={open} setOpen={setOpen} className="p-0 max-tablet:p-0" maxWidth="800px">
      <div>
        <div className="flex items-center justify-between gap-10 bg-neutral-900/75 px-10 py-7 max-tablet:flex-col-reverse max-tablet:gap-4 max-tablet:p-4 max-tablet:text-center">
          <div>
            <p className="mb-1.5 text-xl font-semibold max-tablet:text-lg">How do you plan to use PlayHT?</p>
            <p>We will use this information to personalize your experience</p>
          </div>
          <div className="w-fit">
            <PlayhtLogo className="w-[10rem] py-4 max-tablet:w-[8rem]" />
          </div>
        </div>
        <div className="p-10 max-tablet:p-4">
          <div>
            <p className="mb-6 text-base max-tablet:text-center">{currentQuestion && currentQuestion.question}</p>
          </div>
          {renderCommonStep(answerOptions, onClickNext)}
          {answers[answers.length - 1] === 'other' && renderCustomStep(customAnswer, setCustomAnswer, onClickNext)}
          <div className="mt-8 flex flex-col items-center gap-5">
            {loading && answers.length === onboardingSteps.length && (
              <div className="mb-8 space-y-4 text-center">
                <Spinner label="Saving..." />
              </div>
            )}
            {currentQuestion && answers.length > 0 && (
              <Button
                format="outlined"
                onClick={() => {
                  setAnswers(answers.slice(0, -1));
                  setCustomAnswer(null);
                }}
              >
                <ChevronLeftIcon className="relative -left-1 h-5" />
                Back
              </Button>
            )}
            {!loading && (
              <div className="mt-5">
                <StepsIndicator totalSteps={onboardingSteps.length} currentStep={answers.length + 1} />
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

function renderCommonStep(answerOptions: OnboardingStepOption[], onClickAnswer: (answerId: string) => void) {
  return (
    <div className="grid grid-cols-2 gap-5 max-tablet:grid-cols-1">
      {answerOptions?.map((option) => {
        return (
          <div
            key={option.id}
            className={clsx(
              'flex flex-grow cursor-pointer items-center justify-between rounded-xl bg-neutral-900 p-6 max-tablet:p-4'
            )}
            onClick={() => onClickAnswer(option.id)}
          >
            <div>{option.title}</div>
            <ChevronRightIcon className="h-5" />
          </div>
        );
      })}
    </div>
  );
}

function renderCustomStep(
  customAnswer: string | null,
  setCustomAnswer: (answer: string) => void,
  onClickContinue: (option: string) => void
) {
  return (
    <div>
      <div className="flex items-center gap-6 max-tablet:flex-col">
        <div className="w-full flex-grow">
          <TextField
            name="customAnswer"
            value={customAnswer ?? ''}
            placeholder="Enter your use case"
            onChange={setCustomAnswer}
            width="full"
          />
        </div>
        <Button intent="primary" onClick={() => onClickContinue(customAnswer ?? '')} disabled={!customAnswer}>
          Continue
        </Button>
      </div>
    </div>
  );
}
