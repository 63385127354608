import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

import clsx from 'clsx';

const defaultTooltipIcon = <InformationCircleIcon className="h-5" />;

const TooltipComponent = ({
  tooltipWrapperClassName = '',
  toolTipHeaderText = '',
  customTooltipIcon = defaultTooltipIcon,
}) => {
  return (
    <div className={clsx(tooltipWrapperClassName)}>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip
            id="tooltip-top"
            className="max-w-[240px] bg-dark-100 px-3 py-2 rounded-md text-white-100 text-center text-sm bg-black"
          >
            {toolTipHeaderText}
          </Tooltip>
        }
      >
        <div color="primary" className="mt-[.2rem] w-full cursor-pointer">
          {customTooltipIcon}
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default TooltipComponent;
