import axios from 'axios';
import { CONFIG } from '../config.ts';
import { ref, update } from 'firebase/database';
import { database } from '../utils/firebase.ts';

export const saveUserToRealtimeDatabase = async (
  userData: {
    id: string;
    email: string | null;
    name: string | null;
    imageUrl: string | null;
    refreshToken: string;
    providerId: string;
    source: string;
  },
  token: string
) => {
  const url = `${CONFIG.playAPIBaseUrl}/saveUserProfile`;
  return axios.post(url, { userObj: userData, token });
};

export const saveOnboardingAnswers = async (token: string, data: Array<Array<string>>) => {
  const url = `${CONFIG.playAPIBaseUrl}/saveOnboardForm`;
  return axios.post(url, { token, data });
};

export async function updateLastNewsSeenDate(userId: string, date: Date) {
  const nodeRef = ref(database, `users/${userId}/singleViewItems`);
  return update(nodeRef, { lastNewsSeenDate: date.toISOString() });
}
