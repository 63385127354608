import { useId } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { UserAgentUtils } from '../../utils/UserAgentUtils';

/**
 * Adds a tooltip to its children. Notice that it wraps them into a <div>. If you
 * don't want that, use `<WithUnwrappedTooltip />`.
 *
 * If your tooltip is showing below the element, and you are using an arrow (that is, you aren't using
 * the `noArrow` prop), make sure you add the `arrowTop` prop for proper styling of the tooltip arrow.
 *
 * For more docs and advanced options, see https://react-tooltip.com/docs/options
 */
export const WithTooltip: React.FC<
  {
    children: React.ReactNode;
    className?: string;
    tooltipChildren?: React.ReactElement;
    tooltipClassName?: string;
    arrowTop?: boolean;
  } & Exclude<React.ComponentProps<typeof ReactTooltip>, 'anchorId'>
> = ({ children, className, tooltipChildren, tooltipClassName = '', arrowTop, ...p }) => {
  const tooltipId = useId();
  const id = useId();

  if (UserAgentUtils.isOnTouchDevice()) return <div className={className}>{children}</div>; // don't show tooltips on touch devices
  return (
    <>
      <div data-tooltip-id={id} aria-describedby={tooltipId} className={className}>
        {children}
      </div>
      {createPortal(
        <ReactTooltip
          style={{ zIndex: 99999 }}
          id={id}
          className={tooltipClassName + (arrowTop ? ' arrow-top' : '') + ' kt-max-w-[520px]'}
          disableStyleInjection="core"
          {...p}
        >
          {tooltipChildren}
        </ReactTooltip>,
        document.body
      )}
    </>
  );
};

/**
 * Adds a tooltip to its children. It is called 'unwrapped' because it doesn't wrap its children with
 * a <div>, like `<WithTooltip>` does. Favor using `<WithTooltip>` if you can.
 */
export const WithUnwrappedTooltip = ({
  anchor,
  ...p
}: { anchor: (anchorId: string) => React.ReactNode } & Exclude<
  React.ComponentProps<typeof ReactTooltip>,
  'anchorId'
>) => {
  const anchorId = useId();

  if (UserAgentUtils.isOnTouchDevice()) return <div>{anchor(anchorId)}</div>; // don't show tooltips on touch devices
  return (
    <>
      {anchor(anchorId)}
      {createPortal(<ReactTooltip disableStyleInjection="core" anchorId={anchorId} {...p} />, document.body)}
    </>
  );
};
