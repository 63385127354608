import { ErrorLayoutWrapper } from '../../layouts/ErrorLayout.tsx';
import { BoltSlashIcon } from '@heroicons/react/24/outline';

export const ErrorPage = () => (
  <ErrorLayoutWrapper>
    <BoltSlashIcon className="h-12 w-12 text-error" />
    <div>Uh-oh! It appears that an error has occurred on our app.</div>
    <div>
      Our team has ben alerted. In the meantime, <span className="font-bold">please try refreshing the page</span>, as
      that may resolve the problem.
    </div>
    <div>If the issue persists, please don't hesitate to reach out to our support team.</div>
  </ErrorLayoutWrapper>
);
