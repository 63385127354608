import { useState, useEffect } from 'react';

import { getAll } from '../API/voices.requests';
import { useAuth } from '../../../contexts/auth.context';

type Maybe<T> = T | null;

export enum Gender {
  Male = 'male',
  Female = 'female',
}

export interface VoiceType {
  name: string;
  value: string;
  gender?: Maybe<Gender>;
  sample?: Maybe<string>;
  language?: Maybe<string>;
  languageCode?: Maybe<string>;
  isCloned?: boolean;
  isPopular?: boolean;
  isNew?: boolean;
  isExperimental?: boolean;
  accent?: Maybe<string>;
  age?: Maybe<string>;
  tempo?: Maybe<string>;
  loudness?: Maybe<string>;
  texture?: Maybe<string>;
  style?: Maybe<string>;
  engine?: Maybe<'peregrine' | 'parrot'>;
}

export function useVoices() {
  const [currentRefreshVoices, setrefreshVoices] = useState(0);
  const { currentUser: user } = useAuth();
  const [data, setData] = useState<Array<VoiceType>>([]);
  const [error, setError] = useState<unknown>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(null);

        const token = await user?.getIdToken();
        const result = await getAll(token);

        setData(result.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [user, currentRefreshVoices]);

  return {
    data,
    error,
    loading,
    refreshVoices: () => {
      setrefreshVoices(currentRefreshVoices + 1);
    },
  };
}

export default useVoices;
