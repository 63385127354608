import { fetchBillingInfo, fetchInvoices, fetchPaymentMethods } from '../API/billing.requests.ts';
import { useEffect, useMemo, useState } from 'react';
import { PaymentMethod } from '../domain/billing/PaymentMethod.ts';
import { BillingInfo } from '../domain/billing/BillingInfo.ts';
import { Invoice } from '../domain/billing/Invoice.ts';
import { useAuth } from '../contexts/auth.context.tsx';
import { AddOn, Package, Plan } from '../domain/billing/Plan.ts';
import { useUser } from '../contexts/user.context.tsx';

// TODO: add react-query or tRPC for async control

export function usePlans(): Plan[] {
  const { user } = useUser();
  const packages: Record<string, Package> | undefined = user?.packages;
  const addOns: Record<string, AddOn> | undefined = user?.add_ons;

  return useMemo(() => {
    return [
      ...(Object.values(packages ?? {})
        .map((p: Package) => Package.safeParse(p))
        .map((p) => (p.success && p.data ? p.data : null))
        .filter((p) => Boolean(p)) as Package[]),
      ...(Object.values(addOns ?? {})
        .map((p: AddOn) => AddOn.safeParse(p))
        .map((p) => (p.success ? p.data : null))
        .filter((p) => p !== null) as AddOn[]),
    ].sort((a, b) => (a.created_at === b.created_at ? 0 : a.created_at > b.created_at ? -1 : 1));
  }, [packages, addOns]);
}

// modificationCount is used to force a re-render when a payment method is added/removed
export function usePaymentMethods(modificationCount: number | null = null) {
  const { currentUser } = useAuth();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[] | null>(null);
  const [defaultPaymentId, setDefaultPaymentId] = useState<string | null>(null);

  useEffect(() => {
    const fetch = async () => {
      const idToken = await currentUser?.getIdToken();
      if (!idToken) {
        setPaymentMethods(null);
        setDefaultPaymentId(null);
      } else {
        try {
          const response = await fetchPaymentMethods(idToken);
          setPaymentMethods(response.data.paymentMethods);
          setDefaultPaymentId(response.data.defaultPaymentId);
        } catch (e) {
          setPaymentMethods([]);
          setDefaultPaymentId(null);
        }
      }
    };
    fetch();
  }, [currentUser, modificationCount]);

  return { paymentMethods, defaultPaymentId };
}

export function useBillingInfo() {
  const { currentUser } = useAuth();
  const [billingInfo, setBillingInfo] = useState<BillingInfo | null>(null);

  useEffect(() => {
    const fetch = async () => {
      const idToken = await currentUser?.getIdToken();
      if (!idToken) {
        setBillingInfo(null);
      } else {
        try {
          const response = await fetchBillingInfo(idToken);
          setBillingInfo(response.data.data);
        } catch (e) {
          setBillingInfo({});
        }
      }
    };
    fetch();
  }, [currentUser]);

  return billingInfo;
}

export function useInvoices() {
  const { currentUser } = useAuth();
  const [invoices, setInvoices] = useState<Invoice[] | null>(null);

  useEffect(() => {
    const fetch = async () => {
      const idToken = await currentUser?.getIdToken();
      if (!idToken) {
        setInvoices(null);
      } else {
        try {
          const response = await fetchInvoices(idToken);
          setInvoices(response.data.data);
        } catch (e) {
          setInvoices([]);
        }
      }
    };
    fetch();
  }, [currentUser]);

  return invoices;
}
