import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';

export function Tabs({
  tabs,
  selectedTab,
  setSelectedTab,
}: {
  tabs: { value: string; label: ReactNode; icon: ReactElement; description: string }[];
  selectedTab: { value: string; label: ReactNode };
  setSelectedTab: (value: { value: string; label: string; icon: ReactElement; description: string }) => void;
}) {
  return (
    <RadioGroup
      value={selectedTab}
      onChange={setSelectedTab}
      className="flex items-stretch justify-center max-w-[912px] w-full mb-4  "
    >
      {tabs.map((option) => (
        <section key={option.value} className="flex-grow flex flex-col relative cursor-pointer ">
          <RadioGroup.Option
            key={option.value}
            value={option}
            style={{
              borderRadius: '16px 16px 0px 0px',
            }}
            className={() =>
              clsx(
                'flex flex-col items-start text-start text-black text-lg lg:text-3xl font-semibold sm:gap-3 gap-1 mb-2 dark:text-white',
                selectedTab.value === option.value ? ' opacity-100' : 'opacity-50',
                'hover:opacity-80'
              )
            }
          >
            <h4 className="flex items-center gap-3 pt-1">
              {option.icon}
              <span className="md:text-[54px] text-[24px] leading-[120%] font-semibold">{option.label}</span>
            </h4>
            <span className="md:text-[24px] text-sm font-normal leading-[110%] w-[98%] mb-3">{option.description}</span>
          </RadioGroup.Option>
          <hr
            className={clsx(
              'h-2 border-none w-full absolute bottom-0',
              selectedTab.value === option.value ? 'bg-primary-green-700' : 'bg-warmGray-300'
            )}
          />
        </section>
      ))}
    </RadioGroup>
  );
}
