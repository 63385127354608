import { ReactNode, useState } from 'react';
import { DocumentDuplicateIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { copyTextToClipboard } from '../utils/stringUtils.ts';
import { clsx } from 'clsx';

export interface TextFieldProps {
  name: string;
  type?: 'text' | 'email' | 'password' | 'search';
  label?: string;
  value: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  width?: string;
  leftIcon?: ReactNode;
  required?: boolean;
  disabled?: boolean;
  showOption?: boolean;
  copyOption?: boolean;
  autoComplete?: string;
}

export function TextField({
  name,
  type = 'text',
  label,
  value,
  placeholder = '',
  onChange = () => ({}),
  width = '400px',
  leftIcon,
  autoComplete,
  required = false,
  disabled = false,
  showOption = false,
  copyOption = false,
}: TextFieldProps) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div style={{ width }} className="max-w-full">
      {label && (
        <label htmlFor={name} className="mb-2 block">
          {label}
        </label>
      )}
      <div className="flex rounded-xl">
        <div className="relative flex flex-grow items-stretch focus-within:z-10 ">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">{leftIcon}</div>
          <input
            id={name}
            name={name}
            type={type === 'password' && showPassword ? 'text' : type}
            autoComplete={autoComplete}
            required={required}
            disabled={disabled}
            className={clsx(
              'disabled: block w-full rounded-l-xl border-0 bg-neutral-50/5 px-4 py-3 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-neutral-50/20 placeholder:text-neutral-600 last:rounded-xl focus:ring-2 focus:ring-inset focus:ring-gray-400 dark:focus:ring-neutral-50/30',
              disabled ? 'text-neutral-400' : 'dark:text-neutral-100',
              leftIcon ? 'pl-10' : 'pl-4'
            )}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
          />
          {showOption &&
            renderRightOption(
              showPassword ? <EyeIcon className="h-5 w-5 " /> : <EyeSlashIcon className="h-5 w-5 " />,
              () => setShowPassword(!showPassword)
            )}
          {copyOption &&
            renderRightOption(<DocumentDuplicateIcon className="h-5 w-5 " />, () => copyTextToClipboard(value))}
        </div>
      </div>
    </div>
  );
}

function renderRightOption(rightOption: ReactNode, action?: () => void) {
  return (
    <button
      type="button"
      className="disabled: relative -ml-px inline-flex items-center gap-x-1.5 bg-neutral-50/5 px-3 py-2 font-semibold ring-1 ring-inset ring-neutral-600 last:rounded-r-md hover:bg-neutral-100/10"
      onClick={action}
    >
      {rightOption}
    </button>
  );
}
