import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { Accept, useDropzone } from 'react-dropzone';

export function FileSelector({
  name,
  label,
  uploadFile,
  accept = { 'application/vnd.ms-powerpoint': ['.ppt', '.pptx'] },
  multiple = false,
}: {
  name: string;
  label?: string;
  uploadFile: (files: File[]) => void;
  allowedFileTypes?: string;
  accept?: Accept;
  multiple?: boolean;
}) {
  const { getRootProps, getInputProps } = useDropzone({ onDrop: uploadFile, accept, multiple });
  return (
    <div>
      <div {...getRootProps()}>
        {label && <label className="mb-3 block">{label}</label>}
        <div className="flex h-[80px] cursor-pointer items-center justify-center rounded-xl bg-gray-200 dark:bg-neutral-900 p-6 text-center ring-1 ring-inset ring-gray-300 dark:ring-neutral-800">
          <div className="space-y-1.5">
            <CloudArrowUpIcon className="m-auto h-8 text-primary" />
            <div className="text-xs">Drop file here or browse</div>
          </div>
          <input name={name} type="file" className="sr-only" {...getInputProps()} />
        </div>
      </div>
    </div>
  );
}
