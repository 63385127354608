import { z } from 'zod';
import { zodUtils } from '../../play-utils/src/zodUtils';

export const VOICE_SAMPLE_FILE_MIME_TYPES = {
  'audio/*': ['.aac', '.mp3', '.ogg', '.wav', '.webm', '.flac', '.mp4', '.m4a', '.vnd', '.amr'],
  'video/*': ['.mov'],
};

export const MIN_VOICE_SAMPLE_FILE_SIZE = 5 * 1024; // 5kb minimum
export const MAX_VOICE_SAMPLE_FILE_SIZE = 50 * 1024 * 1024; // 50mb maximum
export const MAX_VOICE_AUDIO_DURATION = 60 * 60; // 1 hour maximum
export const MIN_VOICE_AUDIO_DURATION = 2; // 2 second minimum

export type VoiceId = z.infer<typeof VoiceId>;
export const VoiceId = zodUtils.stringId('VoiceId');

export type VoiceGender = z.infer<typeof VoiceGender>;
export const VoiceGender = z.union([z.literal('male'), z.literal('female')]);

export type VoiceAccent = z.infer<typeof VoiceAccent>;
export const VoiceAccent = z.union([
  z.literal('american'),
  z.literal('british'),
  z.literal('canadian'),
  z.literal('australian'),
]);

export type VoiceLoudness = z.infer<typeof VoiceLoudness>;
export const VoiceLoudness = z.union([z.literal('high'), z.literal('neutral'), z.literal('low'), z.literal('whisper')]);

export type StaticVoice = z.infer<typeof StaticVoice>;
export const StaticVoice = z.object({
  id: VoiceId,
  sample: z.string().url().nullable(), // TODO: shouldn't be nullable
  name: z.string(),
  gender: VoiceGender.nullable(),
  accent: VoiceAccent.nullable(),
  // age: VoiceAge.nullable(), // TODO: add age (?)
  // tempo: VoiceTempo.nullable(), // TODO: add tempo (?)
  loudness: VoiceLoudness.nullable(),
  style: z.string().nullable(),
  texture: z.string().nullable(),
});

export type ClonedVoice = z.infer<typeof ClonedVoice>;
export const ClonedVoice = StaticVoice.extend({
  ownerId: z.string(),
  originalSample: z.string().url(),
  originalSampleFileName: z.string(),
  description: z.string(),
  customAttributes: z.record(z.string()),
});

export type Voice = z.infer<typeof Voice>;
export const Voice = z.union([StaticVoice, ClonedVoice]);

export function isClonedVoice(voice: Voice): voice is ClonedVoice {
  return ClonedVoice.safeParse(voice).success;
}
