import { useState } from 'react';
import { Button } from '../../components/Button';
import { Modal } from '../../components/Modal';
import { Invite } from '../../domain/teams/Invite';
import { useActiveMainPlan } from '../../hooks/plans.hooks';
import { useInviteTeamMember } from '../../hooks/teams.hooks';
import { TextField } from '../../components/TextField';
import { useUser } from '../../contexts/user.context';
import { AxiosError } from 'axios';

const emailRegext = new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$');

export function AddMemberButton({
  teamInvites,
  teamInvitesIsLoading,
}: {
  teamInvites: Array<Invite> | undefined;
  teamInvitesIsLoading: boolean;
}) {
  const { user } = useUser();
  const activeMainPlan = useActiveMainPlan();
  const [inviteMemberModalIsOpen, setInviteMemberModalIsOpen] = useState(false);
  const [memberEmail, setMemberEmail] = useState('');
  const [error, setError] = useState<null | string>(null);
  const { mutate: inviteMember, isLoading: isInvitingMember } = useInviteTeamMember();

  const existingMembersCount = 1 + (teamInvites ? teamInvites.length : 0); // +1 for the owner
  const allowedMembersCount = activeMainPlan?.quantity ?? 0;
  const seatsLeft = allowedMembersCount - existingMembersCount;

  const teamId = user?.adminOfTeams ? user.adminOfTeams[0] : null;

  const handleSendInvite = () => {
    setError(null);

    if (seatsLeft <= 0) {
      setError('You have reached the maximum number of team members for your plan');
      return;
    }

    if (!emailRegext.test(memberEmail)) {
      setError('Please enter a valid email address');
      return;
    }

    if (!teamId) {
      setError('Team not found, please contact support if this error persists');
      return;
    }

    inviteMember(
      {
        teamId,
        memberEmail,
      },
      {
        onSuccess: () => {
          setInviteMemberModalIsOpen(false);
          setMemberEmail('');
        },
        onError: (error) => {
          let message = 'Something went wrong. Please try again later or contact support.';

          const errorData = (error as AxiosError)?.response?.data;
          if (
            errorData &&
            typeof errorData === 'object' &&
            'error' in errorData &&
            typeof errorData.error === 'string'
          ) {
            message = errorData.error;
          }

          setError(message);
        },
      }
    );
  };

  return (
    <div className="flex items-center gap-2">
      <p>
        {existingMembersCount} / {allowedMembersCount} team members added
      </p>
      <Button
        intent="primary"
        size="large"
        loading={teamInvitesIsLoading || isInvitingMember}
        onClick={() => setInviteMemberModalIsOpen(true)}
      >
        + Add member
      </Button>
      <Modal open={inviteMemberModalIsOpen} setOpen={setInviteMemberModalIsOpen}>
        <div className="flex flex-col items-center gap-4 py-4 px-4">
          <p className="font-semibold text-xl mb-4">Invite a Member</p>
          <TextField name="email" placeholder="Email" value={memberEmail} onChange={setMemberEmail} />
          {error && <p className="text-red-500 max-w-xs text-center">{error}</p>}
          <Button
            intent="primary"
            size="large"
            width="full"
            loading={teamInvitesIsLoading || isInvitingMember}
            onClick={() => handleSendInvite()}
          >
            Send Invite
          </Button>
          <button onClick={() => setInviteMemberModalIsOpen(false)}>Cancel</button>
        </div>
      </Modal>
    </div>
  );
}
