import { Button } from './Button.tsx';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

export const postsPerPage = 10;

export function Pagination({
  totalRows,
  currentPage,
  setCurrentPage,
}: {
  totalRows: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}) {
  return (
    <nav className="flex items-center justify-between gap-3" aria-label="Pagination">
      <div className="">
        <p className="text-xs">
          Showing <span className="font-medium">{postsPerPage * (currentPage - 1)}</span> to{' '}
          <span className="font-medium">
            {postsPerPage * currentPage > totalRows ? totalRows : postsPerPage * currentPage}
          </span>{' '}
          of <span className="font-medium">{totalRows}</span> results
        </p>
      </div>

      <div className="flex justify-between gap-3 tablet:justify-end ">
        <Button
          format="outlined"
          size="small"
          disabled={currentPage === 1}
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
            }
          }}
        >
          <ChevronLeftIcon className="h-5 w-5" />
        </Button>
        <Button
          format="outlined"
          size="small"
          disabled={currentPage * postsPerPage >= totalRows}
          onClick={() => {
            if (currentPage * postsPerPage < totalRows) {
              setCurrentPage(currentPage + 1);
            }
          }}
        >
          <ChevronRightIcon className="h-5 w-5" />
        </Button>
      </div>
    </nav>
  );
}
