// TODO: remove mocks
import { useEffect, useState } from 'react';

import { PlusIcon } from '@heroicons/react/24/outline';
import { usePaymentMethods } from '../../hooks/billing.hooks.ts';
import { Spinner } from '../../components/Spinner.tsx';
import { AddNewPaymentMethodModal } from './BillingAddNewPaymentMethodModal.tsx';
import { addPaymentMethod, removePaymentMethod } from '../../API/billing.requests.ts';
import { useAuth } from '../../contexts/auth.context.tsx';
import { PaymentMethod } from '../../domain/billing/PaymentMethod.ts';
import { SimpleStatefulRequestButton } from '../../components/SimpleStatefulRequestButton.tsx';

// Import all SVG files from a directory
const paymentLogosContext = import.meta.glob('/src/assets/paymentLogos/*.svg', { eager: true });

// Transform the imagesContext object into a more usable format
const paymentLogos = Object.fromEntries(
  Object.entries(paymentLogosContext).flatMap(([path, image]) => {
    const importedPath = (image as { default?: string }).default;
    if (!path || !importedPath) return [];

    const fileName = path.split('/').pop();
    if (!fileName) return [];
    const key = fileName.split('.')[0]; // get file name without extension as key

    return [[key, importedPath]];
  })
);

export function BillingPaymentMethodSection() {
  const [modificationCount, setModificationCount] = useState(0); // used to force a re-render when a payment method is added/removed
  const { paymentMethods: newPaymentMethods, defaultPaymentId: newDefaultPaymentId } =
    usePaymentMethods(modificationCount);

  // The state ends up duplicated twice because we want to update payment methods immediately after a modification request
  // e.g. after a user runs "make a default" we want to update the UI immediately, not wait for the next fetch
  //
  // We still fetch the data from the server to make sure we don't get out of sync with the server (in particular, the default payment method)
  // It's probably better to refactor the API to return the updated payment methods after a modification request,
  // but this works for now
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[] | null>([]);
  const [defaultPaymentId, setDefaultPaymentId] = useState<string | null>(null);

  useEffect(() => {
    setPaymentMethods(newPaymentMethods);
    setDefaultPaymentId(newDefaultPaymentId);
  }, [newPaymentMethods, newDefaultPaymentId]);

  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);

  const auth = useAuth();

  if (!paymentMethods) return <Spinner className="pt-4" label="Loading payment methods..." />;

  return (
    <div>
      <div className="flex flex-row flex-wrap gap-6">
        {paymentMethods.length === 0 && (
          <div className="min-w-96 flex h-52 w-96 flex-col justify-center gap-4 rounded-xl p-4 align-middle text-sm dark:bg-neutral-900">
            <span className="text-center text-lg">You have no payment methods</span>
            <button className="w-full rounded-md bg-primary p-3 text-center" onClick={() => setPaymentModalOpen(true)}>
              Add a payment method
            </button>
          </div>
        )}
        {paymentMethods.map((method) => (
          <div
            key={method.id}
            className="flex h-52 w-96 flex-col justify-between gap-4 rounded-xl p-6 text-sm dark:bg-neutral-900"
          >
            <div className="flex items-start justify-between">
              <img
                src={paymentLogos[method.cardInfo.brand]}
                alt={method.cardInfo.brand}
                className="mb-auto w-20 rounded-md object-contain"
              />
              <SimpleStatefulRequestButton
                textNormal="Remove"
                textInProgress="Removing..."
                textRetry="Retry: remove"
                onClick={async () => {
                  const tokenId = await auth.currentUser?.getIdToken();
                  if (!tokenId) throw new Error('No token');

                  await removePaymentMethod(tokenId, method.id);
                  setModificationCount(modificationCount + 1);
                  setPaymentMethods(paymentMethods.filter((m) => m.id !== method.id));
                }}
              />
            </div>
            <div>
              <div className="mb-1.5 text-xl">
                Card ending in <span className="font-semibold">{method.cardInfo.last4}</span>
              </div>
              <div className="flex justify-between">
                <div>{`Expiry ${method.cardInfo.exp_month}/${method.cardInfo.exp_year}`}</div>{' '}
                <div>
                  {method.id === defaultPaymentId ? (
                    <div className="text-success">DEFAULT</div>
                  ) : (
                    <SimpleStatefulRequestButton
                      textNormal="Make default"
                      textInProgress="Please wait..."
                      textRetry="Retry: make default"
                      onClick={async () => {
                        const tokenId = await auth.currentUser?.getIdToken();
                        if (!tokenId) throw new Error('No token');

                        await addPaymentMethod(tokenId, method.id);
                        setModificationCount(modificationCount + 1);
                        setDefaultPaymentId(method.id);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button onClick={() => setPaymentModalOpen(true)} className="mt-8 flex w-fit flex-row items-center">
        <PlusIcon className="mr-2 h-5 w-5" />
        <span className="text-base font-semibold">Add payment method</span>
      </button>
      <AddNewPaymentMethodModal
        visible={isPaymentModalOpen}
        setVisible={setPaymentModalOpen}
        onSuccess={() => {
          console.log("Add payment method modal's onSuccess");
          setPaymentModalOpen(false);
          setModificationCount(modificationCount + 1);
        }}
      />
    </div>
  );
}
