import { FC } from 'react';
import { FilesDropzone } from '../../__playrepo/ui-components/src/FilesDropzone';

type HFCloneMethodProps = {
  uploadHfFile: (files: File[]) => Promise<void>;
};

const HFCloneMethod: FC<HFCloneMethodProps> = ({ uploadHfFile }) => {
  return (
    <div className="flex flex-col px-4 py-1 rounded-2xl border border-white/10 flex-1 justify-center">
      <FilesDropzone
        name="audioUploader"
        accept={{ 'audio/*': [] }}
        multiple
        onFilesSelected={uploadHfFile}
        title="Upload high quality audio sample(s) of 30 minutes or more for best results"
      />
    </div>
  );
};

export default HFCloneMethod;
