import React from 'react';
import { AudioVisualizer } from '../AudioVisualizer';
import { ArrowPathIcon } from '@heroicons/react/24/solid';

interface RecordingControlsProps {
  audioStream: MediaStream | null;
  recordingTime: number;
  onRestart: () => void;
  onStop: () => void;
}

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const RecordingControls: React.FC<RecordingControlsProps> = ({ audioStream, recordingTime, onRestart, onStop }) => {
  return (
    <>
      <AudioVisualizer audioStream={audioStream} />
      <div className="flex flex-col items-center">
        <span className="mb-5 px-5 py-3 text-[14px] leading-[17px] text-white">{formatTime(recordingTime)} / 0:30</span>
        <div className="mx-auto flex w-full justify-center gap-4">
          <button
            className="rounded-2xl flex justify-center items-center bg-gray-700 flex-1 py-2 px-12 h-12"
            onClick={onRestart}
          >
            <ArrowPathIcon className="mr-1 h-4 w-4 text-white" />
            <span className="leading-[17px] text-[14px] text-white">Restart</span>
          </button>
          <button
            className="flex items-center justify-center rounded-2xl bg-primary-green-600 py-2 h-12 px-12 w-6/12"
            onClick={onStop}
          >
            <span className="text-[14px] leading-[17px] text-dark-band-green-700 flex-1">Done</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default RecordingControls;
