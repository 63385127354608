import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/auth.context';
import { SpinnerFullScreen } from './SpinnerFullScreen.tsx';

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (auth.loading) {
    return <SpinnerFullScreen label="Authenticating..." />;
  }

  if (!auth.currentUser) {
    // Redirect them to the /auth/login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/studio/auth/login" state={{ from: location }} replace />;
  }

  return children;
}

export default RequireAuth;
