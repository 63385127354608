import { useUser } from '../../contexts/user.context';
import { TeamMembersBlockingUI } from '../../components/TeamMembersBlockingUI';
import { TeamAccessEmptyState } from './TeamAccessEmptyState';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import { TextField } from '../../components/TextField';
import { useTeamInvites } from '../../hooks/teams.hooks';
import { Spinner } from '../../components/Spinner';
import { TeamInvitesTable } from './TeamInvitesTable';
import { AddMemberButton } from './AddMemberButton';

export function TeamAccessPage() {
  const { user } = useUser();

  const { data: teamInvites, isLoading: teamInvitesIsLoading } = useTeamInvites(
    user?.adminOfTeams ? user.adminOfTeams[0] : ''
  );

  if (user?.isTeamMember) {
    return <TeamMembersBlockingUI pageName="Team Access" />;
  }

  if (!user?.isTeamAdmin) {
    return <TeamAccessEmptyState />;
  }

  return (
    <div className="max-w-7xl">
      <h1 className="mb-8 text-xl font-semibold">
        <UserGroupIcon className="inline-block w-6 h-6 mr-2" />
        Team Access
      </h1>
      <div className="flex flex-col gap-2 lg:flex-row items-center justify-between">
        <TextField name="search-members" placeholder="Search Members" value={''} />
        <AddMemberButton teamInvites={teamInvites} teamInvitesIsLoading={teamInvitesIsLoading} />
      </div>
      <div className="mt-12">
        <p className="text-base font-semibold leading-6">Team Members ({(teamInvites?.length ?? 1) + 1}) </p>
        {teamInvitesIsLoading ? (
          <Spinner label="Loading team members" />
        ) : !teamInvites ? (
          <p className="text-base font-normal mt-4">You haven't invited any team members yet</p>
        ) : (
          <TeamInvitesTable teamInvites={teamInvites} user={user} />
        )}
      </div>
    </div>
  );
}
