import { BillingPlanCard } from './BillingPlanCard.tsx';
import { formatCurrency } from '../../utils/formatCurrency.ts';
import { formatUnixTimestampToLocaleDate } from '../../utils/dateUtils.ts';
import { Link } from 'react-router-dom';
import { usePlans } from '../../hooks/billing.hooks.ts';
import { Button } from '../../components/Button.tsx';

export function BillingPlansSection() {
  const plans = usePlans();

  if (plans.length === 0) {
    return (
      <>
        <div className="text-base">
          You are not on a plan yet. Upgrade to get more words and access premium features.
        </div>
        <Link to="/studio/pricing" className="block mt-8">
          <Button intent="primary">Upgrade Your Plan</Button>
        </Link>
      </>
    );
  }

  return (
    <div className="flex flex-wrap gap-10">
      {plans.length === 0 && (
        <div className="flex w-96 flex-col gap-4 rounded-xl p-6 dark:bg-neutral-900/50">
          <span className="text-center text-lg">You have no active plans</span>
          <Link to="/studio/pricing" className="w-full rounded-md bg-primary p-3 text-center">
            Buy a new plan
          </Link>
        </div>
      )}
      {plans.map((plan) => {
        const status = plan.status;
        const amount = formatCurrency((plan.amount ?? 0 * (plan.quantity ?? 1)) / 100);
        const startedOn = formatUnixTimestampToLocaleDate(plan.created_at * 1000);
        const renewalOn = formatUnixTimestampToLocaleDate(plan.renewal * 1000);

        const planAddons = Object.values(plan.addons ?? {});

        return (
          <BillingPlanCard
            key={plan.subscription_id}
            subscription_id={plan.subscription_id}
            status={status}
            amount={amount}
            startedOn={startedOn}
            renewalOn={renewalOn}
            planAddons={planAddons}
            name={plan.name}
            interval={plan.interval}
            is_metered_plan={plan.is_metered_plan ?? false}
          />
        );
      })}
    </div>
  );
}
