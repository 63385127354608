import { User } from '../contexts/user.context.tsx';

export const defaultPlanId = 'studio-chars-pro-plan-monthly';

export type VCAddonPlanType = {
  priceId: string;
  name: string;
  interval: 'monthly' | 'yearly';
  price: number;
  key: string;
};

export type CycleType = 'monthly' | 'annually';

export const cycle = {
  MONTHLY: 'monthly' as CycleType,
  ANNUALLY: 'annually' as CycleType,
};

export const voiceCloningPlansMetaData: Record<string, VCAddonPlanType> = {
  'high-fid-clone-monthly': {
    priceId: 'high-fid-clone-monthly',
    name: 'High Fidelity Clone',
    interval: 'monthly',
    price: 19,
    key: 'high-fid-clone-monthly',
  },
  'high-fid-clone-yearly': {
    priceId: 'high-fid-clone-yearly',
    name: 'High Fidelity Clone',
    interval: 'yearly',
    price: 136.8,
    key: 'high-fid-clone-yearly',
  },
  'instant-clone-monthly': {
    priceId: 'instant-clone-monthly',
    name: 'Instant Clone',
    interval: 'monthly',
    price: 4,
    key: 'instant-clone-monthly',
  },
  'instant-clone-yearly': {
    priceId: 'instant-clone-yearly',
    name: 'Instant Clone',
    interval: 'yearly',
    price: 28.8,
    key: 'instant-clone-yearly',
  },
};

export const plansMeta: Record<string, PlanMetaT> = {
  enterprise: {
    name: 'Enterprise',
    features: [
      {
        available: true,
        name: 'Custom usage requirement',
      },
      {
        available: true,
        name: 'Unlimited instant voice clones',
        info: 'Create a custom voice by uploading 30 seconds recording. English supported, multi-lingual coming soon',
      },
      {
        available: true,
        name: 'Access to all voices and languages.',
      },
      {
        available: true,
        name: 'Unlimited High Fidelity voice clones',
      },
      {
        available: true,
        name: 'Commercial use',
      },
      {
        available: true,
        name: 'Attribution-Free Use',
      },
      {
        available: true,
        name: 'Team Access',
      },
      {
        available: true,
        name: 'Advanced security and compliance',
      },
      {
        available: true,
        name: 'Single-Sign-On (SSO)',
      },
      {
        available: true,
        name: 'Commercial and re-sell rights',
      },
      {
        available: false,
        name: 'API',
      },
    ],
  },

  APIenterprise: {
    name: 'Business',
    features: [
      {
        available: true,
        name: 'Large volume discounts',
      },
      {
        available: true,
        name: 'Unlimited Instant Clones',
        info: 'Create a custom voice by uploading 30 seconds recording. English supported, multi-lingual coming soon',
      },
      {
        available: true,
        name: 'Access to all voices and languages.',
      },
      {
        available: true,
        name: 'Real-time streaming API',
      },
      {
        available: true,
        name: 'High-Fidelity Clones',
        info: 'Create the best voice clone by uploading more audio data',
      },
      {
        available: true,
        name: 'Custom rate limits',
      },
      {
        available: true,
        name: 'Commercial use.',
      },
      {
        available: true,
        name: 'Attribution-Free Use',
      },
      {
        available: true,
        name: 'Guranteed latency (400ms) with SLA',
      },
      {
        available: true,
        name: 'Dedicated Enterprise Cluster',
      },
      {
        available: true,
        name: 'ISO/SOC2 Certifications',
      },
      {
        available: true,
        name: 'Single-Sign-On (SSO)',
      },
      {
        available: true,
        name: 'Priority Support',
      },
      {
        available: true,
        name: 'Early access to new models',
      },
    ],
  },

  creatorWithVC: {
    name: 'Creator',
    features: [
      {
        available: true,
        name: '10 instant voice clones.',
        info: 'Create a custom voice by uploading 30 seconds recording. English supported, multi-lingual coming soon',
      },
      {
        available: true,
        name: 'Access to all voices and languages.',
      },
      {
        available: false,
        name: 'High Fidelity voice clones',
        info: 'Create the best voice clone by uploading more audio data',
      },
      {
        available: true,
        name: 'Attribution-Free Use',
      },
      {
        available: false,
        name: 'API',
      },
    ],
  },

  unlimitedStudioPlan: {
    name: 'Unlimited',
    features: [
      {
        available: true,
        name: 'Unlimited instant voice clones',
        info: 'Create a custom voice by uploading 30 seconds recording. English supported, multi- lingual coming soon',
      },
      {
        available: true,
        name: 'Access to all voices and languages.',
      },
      {
        available: true,
        name: '1 High Fidelity clone',
        info: 'Create the best voice clone by uploading more audio data',
      },
      {
        available: true,
        name: 'Commercial use.',
      },
      {
        available: true,
        name: 'Attribution-Free Use',
      },
      {
        available: false,
        name: 'API',
      },
    ],
  },

  free: {
    name: 'Free Plan',
    features: [
      {
        available: true,
        name: '12,500 characters',
      },
      {
        available: true,
        name: '1 Instant voice clone',
        info: 'Create a custom voice by uploading 30 seconds recording. English supported, multi-lingual coming soon',
      },
      {
        available: true,
        name: 'Access to all voices and languages.',
      },
      {
        available: true,
        name: 'Real time streaming API',
      },
      {
        available: false,
        name: 'High Fidelity clones',
        info: 'Create the best voice clone by uploading more audio data',
      },
      {
        available: true,
        name: 'Standard rate limit',
        info: '10 requests / minute 35,000 characters / minute',
      },
      {
        available: false,
        name: 'Commercial use',
      },
      {
        available: false,
        name: 'Attribution-Free Use',
      },
    ],
    isFree: true,
  },

  free_studio: {
    name: 'Free Plan',
    features: [
      {
        available: true,
        name: '12,500 characters',
      },
      {
        available: true,
        name: '1 Instant voice clone',
        info: 'Create a custom voice by uploading 30 seconds recording. English supported, multi-lingual coming soon',
      },
      {
        available: true,
        name: 'Access to all voices and languages.',
      },
      {
        available: false,
        name: 'High Fidelity clones',
        info: 'Create the best voice clone by uploading more audio data',
      },
      {
        available: false,
        name: 'Attribution-Free Use',
      },
      {
        available: false,
        name: 'API',
      },
    ],
    isFree: true,
  },

  apiHackerWithVC: {
    name: 'Hacker',
    features: [
      {
        available: true,
        name: '25,000 characters per month',
        info: '$0.20 per every additional 1000 characters',
      },
      {
        available: true,
        name: '10 instant voice clones.',
        info: 'Create a custom voice by uploading 30 seconds recording. English supported, multi-lingual coming soon',
      },
      {
        available: true,
        name: 'Access to all voices and languages.',
      },
      {
        available: true,
        name: 'Real-Time Streaming API',
      },
      {
        available: false,
        name: 'High Fidelity voice clones',
        info: 'Create the best voice clone by uploading more audio data',
      },
      {
        available: true,
        name: 'Standard rate limit',
        info: '10 requests / minute 35,000 characters / minute',
      },
      {
        available: true,
        name: 'Commercial use.',
      },
      {
        available: true,
        name: 'Attribution-Free Use',
      },
    ],
  },
  // API PRO
  apiProWithVC: {
    name: 'Pro',
    features: [
      { available: true, name: '250,000 characters per month', info: '$0.196 per every additional 1000 characters' },
      {
        available: true,
        name: '100 instant voice clones.',
        info: 'Create a custom voice by uploading 30 seconds recording. English supported, multi-lingual coming soon',
      },
      { available: true, name: 'Access to all voices and languages.' },
      { available: true, name: 'Real-Time Streaming API' },
      {
        available: false,
        name: 'High Fidelity voice clones',
        info: 'Create the best voice clone by uploading more audio data',
      },
      { available: true, name: 'Standard rate limit', info: '10 requests / minute 35,000 characters / minute' },
      { available: true, name: 'Commercial use.' },
      { available: true, name: 'Attribution-Free Use' },
    ],
  },

  apiStartupWithVC: {
    name: 'Startup',
    features: [
      {
        available: true,
        name: '2 million characters per month',
        info: 'Approximately 44 hrs; $0.15 per every additional 1000 characters',
      },
      {
        available: true,
        name: '750 instant clones',
        info: 'Create a custom voice by uploading 30 seconds recording. English supported, multi-lingual coming soon',
      },
      {
        available: true,
        name: 'Access to all voices and languages.',
      },
      {
        available: true,
        name: 'Real-Time Streaming API',
      },
      {
        available: false,
        name: 'High Fidelity voice clones',
        info: 'Create the best voice clone by uploading more audio data',
      },
      {
        available: true,
        name: 'Higher rate limit',
        info: '25 requests / minute 87,500 characters / minute',
      },
      {
        available: true,
        name: 'Commercial use.',
      },
      {
        available: true,
        name: 'Attribution-Free Use',
      },
    ],
  },

  apiGrowthWithVC: {
    name: 'Growth',
    features: [
      {
        available: true,
        name: '11 million characters per month',
        info: 'Approximately 242 hrs; $0.091 per every additional 1000 characters',
      },
      {
        available: true,
        name: 'Unlimited Instant Clones',
        info: 'Create a custom voice by uploading 30 seconds recording. English supported, multi-lingual coming soon',
      },
      {
        available: true,
        name: 'Access to all voices and languages.',
      },
      {
        available: true,
        name: 'Real-Time Streaming API',
      },
      {
        available: false,
        name: 'High Fidelity voice clones',
        info: 'Create the best voice clone by uploading more audio data',
      },
      {
        available: true,
        name: 'Superior rate limits',
        info: '100 requests / minute 350,000 characters / minute',
      },
      {
        available: true,
        name: 'Commercial use.',
      },
      {
        available: true,
        name: 'Attribution-Free Use',
      },
      {
        available: true,
        name: 'Guaranteed latency (400ms)',
      },
    ],
  },

  canva: {
    name: 'Canva',
    features: [
      {
        name: '<b>Clone your own voice</b>',
        isNew: true,
        available: false,
        isGreen: false,
        isInfo: false,
      },
      {
        name: "<span style='color: #01DA72; font-weight: 500; cursor: pointer; text-decoration: underline;'>All Ultra realistic Voices</span>",
        infoText: 'A new generation of voices almost indistinguishable from a human voice.',
        isInfo: true,
        isGreen: true,
        modalName: 'Ultra',
        available: false,
        isNew: false,
      },
      {
        name: "<span style='color: #01DA72; font-weight: 500; cursor: pointer; text-decoration: underline;'>All Premium Voices</span>",
        isGreen: true,
        modalName: 'Realistic',
        isInfo: true,
        infoText:
          'Next generation, state-of-the-art voices powered by Generative AI models. These voices sound so real, they are indistinguishable from a human voice.',
        available: false,
        isNew: false,
      },
      {
        name: 'Unlimited Downloads',
        isInfo: true,
        infoText: 'Download your audio files any number of times',
        available: false,
        isGreen: false,
        isNew: false,
      },
      {
        name: 'Commercial License',
        infoText:
          'You can use the audio you create in your revenue-focused activities such as product demos, ads, employee training, YouTube videos, etc.',
        isInfo: true,
        available: false,
        isGreen: false,
        isNew: false,
      },
    ],
  },
};

export const studioPlans: PlanT[] = [
  // free plan
  {
    ...plansMeta.free_studio,
    key: 'free-plan-monthly',
    priceId: 'free-plan-monthly',
    target: 'studio',
    cycle: cycle.MONTHLY,
    price: 0,
    characterLimit: '12,500 characters',
  },
  {
    ...plansMeta.free_studio,
    key: 'free-plan-yearly',
    priceId: 'free-plan-yearly',
    cycle: cycle.ANNUALLY,
    target: 'studio',
    price: 0,
    characterLimit: '12,500 characters',
  },

  // creator
  {
    ...plansMeta.creatorWithVC,
    key: 'studio-chars-creator-plan-monthly',
    priceId: 'studio-chars-creator-plan-monthly',
    cycle: cycle.MONTHLY,
    target: 'studio',
    price: 39,
    characterLimit: '250,000 characters per month',
    features: [
      {
        available: true,
        name: '250,000 characters per month',
        info: 'Approximately 6 hrs per month',
      },
      ...plansMeta.creatorWithVC.features,
    ],
  },
  {
    ...plansMeta.creatorWithVC,
    key: 'studio-chars-creator-plan-yearly',
    priceId: 'studio-chars-creator-plan-yearly',
    cycle: cycle.ANNUALLY,
    target: 'studio',
    price: 374.4,
    characterLimit: '3 million characters per year',
    features: [
      {
        available: true,
        name: '3 million characters per year',
        info: 'Approximately 70 hrs per year',
      },
      ...plansMeta.creatorWithVC.features,
    ],
  },
  // Pro
  {
    ...plansMeta.unlimitedStudioPlan,
    key: 'studio-chars-pro-plan-monthly',
    priceId: 'studio-chars-pro-plan-monthly',
    cycle: cycle.MONTHLY,
    target: 'studio',
    price: 99,
    characterLimit: 'Unlimited* characters per month',
    features: [
      {
        available: true,
        name: 'Unlimited* characters per month',
        info: 'Adhere to our fair use policy to ensure smooth operations',
      }, // limit is 2,500,000 characters per month
      ...plansMeta.unlimitedStudioPlan.features,
    ],
    isRecommended: true,
  },
  {
    ...plansMeta.unlimitedStudioPlan,
    key: 'studio-chars-pro-plan-2-yearly',
    priceId: 'studio-chars-pro-plan-2-yearly',
    cycle: cycle.ANNUALLY,
    target: 'studio',
    price: 348,
    characterLimit: 'Unlimited* characters per year',
    features: [
      {
        available: true,
        name: 'Unlimited* characters per year',
        info: 'Adhere to our fair use policy to ensure smooth operations',
      }, // limit is 30,000,000 characters per year
      ...plansMeta.unlimitedStudioPlan.features,
    ],
    isRecommended: true,
    isOnDiscount: true,
    priceBeforeDiscount: 1188,
    discountCopy: 'Limited time deal!',
    discountCouponCode: 'blackfriday2023',
    discountExpiresAt: generateDiscountExpiryTimeStampEveryThreeDays(),
  },

  // enterprise
  {
    ...plansMeta.enterprise,
    key: 'enterprise-plan-monthly',
    priceId: 'enterprise-plan-monthly',
    cycle: cycle.MONTHLY,
    target: 'studio',
    price: 0,
    characterLimit: 'Please contact us',
    isEnterprisePlan: true,
  },
  {
    ...plansMeta.enterprise,
    key: 'enterprise-plan-yearly',
    priceId: 'enterprise-plan-yearly',
    cycle: cycle.ANNUALLY,
    target: 'studio',
    price: 0,
    characterLimit: 'Please contact us',
    isEnterprisePlan: true,
  },
];

export const apiPlans: PlanT[] = [
  // free plan
  {
    ...plansMeta.free,
    key: 'free-plan-monthly',
    priceId: 'free-plan-monthly',
    target: 'api',
    cycle: cycle.MONTHLY,
    characterLimit: '12,500 characters',
    price: 0,
  },

  // API Hacker
  {
    ...plansMeta.apiHackerWithVC,
    key: 'api-chars-hacker-2-metered-plan-monthly',
    priceId: 'api-chars-hacker-2-metered-plan-monthly',
    target: 'api',
    cycle: cycle.MONTHLY,
    price: 5,
    characterLimit: '25,000 characters per month',
    features: [...plansMeta.apiHackerWithVC.features],
  },

  // API PRO
  {
    ...plansMeta.apiProWithVC,
    key: 'api-chars-pro-metered-plan-monthly',
    priceId: 'api-chars-pro-metered-plan-monthly',
    target: 'api',
    cycle: cycle.MONTHLY,
    price: 49,
    characterLimit: '250,000 characters per month',
    features: [...plansMeta.apiProWithVC.features],
  },

  // API Startup
  {
    ...plansMeta.apiStartupWithVC,
    key: 'api-chars-startup-2-metered-plan-monthly',
    priceId: 'api-chars-startup-2-metered-plan-monthly',
    target: 'api',
    cycle: cycle.MONTHLY,
    price: 299,
    characterLimit: '2 million characters per month',
    features: [...plansMeta.apiStartupWithVC.features],
  },

  // growth

  {
    ...plansMeta.apiGrowthWithVC,
    key: 'api-chars-growth-2-metered-plan-monthly',
    priceId: 'api-chars-growth-2-metered-plan-monthly',
    target: 'api',
    cycle: cycle.MONTHLY,
    price: 999,
    characterLimit: '11 million characters per month',
    features: [...plansMeta.apiGrowthWithVC.features],
    isRecommended: true,
  },

  // enterprise
  {
    ...plansMeta.APIenterprise,
    key: 'enterprise-plan-monthly',
    priceId: 'enterprise-plan-monthly',
    target: 'api',
    cycle: cycle.MONTHLY,
    price: 0,
    characterLimit: 'Please contact us',
    isEnterprisePlan: true,
  },
];

export type PlanMetaT = {
  name: string;
  features: {
    available: boolean;
    name: string;
    info?: string;
    infoText?: string;
    isGreen?: boolean;
    isInfo?: boolean;
    modalName?: string;
    isNew?: boolean;
  }[];
  isFree?: boolean;
};

export type PlanT = {
  key: string;
  priceId: string;
  target: 'studio' | 'api';
  cycle: CycleType;
  price: number;
  isOnFiftyPercentDiscount?: boolean;
  discountExpiryTimestamp?: Date;
  words?: string;
  isEnterprisePlan?: boolean;
  isRecommended?: boolean;
  isOnDiscount?: boolean;
  discountCopy?: string;
  priceBeforeDiscount?: number;
  discountCouponCode?: string;
  isFree?: boolean;
  discountExpiresAt?: Date;
  characterLimit?: string;
  features: {
    available: boolean;
    name: string;
    info?: string;
    infoText?: string;
    isGreen?: boolean;
    isInfo?: boolean;
  }[];
} & PlanMetaT;
export const getActivePackage = (user: User) =>
  Object.values(user.packages ?? {}).find((p) => p.status === 'active') ?? null;

export const getLastPackage = (user: User) => {
  const packageValues = Object.values(user.packages ?? {});
  return packageValues.length > 0 ? packageValues[packageValues.length - 1] : null;
};

export const getActiveVCpackage = (user: User) =>
  user && user.add_ons
    ? Object.values(user.add_ons).find((p) => p.status === 'active' && p.id && p.id.includes('cloning'))
    : null;

export function getPlan(key: string) {
  if (!key) return null;
  return [...studioPlans, ...apiPlans].find((plan) => plan.key.includes(key));
}

function generateDiscountExpiryTimeStampEveryThreeDays() {
  // this function is for extending discount every 3 days
  const now = new Date();
  let discountExpiryDate;

  if (now.getDate() < 30) {
    const expiryDay = (Math.trunc(now.getDate() / 3) + 1) * 3;
    discountExpiryDate = new Date(now.getFullYear(), now.getMonth(), expiryDay);
  } else {
    discountExpiryDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  }

  return discountExpiryDate;
}
