import axios from 'axios';
import { CONFIG } from '../../../config';

export const getAll = async (token) =>
  axios
    .get(`${CONFIG.playStudioAPIBaseUrl}/voices/`, {
      headers: {
        Authorization: `${token}`,
      },
    })
    .then((result) => result.data);
