import { useUser } from '../contexts/user.context.tsx';
import { getActivePackage, getActiveVCpackage, getLastPackage } from '../utils/plans.helpers.tsx';
import { useMemo } from 'react';
import { CloningRequest, Voice } from '../utils/VC.helpers.ts';
import { getAddonQuantityFromMainPlan } from '../domain/billing/getAddonQuantityFromMainPlan.ts';

export function useActiveMainPlan() {
  const { user } = useUser();

  const lastPackage = useMemo(() => user && getLastPackage(user), [user]);
  const activePackage = useMemo(() => user && getActivePackage(user), [user]);

  if (user?.is_pro && !activePackage) {
    // when the user cancel his plan but is_pro is still true we return the last package as the plan expire date is not yet reached
    // there is a cronjob that will set is_pro to false when the plan expire date is reached
    return lastPackage;
  }

  return activePackage;
}

export function useActiveVCPlan() {
  const { user } = useUser();
  return useMemo(() => user && getActiveVCpackage(user), [user]);
}

export function useUserHasHFCloningAccess() {
  const activeMainPackage = useActiveMainPlan();
  const activeVoiceCloningPackage = useActiveVCPlan();
  return !!activeVoiceCloningPackage || !!activeMainPackage?.high_fid_clone;
}

export function useUserReachedHFCloningLimit(userClonedVoices: Voice[], userCloningRequests: CloningRequest[]) {
  const activeMainPlan = useActiveMainPlan();
  const activeVCPlan = useActiveVCPlan();
  return useMemo(() => {
    let allowedQuantity = 0;
    let existingClonesCount = 0;
    if (activeVCPlan) {
      allowedQuantity = activeVCPlan.quantity;
      existingClonesCount = userClonedVoices.length;
    } else if (activeMainPlan) {
      allowedQuantity = activeMainPlan.high_fid_clone ?? 0;
      existingClonesCount = userClonedVoices.filter((v) => !v.isZeroShot).length + userCloningRequests.length;
      allowedQuantity += getAddonQuantityFromMainPlan(activeMainPlan, 'high-fid-clone');
    } else {
      existingClonesCount = userClonedVoices.length;
      allowedQuantity = 0;
    }

    return existingClonesCount >= allowedQuantity;
  }, [activeMainPlan, activeVCPlan, userClonedVoices, userCloningRequests]);
}

export function useUserReachedInstantCloningLimit(userClonedVoices: Voice[]) {
  const activeMainPlan = useActiveMainPlan();
  const activeVCPlan = useActiveVCPlan();
  return useMemo(() => {
    let allowedQuantity = 0;
    let existingClonesCount = 0;
    if (activeVCPlan) {
      allowedQuantity = activeVCPlan.quantity;
      existingClonesCount = userClonedVoices.length;
    } else if (activeMainPlan) {
      allowedQuantity = activeMainPlan.zero_shot_clone ?? 0;
      existingClonesCount = userClonedVoices.filter((v) => v.isZeroShot).length;
      allowedQuantity += getAddonQuantityFromMainPlan(activeMainPlan, 'instant-clone');
    } else {
      allowedQuantity = 1;
      existingClonesCount = userClonedVoices.length;
    }
    return existingClonesCount >= allowedQuantity;
  }, [activeMainPlan, activeVCPlan, userClonedVoices]);
}
