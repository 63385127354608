/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { Waveform } from '@playht/component-waveform';
import Slider from 'rc-slider';

import LoaderCircle from './Icons/LoaderCircle';
import debounce from 'lodash.debounce';
import { Modal } from './Modal';
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from '@heroicons/react/20/solid';
import { PlayIcon } from './Icons/PlayIcon';
import { PauseIcon } from './Icons/PauseIcon';

// eslint-disable-next-line react-refresh/only-export-components
export const TUTORIAL_URLS = {
  VOICE_CLONING: '//playtht-website-assets.s3.amazonaws.com/img/tutorials/clipping-tutorial.gif',
};

const MAX_REGION_DURATION = 60; // 1 minute
const MIN_REGION_DURATION = 6; // 6 seconds
const MAX_TOTAL_DURATION = 60 * 60 * 4; // 4 hours
const MAX_CLIPS_COUNT = 6; // per speaker
const MIN_CLIPS_COUNT = 2; // per speaker

const useInjectStyles = (css) => {
  useLayoutEffect(() => {
    const head = document.head;
    const style = document.createElement('style');

    head.appendChild(style);

    style.appendChild(document.createTextNode(css));
  }, [document.head, css]);
};

const groupBy = (data, ...keys) => {
  const getGroupFromItem = (item, keys) => {
    return keys.length > 1 ? getGroupFromItem(item[keys[0]], keys.slice(1)) : item[keys[0]];
  };

  return data.reduce((results, item) => {
    const group = getGroupFromItem(item, keys);

    results[group] = results[group] || [];
    results[group].push(item);

    return results;
  }, {});
};

// eslint-disable-next-line react-refresh/only-export-components
export const hasMinimumClippings = (source = [], clippings = []) => {
  if (!source || (Array.isArray(source) && source.length === 0)) {
    return false;
  }

  if (!clippings || (Array.isArray(clippings) && clippings.length === 0)) {
    return false;
  }

  return clippings?.length >= MIN_CLIPS_COUNT;
};

const AudioClipper = ({ urls = [], onFinish, speaker, onSpeakerUpdate }) => {
  const [ready, setReady] = useState(false);
  const [reset, setReset] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const [clippings, setClippings] = useState(null);
  const [tutorial, setTutorial] = useState(false);

  const [instanceMap, setInstanceMap] = useState({});
  const [durationMap, setDurationMap] = useState({});

  const [duration, setDuration] = useState(0);

  const [hasCapacity, setHasCapacity] = useState(true);

  const [internal, setInternal] = useState(urls);
  const [completed, setCompleted] = useState([]);

  useEffect(() => {
    if (completed.length === 0) {
      setInternal(urls);
    }

    const keys = Object.keys(groupBy(completed, 'url'));

    setInternal(urls.filter((src) => !keys.includes(src.url)));
  }, [urls, completed]);

  useEffect(() => {
    if (reset) {
      setCompleted([]);
      setInternal(urls);

      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    setDuration(
      Object.values(durationMap)
        .filter((value) => value && !Number.isNaN(value))
        .reduce((prev, next) => prev + next, 0)
    );
  }, [durationMap]);

  useEffect(() => {
    if (!speaker || speaker === '') {
      setModalVisible(true);
    }
  }, [speaker]);

  const urlsRef = useRef(urls);
  useEffect(() => {
    if (!hasCapacity) {
      return;
    }

    if (urlsRef.current?.length !== urls.length) {
      setReady(false);
      urlsRef.current = urls;
    }
  }, [urls]);

  // workaround to make sure label text is visible in dark mode
  useInjectStyles(`
  html.dark .marker-label {
    color: #EBE8E9;
  }
  `);

  const onlyUrls = useMemo(() => internal.map((elem) => elem.url), [internal]);

  const handleUpdate = (records) => {
    const keys = Object.keys(records);

    if (keys.length >= MAX_CLIPS_COUNT) {
      setCompleted(urls);
      setInternal([]);
    }

    const next = keys
      .map((key) => records[key])
      .map((record) => ({
        start: record.startTime,
        end: record.endTime,
        url: record.url,
        speaker: record.speaker,
        index: onlyUrls.indexOf(record.url),
      }));

    if (typeof onFinish === 'function') {
      onFinish(next);
    }

    setClippings(next);
  };

  const handleMount = (url, instance) => setInstanceMap((prev) => ({ ...prev, [url]: instance }));

  const handleSlide = (url, value) => {
    const instance = instanceMap[url];

    if (instance) {
      instance.zoom(value);
    }
  };

  const handleReady = (url, instance) => {
    setReady(true);

    // by default, we'd be using url as the key, but it would mean duplicated files could
    // have total duration more than the threshold, so we need to pick the name instead
    // to make sure we have unique audio files to get the data from.
    let key = url;
    const src = internal.find((item) => item.url === url);
    if (src) {
      key = src.name;
    }

    setDurationMap((prev) => ({ ...prev, [key]: (instance && instance.getDuration && instance.getDuration()) ?? 0 }));
  };

  const debouncedHandleSlide = useMemo(() => debounce(handleSlide, 250), [instanceMap]);

  const urlMap = useMemo(() => internal.reduce((prev, { url, name }) => ({ ...prev, [url]: name }), {}), [internal]);

  const source = useMemo(() => {
    const src = [];
    let total = 0;

    Object.keys(urlMap).forEach((url) => {
      const name = urlMap[url];
      const duration = durationMap[name];

      if (total <= MAX_TOTAL_DURATION) {
        src.push(url);
      } else {
        setHasCapacity(false);
      }

      total += duration;
    });

    return src;
  }, [urlMap, durationMap]);

  const hasSmallClip = useMemo(() => {
    if (clippings === null) {
      return false;
    }

    return clippings.some(({ start, end }) => end - start < MIN_REGION_DURATION);
  }, [clippings]);

  const hasLargeClip = useMemo(() => {
    if (clippings === null) {
      return false;
    }

    return clippings.some(({ start, end }) => end - start > MAX_REGION_DURATION);
  }, [clippings]);

  const hiddenCount = internal.length - source.length;
  const showDurationWarning = duration > 0 && duration < 10 * 60; // 30 minutes
  const isVisible = internal.length > 0;
  const isClipping = speaker !== null && isVisible;

  return (
    <React.Fragment>
      <Modal open={modalVisible} setOpen={() => setModalVisible(true)}>
        <div className="text-sm font-medium text-[#32325D] dark:text-[#EBE8E9]">
          <p className="mb-2 mt-6">Please type the speaker name: </p>
          <input
            className="mb-4 mt-8 w-full rounded-lg py-3 text-sm font-semibold text-[#32325D] disabled:opacity-50"
            type="text"
            onChange={(elem) => onSpeakerUpdate(elem.target.value)}
          />
          <button
            className="mb-4 mt-8 w-full rounded-lg bg-[#01DA72] py-3 text-sm font-semibold text-neutral-50 disabled:opacity-50"
            onClick={() => setModalVisible(false)}
          >
            <span>Set Speaker</span>
          </button>
        </div>
      </Modal>
      <Modal open={tutorial} setOpen={() => setTutorial((prev) => !prev)}>
        <img src={TUTORIAL_URLS.VOICE_CLONING} />
        <button
          className="mb-4 mt-8 w-full rounded-lg bg-[#01DA72] py-3 text-sm font-semibold text-neutral-50 disabled:opacity-50"
          onClick={() => setTutorial(false)}
        >
          <span>Close</span>
        </button>
      </Modal>

      {isVisible && (
        <div className="mt-6 pt-4">
          <p className="mb-4 text-sm font-medium text-[#32325D] dark:text-[#EBE8E9]">
            Click and highlight parts on the audio files that best capture the speakers voice
          </p>
        </div>
      )}

      {showDurationWarning && (
        <div className="col-auto mt-5 flex rounded border-2 border-amber-500 bg-amber-100 p-5 text-sm text-amber-500 dark:border-none dark:bg-amber-500 dark:text-neutral-50">
          <ExclamationCircleIcon className="h-10 w-10" />
          <span className="ml-5 self-center">
            Upload at least 10 minutes of high quality audio to get the best results.
          </span>
        </div>
      )}

      {isClipping && (
        <p className="py-2 text-center text-sm font-medium text-[#32325D] dark:text-[#EBE8E9]">
          {clippings?.length ?? 0}/{MAX_CLIPS_COUNT} parts selected
        </p>
      )}

      {!ready && <LoaderCircle stroke="#01da72" />}

      <Waveform
        urls={source}
        onRecordsUpdated={handleUpdate}
        onReady={handleReady}
        onMount={handleMount}
        progressColor="#01da72"
        showEditMode={isClipping}
        speakerName={speaker}
        reset={reset}
        pluginOpts={{
          timeline: {
            primaryFontColor: '#e1e1e1',
            secondaryFontColor: '#01da72',
          },
          regions: {
            regionsMinLength: MIN_REGION_DURATION,
          },
        }}
        beforeContent={(url) => (
          <div className="py-2">
            {ready && <span className="text-sm font-medium text-[#32325D] dark:text-[#EBE8E9]">{urlMap[url]}</span>}
          </div>
        )}
        afterContent={(url) => (
          <div className="py-2">
            {ready && (
              <div className="flex items-center">
                <MinusCircleIcon className="mr-2 h-5 w-5 dark:text-neutral-300" />
                <Slider min={0} max={100} defaultValue={20} onChange={(value) => debouncedHandleSlide(url, value)} />
                <PlusCircleIcon className="ml-2 h-5 w-5 dark:text-neutral-300" />
              </div>
            )}
          </div>
        )}
        actionContainerClassName="flex items-center h-[205px] w-[60px]"
        action={(status, handler) =>
          ready ? (
            <div className="cursor-pointer" onClick={handler}>
              {status ? <PauseIcon /> : <PlayIcon />}
            </div>
          ) : null
        }
      />

      {isVisible && (
        <div
          className="my-3 cursor-pointer text-center text-xs font-medium text-[#32325D] underline dark:text-[#EBE8E9]"
          onClick={() => setTutorial((prev) => !prev)}
        >
          How to clip audio & identify speakers for better performance?
        </div>
      )}

      {isClipping && (
        <p className="py-2 text-sm font-medium text-[#C8C8C8] dark:text-[#727272]">
          Hint: You can also play and pause uploaded audio by clicking on waveform
        </p>
      )}

      {completed.length > 0 && (
        <div className="mt-6 pt-4">
          <div className="flex flex-row">
            <p className="mb-4 flex-grow text-lg font-medium text-[#32325D] dark:text-[#EBE8E9]">Completed Files</p>
            <button
              className="h-8 rounded-lg border-2 border-[#01DA72] px-4 text-sm dark:text-neutral-50"
              onClick={() => setReset(true)}
            >
              Reset
            </button>
          </div>

          <div>
            {completed.map((file, index) => (
              <div key={index} className="flex flex-row items-center gap-2">
                <div className="h-4 w-4 rounded-full bg-[#01DA72]" />
                <p className="text-sm font-medium text-[#32325D] dark:text-[#EBE8E9]">{file.name}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {!hasCapacity && hiddenCount > 0 && (
        <div className="col-auto mt-5 flex rounded border-2 border-cyan-600 bg-cyan-100 p-5 text-sm text-cyan-500 dark:border-none dark:bg-cyan-600 dark:text-neutral-50">
          <InformationCircleIcon className="h-10 w-10" />
          <span className="ml-5 self-center">
            {hiddenCount} {hiddenCount > 1 ? 'files are' : 'file is'} not being shown to be clipped as we already have 4
            hours worth of audio as the source to clone from.
          </span>
        </div>
      )}

      {(hasSmallClip || hasLargeClip) && (
        <div className="col-auto mt-5 flex rounded border-2 border-red-500 bg-red-100 p-5 text-sm text-red-500 dark:border-none dark:bg-red-500 dark:text-neutral-50">
          <ExclamationCircleIcon className="h-10 w-10" />
          <span className="ml-5 self-center">
            {hasSmallClip && !hasLargeClip && `All clips should have minimum length of ${MIN_REGION_DURATION} seconds`}
            {hasLargeClip && !hasSmallClip && `All clips should have maximum length of ${MAX_REGION_DURATION} seconds`}
            {hasLargeClip &&
              hasSmallClip &&
              `All clips should have length between ${MIN_REGION_DURATION} to ${MAX_REGION_DURATION} seconds`}
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

export { AudioClipper };
