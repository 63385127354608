export type FileId = string & { brand: '__FileId' };
export interface File {
  id: FileId;
  title?: string;
  isStudio: boolean;
  isStudioAudio: boolean;
  modifiedDate: number; // unix timestamp in milliseconds
  model?: string;
  wordsCount?: string | number;
  // TODO: add others fields
}

export type FileModel = 'PlayHT2.0' | 'PlayHT1.0' | 'Standard' | 'PlayHT2.0-gargamel' | 'Play3.0' | 'PlayDialog';
export const DEFAULT_FILE_MODEL: FileModel = 'PlayHT1.0';

export const defaultFileName = 'Untitled';
