import { Spinner } from '../../components/Spinner';
import { useClonedVoicesAndRequests } from '../../hooks/voiceCloning.hooks.ts';
import { VCTable } from './VCTable.tsx';
import { useEffect, useState } from 'react';
import { TextField } from '../../components/TextField.tsx';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { DeleteClonedVoicesButton } from './DeleteClonedVoicesButton.tsx';
import { useUser } from '../../contexts/user.context.tsx';
import { VoiceCloningModal } from '../../components/CreateVoiceCloneWizard/CreateVoiceCloneWizard.tsx';
import { PlusSmallIcon } from '@heroicons/react/20/solid';

export type ModalType = null | 'choose' | 'upgrade' | 'instant' | 'hf';

export function VCListingPage() {
  const { user } = useUser();
  const { isFetching, isFetchedAfterMount, vcRequestsAndVoices } = useClonedVoicesAndRequests();
  const [filteredRows, setFilteredRows] = useState(vcRequestsAndVoices);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [searchingValue, setSearchingValue] = useState('');
  const openVoiceCloningModal = VoiceCloningModal.useStore((state) => state.open);

  useEffect(() => {
    if (searchingValue && vcRequestsAndVoices) {
      const newFilteredFiles = vcRequestsAndVoices.filter((file) =>
        file.name.toLowerCase().includes(searchingValue.toLowerCase())
      );
      setFilteredRows(newFilteredFiles);
      setSelectedRowIds(selectedRowIds.filter((fileId) => newFilteredFiles.some((f) => f.id === fileId)));
    } else {
      setFilteredRows(vcRequestsAndVoices);
    }
  }, [searchingValue, vcRequestsAndVoices, selectedRowIds]);

  const onDelete = (deletedRowIds: string[]) => {
    setSelectedRowIds(selectedRowIds.filter((id) => !deletedRowIds.includes(id)));
  };

  return (
    <>
      <div className="max-w-5xl">
        <h1 className="text-xl font-semibold">Voice Cloning</h1>
        {user?.isTeamMember ? (
          <p className="my-2">You are a team member, Only team admins can clone voices.</p>
        ) : (
          <div
            className="my-6 cursor-pointer rounded-xl bg-neutral-900 p-10 ring-1 ring-inset ring-neutral-50/10 max-tablet:p-6"
            onClick={() => openVoiceCloningModal()}
          >
            <div className="flex items-center justify-center gap-4">
              <div className="rounded-xl bg-primary">
                <PlusSmallIcon className="h-12" />
              </div>
              <div className="text-base font-semibold">Create a New Clone</div>
            </div>
          </div>
        )}
        {isFetching && !isFetchedAfterMount ? (
          <Spinner className="mt-10" label="Fetching voice clones..." />
        ) : vcRequestsAndVoices.length === 0 ? (
          <span className="text-base font-semibold">You haven't created any voice clones yet</span>
        ) : (
          <>
            <div className="flex flex-wrap items-center justify-between gap-6">
              <div className="mt-6 w-96 max-tablet:w-full">
                <TextField
                  name="search"
                  value={searchingValue}
                  placeholder="Search cloned voices"
                  type="search"
                  autoComplete="off"
                  onChange={setSearchingValue}
                  width="auto"
                  leftIcon={<MagnifyingGlassIcon className="h-5 text-neutral" />}
                />
              </div>
              <div className="flex gap-3">
                <DeleteClonedVoicesButton voicesIds={selectedRowIds} onDelete={onDelete} />
              </div>
            </div>
            <VCTable rows={filteredRows} selectedRows={selectedRowIds} setSelectedRows={setSelectedRowIds} />
          </>
        )}
      </div>
    </>
  );
}
