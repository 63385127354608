import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { onValue, ref } from 'firebase/database';
import { useAuth } from './auth.context';
import { database } from '../utils/firebase';
import { saveUserToRealtimeDatabase } from '../API/user.requests';
import { AddOn, Package } from '../domain/billing/Plan.ts';
import { errorsTracker } from '../infra/errors/errorsTracker.ts';
import { getActivePackage } from '../utils/plans.helpers.tsx';
import { eventsTracker } from '../infra/analytics/eventsTracker.ts';

export type User = {
  id: string;
  email: string;
  usecase?: unknown;
  role?: string;
  source?: string;
  created_at?: string;
  imageUrl?: string;
  name?: string;
  refreshToken?: string;
  providerId?: string;
  appId?: string;
  usage?: {
    credits?: number;
    appsumo_credits?: number;
    writer_credits?: number;
    appsumo_words?: number;
    words_count?: number;
    words_carry_over?: number;
    chars_count?: number;
    current_metered_char_usage?: number;
  };
  packages?: Record<string, Package>;
  activated?: boolean;
  phoneNumber?: string;
  action?: string;
  add_ons?: Record<string, AddOn>;
  stripe_customer_id?: string;
  type?: string;
  emailVerified?: boolean;
  is_pro?: boolean;
  isAdmin?: boolean;
  isTeamMember?: boolean;
  teamAdmins?: string[];
  isTeamAdmin?: boolean;
  adminOfTeams?: string[];
  singleViewItems?: {
    lastNewsSeenDate?: string;
  };
};

const UserContext = createContext<{
  user: User | null;
  loading: boolean;
}>({
  user: null,
  loading: true,
});

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuth();

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (!auth.currentUser || !auth.currentUser.uid) {
      return;
    }

    const userRef = ref(database, `users/${auth.currentUser.uid}`);

    const unsubscribe = onValue(userRef, async (snapshot) => {
      const data: User = snapshot.val(); // TODO: validate with Zod

      if (
        (!data || !data?.appId || !data?.id || !data?.email) &&
        auth.currentUser &&
        auth.currentUser.uid &&
        auth.currentUser.email
      ) {
        const idToken = await auth.currentUser?.getIdToken();
        await saveUserToRealtimeDatabase(
          {
            id: auth.currentUser.uid,
            email: auth.currentUser.email,
            name: auth.currentUser.displayName,
            imageUrl: auth.currentUser.photoURL,
            refreshToken: auth.currentUser.refreshToken,
            providerId: auth.currentUser.providerId,
            source: 'roost',
          },
          idToken
        );
      }

      setUser(data ?? null);

      if (!data) {
        errorsTracker.setUserContext(null);
      } else {
        addSentryContextData(auth.currentUser?.uid, data);

        // Profitwell
        addProfitWellData(data);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth.currentUser]);

  const value = {
    user,
    loading: auth.loading,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  return useContext(UserContext);
};

export function isUserOnboarded(user: User) {
  return user.role || user.type || user.usecase;
}

function addSentryContextData(currentUserId: string | undefined, data: User) {
  try {
    errorsTracker.setUserContext({
      id: currentUserId || data?.id || 'unknown',
      email: data?.email || 'unknown',
      is_pro: data?.is_pro ?? false,
      plan: getActivePackage(data)?.id ?? 'free',
    });
  } catch (e) {
    console.error(e);
  }
}

async function addProfitWellData(data: User) {
  // https://www.paddle.com/help/profitwell-metrics/setup/install-engagement/how-to-install-the-profit-well-js-snippet-for-engagement-data
  // because each user might have a couple of stripe profiles so we don't want to use the user email
  // and we don't want to start profitwell if there is no stripe_customer_id
  try {
    if (data.stripe_customer_id) {
      await eventsTracker.sendProfitwellEvent('start', {
        user_id: data.stripe_customer_id,
      });
    }
  } catch (e) {
    console.error(e);
  }
}
