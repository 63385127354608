import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Modal } from '../../components/Modal';
import { VCAddonPlanType, voiceCloningPlansMetaData } from '../../utils/plans.helpers.tsx';
import UpgradeCard from './UpgradeCard';
import { useActiveMainPlan } from '../../hooks/plans.hooks';
import { CheckCircleIcon, ChevronLeftIcon, MinusIcon, PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { Button } from '../../components/Button';

function VCbuyVoicesModal({
  open,
  setOpen,
  type,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  type: 'instant' | 'hf';
}) {
  const activeMainPlan = useActiveMainPlan();
  const isMonthly = activeMainPlan?.interval !== 'year';

  const [isSuccessfulPurchase, setIsSuccessfulPurchase] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const [hasChosenQuantity, setHasChosenQuantity] = useState(false);

  useEffect(() => {
    if (isSuccessfulPurchase) {
      // when we have both setStates in the same setTimeout the user will see the success cleared and then the modal closed
      const timeOutId = setTimeout(() => {
        setOpen(false);
      }, 1000);
      const sucesstimeOutId = setTimeout(() => {
        setIsSuccessfulPurchase(false);
        setHasChosenQuantity(false);
      }, 2000);
      return () => {
        clearTimeout(timeOutId);
        clearTimeout(sucesstimeOutId);
      };
    }
  }, [isSuccessfulPurchase, setOpen]);

  let theTargetPackage;
  if (type === 'hf') {
    theTargetPackage = isMonthly
      ? voiceCloningPlansMetaData['high-fid-clone-monthly']
      : voiceCloningPlansMetaData['high-fid-clone-yearly'];
  } else {
    theTargetPackage = isMonthly
      ? voiceCloningPlansMetaData['instant-clone-monthly']
      : voiceCloningPlansMetaData['instant-clone-yearly'];
  }

  return (
    <Modal maxWidth="560px" className="p-4 max-tablet:p-4" open={open} setOpen={setOpen}>
      {!hasChosenQuantity ? (
        <ChooseQuantity
          quantity={quantity}
          setQuantity={setQuantity}
          setHasChosenQuantity={setHasChosenQuantity}
          type={type}
          theTargetPackage={theTargetPackage}
        />
      ) : (
        <UpgradeCardWrapper
          isSuccessfulPurchase={isSuccessfulPurchase}
          isMonthly={isMonthly}
          setIsSuccessfulPurchase={setIsSuccessfulPurchase}
          quantity={quantity}
          theTargetPackage={theTargetPackage}
          setOpen={setOpen}
          setHasChosenQuantity={setHasChosenQuantity}
        />
      )}
    </Modal>
  );
}

const UpgradeCardWrapper = ({
  isSuccessfulPurchase,
  isMonthly,
  setIsSuccessfulPurchase,
  quantity,
  theTargetPackage,
  setOpen,
  setHasChosenQuantity,
}: {
  isSuccessfulPurchase: boolean;
  isMonthly: boolean;
  setIsSuccessfulPurchase: Dispatch<SetStateAction<boolean>>;
  quantity: number;
  theTargetPackage: VCAddonPlanType;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setHasChosenQuantity: Dispatch<SetStateAction<boolean>>;
}) =>
  !isSuccessfulPurchase ? (
    <div>
      <div className="flex items-center justify-between pt-4">
        <ChevronLeftIcon className="h-6 w-6 cursor-pointer" onClick={() => setHasChosenQuantity(false)} />
        <div className="h-6 w-6" />
        <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={() => setOpen(false)} />
      </div>
      <UpgradeCard
        plan={{
          ...theTargetPackage,
          quantity,
        }}
        isMonthly={isMonthly}
        setIsSuccessfulPurchase={setIsSuccessfulPurchase}
        quantity={quantity}
      />
    </div>
  ) : (
    <div className="max-w-380 mx-auto flex flex-col items-center rounded-lg p-12">
      <CheckCircleIcon className="mb-4 h-20 w-20 text-xl text-primary" />

      <h4 className="text-2xl font-bold">Thank you for your purchase!</h4>

      <p className="mt-4 text-center text-sm font-normal">
        We’ve sent you a receipt on your registered email with us. If you need help, please reach out to us at{' '}
        <a href="mailto:support@play.ht" className="text-primary">
          support@play.ht
        </a>
        .
      </p>
    </div>
  );

const ChooseQuantity = ({
  quantity,
  setQuantity,
  setHasChosenQuantity,
  type,
  theTargetPackage,
}: {
  quantity: number;
  setQuantity: Dispatch<SetStateAction<number>>;
  setHasChosenQuantity: Dispatch<SetStateAction<boolean>>;
  type: 'instant' | 'hf';
  theTargetPackage: VCAddonPlanType;
}) => {
  const typeLabel = type === 'instant' ? 'Instant' : 'High Fidelity';
  return (
    <div className="mx-auto flex flex-col items-center rounded-lg p-4 sm:p-8 lg:p-14">
      <h4 className="mb-4 text-center text-lg font-semibold sm:text-xl lg:text-2xl">Buy {typeLabel} Clones</h4>
      <div className="mt-4 flex items-center gap-4">
        <MinusIcon
          onClick={() => setQuantity((prev) => (prev > 1 ? prev - 1 : prev))}
          className="h-10 w-10 cursor-pointer"
        />
        <p className="rounded-2xl border border-neutral-700 bg-neutral-800 px-8 py-6  text-lg font-semibold sm:text-xl lg:text-5xl">
          {quantity}
        </p>
        <PlusIcon onClick={() => setQuantity((prev) => prev + 1)} className="h-10 w-10 cursor-pointer" />
      </div>
      <p className="mt-4 text-xs text-neutral-400">
        ${theTargetPackage.price} per {typeLabel} clone
      </p>
      <div className="mt-8 w-full">
        <Button intent="primary" width="full" onClick={() => setHasChosenQuantity(true)}>
          Make Payment
        </Button>
        <p className="mt-4 text-center text-neutral-500">
          Prorated amount payable now, then ${theTargetPackage.price} per{' '}
          {theTargetPackage.interval === 'yearly' ? 'year' : 'month'}.
        </p>
      </div>
    </div>
  );
};

export { VCbuyVoicesModal };
