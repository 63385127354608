import { AuthErrorCodes, AuthError } from '@firebase/auth';

export const formatFirebaseError = (error: AuthError) => {
  switch (error.code) {
    case AuthErrorCodes.INVALID_EMAIL:
      return 'Invalid email address format.';
    case AuthErrorCodes.USER_DISABLED:
      return 'This account has been disabled.';
    case AuthErrorCodes.USER_DELETED:
      return 'There is no account associated with this email address.';
    case AuthErrorCodes.INVALID_PASSWORD:
      return 'Invalid password.';
    case AuthErrorCodes.EMAIL_EXISTS:
      return 'This email address is already in use.';
    case AuthErrorCodes.WEAK_PASSWORD:
      return 'Password should be at least 6 characters.';
    case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
      return 'Too many attempts. Please try again later.';
    case AuthErrorCodes.OPERATION_NOT_ALLOWED:
      return 'This operation is not allowed.';
    case AuthErrorCodes.NETWORK_REQUEST_FAILED:
      return 'Network error. Please try again.';
    case AuthErrorCodes.INTERNAL_ERROR:
      return 'Internal error. Please try again.';
    default:
      return 'An unknown error occurred.';
  }
};
