import { useFirebaseListener } from '../../../hooks/firebase.hooks';
import { auth } from '../../../utils/firebase';

export function useFirebaseUploadedMedia() {
  return useFirebaseListener(`videos/${auth?.currentUser?.uid}`, {
    transform: (snapshot) => {
      const list = Object.keys(snapshot ?? {})
        .map((key) => ({ ...snapshot[key] }))
        .filter((item) => typeof item.url !== 'undefined' && item.url !== '' && item.url !== null);

      return list;
    },
  });
}
