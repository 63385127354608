import { useSearchParams } from 'react-router-dom';
import { PlanT, defaultPlanId, getPlan } from '../../utils/plans.helpers.tsx';
import { Fragment } from 'react';
import { CheckBadgeIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { Pay } from './Pay.tsx';

export function CheckoutPage() {
  const [searchParams] = useSearchParams();
  const planId: string = searchParams.get('plan') ?? defaultPlanId;
  const targetPlan: PlanT = getPlan(planId) ?? getPlan(defaultPlanId)!;
  const planNameWithoutBillingCycle = targetPlan.name.replace(/(monthly|yearly)/gi, '');
  const unavailableFeatures = targetPlan.features.filter((feature) => !feature.available);

  if (!targetPlan) return null;

  return (
    <div className="'p-4 m-auto rounded-2xl pt-14 shadow-sm md:p-14 lg:w-[850px]">
      <h3 className="text-base font-semibold">Upgrading to {targetPlan.name}</h3>

      <div className="mt-6 text-sm  lg:flex">
        <Pay targetPlan={targetPlan} />

        <section className="flex flex-col gap-4">
          <div className="mt-10 lg:mt-0 lg:pl-12">
            <h3 className="text-[.875rem] font-normal mb-4">Included in {planNameWithoutBillingCycle}:</h3>

            <div>
              {targetPlan.words && (
                <div className="flex mt-3">
                  <CheckBadgeIcon className="h-4 w-4 text-primary" />

                  <div>
                    <p className="ml-4 text-[.875rem] font-medium">{targetPlan.words}</p>
                  </div>
                </div>
              )}

              {targetPlan.features
                .filter((feature) => feature.available)
                .map((feature, idx) => {
                  return (
                    <Fragment key={idx}>
                      <div className="flex mt-3">
                        <CheckBadgeIcon className="h-4 w-4 text-primary" />

                        <div>
                          <p
                            className="ml-4 text-[.875rem] font-medium"
                            dangerouslySetInnerHTML={{
                              __html: feature.name,
                            }}
                          />
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
            </div>
          </div>

          {unavailableFeatures.length ? (
            <div className="mt-10 lg:mt-0 lg:pl-12 md:mb-0 mb-10">
              <h3 className="text-[.875rem] font-normal mb-4">Not Included:</h3>

              <div>
                {targetPlan.words && (
                  <div className="flex mt-3">
                    <CheckBadgeIcon className="h-4 w-4 text-primary" />

                    <div>
                      <p className="ml-4 text-[.875rem] font-medium">{targetPlan.words}</p>
                    </div>
                  </div>
                )}

                {targetPlan.features
                  .filter((feature) => !feature.available)
                  .map((feature, idx) => {
                    return (
                      <Fragment key={idx}>
                        <div className="flex items-center mt-3">
                          <XCircleIcon className="h-4 w-4 flex-none text-red-500" aria-hidden="true" />
                          <div>
                            {feature.name === 'API' ? (
                              <div className="flex gap-5">
                                <p className="ml-2 text-[.875rem] font-medium">{feature.name}</p>
                                <span>
                                  Included in{' '}
                                  <a href="/studio/pricing?planType=api" className="underline">
                                    API Plan
                                  </a>
                                </span>
                              </div>
                            ) : (
                              <p className="ml-2 text-[.875rem] font-medium">{feature.name}</p>
                            )}
                          </div>
                        </div>
                      </Fragment>
                    );
                  })}
              </div>
            </div>
          ) : null}
        </section>
      </div>
    </div>
  );
}
