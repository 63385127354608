import { ErrorBoundary, ErrorBoundaryProps } from '@sentry/react';

export function SentryFatalErrorBoundary({ beforeCapture, ...otherProps }: ErrorBoundaryProps) {
  return (
    <ErrorBoundary
      {...otherProps}
      beforeCapture={(scope, error, componentStack) => {
        scope.setLevel('fatal');
        return beforeCapture?.(scope, error, componentStack) ?? scope;
      }}
    />
  );
}
