import React, { useEffect, useRef } from 'react';

interface AudioVisualizerProps {
  audioStream: MediaStream | null;
}

export const AudioVisualizer: React.FC<AudioVisualizerProps> = ({ audioStream }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!audioStream || !canvasRef.current) return;

    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaStreamSource(audioStream);
    source.connect(analyser);

    analyser.fftSize = 256;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext('2d');

    if (!canvasCtx) return;

    const draw = () => {
      requestAnimationFrame(draw);

      analyser.getByteFrequencyData(dataArray);

      canvasCtx.fillStyle = '#121212';
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

      const barWidth = 2;
      const barGap = 1;
      const barCount = Math.floor(canvas.width / (barWidth + barGap));

      for (let i = 0; i < barCount; i++) {
        const dataIndex = Math.floor((i * bufferLength) / barCount);
        const percent = dataArray[dataIndex] / 255;
        const barHeight = Math.max(2, canvas.height * percent);

        const x = i * (barWidth + barGap);
        const y = (canvas.height - barHeight) / 2;

        canvasCtx.fillStyle = '#D3D3D3';
        canvasCtx.fillRect(x, y, barWidth, barHeight);
      }
    };

    draw();

    return () => {
      source.disconnect();
      audioContext.close();
    };
  }, [audioStream]);

  return (
    <div className="flex justify-center items-center bg-[#121212] rounded-full p-1">
      <canvas ref={canvasRef} width={300} height={32} className="rounded-full" />
    </div>
  );
};
