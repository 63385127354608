import { ReactNode } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CONFIG } from '../../config.ts';
import { errorsTracker } from '../errors/errorsTracker.ts';

const maxRetries = 5;
const backoff = 5000;
const stripePromise = loadStripeWithRetry();

export function StripeProvider({ children }: { children: ReactNode }) {
  return <Elements stripe={stripePromise}>{children}</Elements>;
}

function loadStripeWithRetry(attempt = 1): Promise<Stripe | null> {
  return loadStripe(CONFIG.stripePublicKey).catch((reason) => {
    const messageError = `Failed to load stripe (attempt ${attempt} of ${maxRetries}).`;
    if (attempt >= maxRetries) {
      errorsTracker.report('error', reason, messageError);
      return null;
    }
    errorsTracker.report('info', reason, messageError);
    return new Promise((resolve) => setTimeout(resolve, backoff)).then(() => loadStripeWithRetry(attempt + 1));
  });
}
